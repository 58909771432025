import { ChangeEvent, useState } from "react";

interface WorkflowCommentProps {
  onAddComment(comment: string): void;
}

export const WorkflowComments = (props: WorkflowCommentProps) => {
  const { onAddComment } = props;
  const [comment, setComment] = useState<string>("");

  const handleOnChangeComment = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };

  const handleOnAddComment = () => {
    onAddComment(comment);
  };

  return (
    <>
      <p className="card-title m-0 mb-2">Comments and Notes</p>
      <div className="accordion collapase-card" id="commentsAndNotesAccordion">
        <div className="card bg-light border">
          <div className="card-header" id="commentsAndNotes">
            <a
              href="#"
              className="btn btn-header-link text-dark"
              data-toggle="collapse"
              data-target="#commentsAndNotesSection"
              aria-expanded="true"
              aria-controls="commentsAndNotesSection"
            >
              User Comments
            </a>
          </div>
          <div
            id="commentsAndNotesSection"
            className="collapse"
            aria-labelledby="commentsAndNotes"
            data-parent="#commentsAndNotesAccordion"
          >
            <div className="card-body pt-4">
              <textarea
                name="user-comments"
                className="form-control"
                rows={4}
                value={comment}
                onChange={(e) => handleOnChangeComment(e)}
              ></textarea>
              <div className="w-100 mt-3">
                <span className="font-weight-bold mr-5">Notify by email: </span>
                <span className="mr-5">
                  <input
                    type="checkbox"
                    id="owner"
                    className="form-check-input"
                  />
                  <label htmlFor="owner">Owner</label>
                </span>
                <button
                  type="button"
                  className="btn btn-primary btn-icon-text btn-sm float-right"
                  onClick={() => handleOnAddComment()}
                >
                  <i className="fa-solid fa-comment-medical"></i> Add Comment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
