import { map } from "lodash";
import {
  PROFILE_TAB_BILLING,
  PROFILE_TAB_FAVORITES,
  PROFILE_TAB_LOGIN_SECURITY,
  PROFILE_TAB_PEOPLE,
  PROFILE_TAB_PROFILE_INFORMATION,
} from "../constants/tms";
import { useState } from "react";
import { ProfileTab } from "../constants/enum";
import { ProfileInformation } from "../components/profile/ProfileInformation";
import { Favorites } from "../components/profile/Favorites";
import { People } from "../components/profile/People";
import { LoginSecurity } from "../components/profile/LoginSecurity";
import { Billing } from "../components/profile/Billing";
import { SuccessModal } from "../components/shared";

const tabs = [
  {
    id: ProfileTab.PERSONAL_INFORMATION,
    text: PROFILE_TAB_PROFILE_INFORMATION,
    icon: "fa-user",
  },
  {
    id: ProfileTab.FAVORITES,
    text: PROFILE_TAB_FAVORITES,
    icon: "fa-heart",
  },
  {
    id: ProfileTab.PEOPLE,
    text: PROFILE_TAB_PEOPLE,
    icon: "fa-people-group",
  },
  {
    id: ProfileTab.LOGIN_SECURITY,
    text: PROFILE_TAB_LOGIN_SECURITY,
    icon: "fa-shield-halved",
  },
  {
    id: ProfileTab.BILLING,
    text: PROFILE_TAB_BILLING,
    icon: "fa-receipt",
  },
];

export const Profile = () => {
  const [activeTab, setActiveTab] = useState<number>(
    ProfileTab.PERSONAL_INFORMATION
  );
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const onChangeActiveTab = (tabId: number) => {
    setActiveTab(tabId);
  };

  const onUpdate = () => {
    setShowSuccessModal(true);
  };

  const fetchProfileTabs = () => {
    return map(tabs, (tab, index) => {
      return (
        <a
          key={index}
          href="#"
          className={`list-group-item list-group-item-action ${
            activeTab === tab.id ? "active" : ""
          }`}
          onClick={() => onChangeActiveTab(tab.id)}
        >
          <i className={`fa-solid ${tab.icon} mr-2`}></i>
          {tab.text}
        </a>
      );
    });
  };

  const fetchActiveTab = () => {
    switch (activeTab) {
      case ProfileTab.PERSONAL_INFORMATION: {
        return <ProfileInformation onUpdate={onUpdate} />;
      }
      case ProfileTab.FAVORITES: {
        return <Favorites />;
      }
      case ProfileTab.PEOPLE: {
        return <People />;
      }
      case ProfileTab.LOGIN_SECURITY: {
        return <LoginSecurity />;
      }
      case ProfileTab.BILLING: {
        return <Billing />;
      }
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="row">
          <div className="col-md-3 col-12 grid-margin stretch-card">
            <div className="card bg-transparent">
              <div className="card-body p-0 m-0">
                <div className="row">
                  <div className="col-md-12 col-12">
                    <div className="list-group rounded">
                      {fetchProfileTabs()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9 col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">{fetchActiveTab()}</div>
            </div>
          </div>
        </div>
      </div>
      {showSuccessModal ? (
        <SuccessModal message="Profile has been successfully updated!" />
      ) : null}
    </>
  );
};
