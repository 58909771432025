import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { ProfileDto } from "../lib/data-transfer-object/profile.dto"

const initialState: ProfileDto = {
  name: "",
  email: "",
}

export const profileSlice = createSlice({
  name: 'profileSlice',
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<ProfileDto>) => {
      state.name = action.payload.name;
      state.email = action.payload.email;
    }
  }
})


export const { setProfile } = profileSlice.actions;

export default profileSlice.reducer;