import React, { ChangeEvent, useEffect, useState } from "react";
import CSS from "csstype";
import {
  chain,
  filter,
  find,
  findIndex,
  forEach,
  get,
  isEmpty,
  map,
  reduce,
  update,
} from "lodash";
import { TaskMockData } from "../../../mockData/TasksMockData";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  ACTIONITEMSTATUS,
  TaskStatus,
  WorkflowStatus,
} from "../../../constants/enum";
import { GenerateReport } from "./GenerateReport";
import { ApproveReject } from "./ApproveReject";
import dayjs from "dayjs";
import { TemplateTaskTable } from "../simulate/TemplateTaskTable";
import { TemplateTasks } from "../simulate/TemplateTask";
import { TemplateInfo } from "../simulate/TemplateInfo";
import { TemplateTaskInformation } from "./TemplateTaskInformation";
import {
  SimulateReportTemplateDto,
  SimulateWorkflow,
} from "../../../lib/data-transfer-object/simulate.report.template.dto";

type SimulateData = {
  id: number;
  name: string;
  workflows: {
    id: number;
    name: string;
    assignedTo?: string;
    approver?: string;
    status: TaskStatus;
    config: {
      isApproverRequired: boolean;
      isFileUploadRequired: boolean;
      isCommentRequired: boolean;
    };
    actionItems: {
      id: number;
      name: string;
      status: TaskStatus;
      assignedTo?: string;
      approver?: string;
    }[];
  }[];
};

export const Simulate = () => {
  const initialValues: SimulateReportTemplateDto = {
    reportTemplateName: "",
    workflows: [],
  };
  const [simulateReportTemplate, setSimulateReportTemplate] =
    useState<SimulateReportTemplateDto>(initialValues);

  const [simulateWorkflows, setSimulateWorflows] = useState<SimulateWorkflow[]>(
    []
  );

  const [templateTasks, setTemplateTasks] = useState<SimulateData[]>([]);
  const [activeTemplateTask, setActiveTemplateTask] =
    useState<SimulateWorkflow>(
      //templateTasks[0]
      simulateReportTemplate.workflows[0]
    ); // TODO: Change this to activeWorkflow

  const [showTemplateTaskInfo, setShowTemplateTaskInfo] =
    useState<boolean>(false);
  const [showApproveReject, setShowApproveReject] = useState<boolean>(false);
  const [showGenerateReport, setShowGenerateReport] = useState<boolean>(false);

  const createReportTemplateDto = useSelector(
    (state: RootState) => state.createReportTemplate
  );

  //Temporary Solution

  /*const { sections } = createReportTemplateDto;
  const categories = sections[0].workflows;
  const subCategories = categories[0].children;

  const checkIfApproverIsRequired = (id: number): boolean => {
    switch (id) {
      case 1: {
        return false;
        break;
      }
      case 2: {
        return false;
        break;
      }
      case 3: {
        return false;
        break;
      }
    }

    return false;
  };

  const checkIfCommentIsRequired = (id: number): boolean => {
    switch (id) {
      case 1: {
        return true;
        break;
      }
      case 2: {
        return true;
        break;
      }
      case 3: {
        return true;
        break;
      }
    }

    return false;
  };

  const checkIfFileUploadIsRequired = (id: number): boolean => {
    switch (id) {
      case 1: {
        return true;
        break;
      }
      case 2: {
        return false;
        break;
      }
      case 3: {
        return false;
        break;
      }
    }

    return false;
  };

  const mappedData: SimulateData[] = map(
    subCategories,
    (subCategory, index) => {
      return {
        id: index + 1,
        name: subCategory.text,
        workflows: map(subCategory.children, (workflow, index) => {
          return {
            id: index + 1,
            name: workflow.text,
            assignedTo: "",
            approver: "",
            status: TaskStatus.ACTIVE,
            config: {
              isApproverRequired: checkIfApproverIsRequired(index + 1),
              isFileUploadRequired: checkIfFileUploadIsRequired(index + 1),
              isCommentRequired: checkIfCommentIsRequired(index + 1),
            },
            actionItems: map(workflow.children, (actionItem, index) => {
              return {
                id: index + 1,
                name: actionItem.text,
                assignedTo: "",
                approver: "",
                description: actionItem.description,
                status: TaskStatus.ACTIVE,
              };
            }),
          };
        }),
      };
    }
  );*/

  const handleOpenTemplateInfoModal = (activeTemplateTask: any) => {
    setActiveTemplateTask(activeTemplateTask);
    setShowTemplateTaskInfo(true);
  };

  const handleGenerateReportModal = () => {
    setShowGenerateReport(true);
  };

  const handleSentForApproval = () => {
    console.log(showApproveReject);
    setShowApproveReject(true);
  };

  const handleApproveWorkflow = () => {
    /*const workflows = templateTasks[0].workflows;
    const templateTask = find(
      workflows,
      (workflow) => workflow.id === activeTemplateTask.id
    );

    const workflowIndex = findIndex(workflows, (workflow) => {
      return workflow.id === activeTemplateTask.id;
    });

    const templateTaskIndex = findIndex(templateTasks, (tempalateTask) => {
      return tempalateTask.id === 1;
    });

    workflows.splice(workflowIndex, 1, {
      ...activeTemplateTask,
      status: TaskStatus.COMPLETED,
      actionItems: map(activeTemplateTask.actionItems, (actionItem) => {
        return {
          ...actionItem,
          status: TaskStatus.COMPLETED,
        };
      }),
    });

    const newTemplateTask = templateTasks.splice(templateTaskIndex, 1, {
      ...templateTasks[0],
      workflows: workflows,
    });

    setTemplateTasks(newTemplateTask);
    setActiveTemplateTask({
      ...activeTemplateTask,
      status: TaskStatus.COMPLETED,
      actionItems: map(activeTemplateTask.actionItems, (actionItem) => {
        return {
          ...actionItem,
          status: TaskStatus.COMPLETED,
        };
      }),
    });*/
  };

  // Refactor to make one function handleApproveWorkflow
  const handleRejectWorkflow = () => {
    /*const workflows = templateTasks[0].workflows;
    const templateTask = find(
      workflows,
      (workflow) => workflow.id === activeTemplateTask.id
    );

    const workflowIndex = findIndex(workflows, (workflow) => {
      return workflow.id === activeTemplateTask.id;
    });

    const templateTaskIndex = findIndex(templateTasks, (tempalateTask) => {
      return tempalateTask.id === 1;
    });

    workflows.splice(workflowIndex, 1, {
      ...activeTemplateTask,
      status: TaskStatus.REJECTED,
      actionItems: map(activeTemplateTask.actionItems, (actionItem) => {
        return {
          ...actionItem,
          status: TaskStatus.REJECTED,
        };
      }),
    });

    const newTemplateTask = templateTasks.splice(templateTaskIndex, 1, {
      ...templateTasks[0],
      workflows: workflows,
    });

    setTemplateTasks(newTemplateTask);
    setActiveTemplateTask({
      ...activeTemplateTask,
      status: TaskStatus.REJECTED,
      actionItems: map(activeTemplateTask.actionItems, (actionItem) => {
        return {
          ...actionItem,
          status: TaskStatus.REJECTED,
        };
      }),
    });*/
  };

  const handleChangeAssignTo = (
    subCategoryId: number,
    workflowId: number,
    newAssignTo: string
  ) => {
    const templateTask = filter(templateTasks, { id: subCategoryId });
    const templateTaskIndex = findIndex(templateTasks, { id: subCategoryId });
    const workflowIndex = findIndex(templateTask[0].workflows, {
      id: workflowId,
    });
    const workflow = filter(templateTask[0].workflows, { id: workflowId });

    templateTask[0].workflows.splice(workflowIndex, 1, {
      ...workflow[0],
      assignedTo: newAssignTo,
    });

    templateTasks.splice(templateTaskIndex, 1, {
      ...templateTask[0],
    });

    setTemplateTasks([...templateTasks]);
  };

  const handleChangeAssignToActionItem = (
    subCategoryId: number,
    workflowId: number,
    actionItemId: number,
    newAssignTo: string
  ) => {
    const templateTask = filter(templateTasks, { id: subCategoryId });
    const templateTaskIndex = findIndex(templateTasks, { id: subCategoryId });
    const workflowIndex = findIndex(templateTask[0].workflows, {
      id: workflowId,
    });
    const workflow = filter(templateTask[0].workflows, { id: workflowId });
    const actionItemIndex = findIndex(workflow[0].actionItems, {
      id: actionItemId,
    });

    const actionItem = filter(workflow[0].actionItems, { id: actionItemId });
    workflow[0].actionItems.splice(actionItemIndex, 1, {
      ...actionItem[0],
      assignedTo: newAssignTo,
    });

    //console.log(workflow[0]);

    /*templateTask[0].workflows.splice(workflowIndex, 1, {
      ...workflow[0],
    });

    templateTasks.splice(templateTaskIndex, 1, {
      ...templateTask[0],
    });

    setTemplateTasks([...templateTasks]);*/
  };

  const handleChangeApprover = (
    subCategoryId: number,
    workflowId: number,
    newAssignTo: string
  ) => {
    var templateTask = filter(templateTasks, { id: subCategoryId });
    var templateTaskIndex = findIndex(templateTasks, { id: subCategoryId });
    var workflowIndex = findIndex(templateTask[0].workflows, {
      id: workflowId,
    });
    var workflow = filter(templateTask[0].workflows, { id: workflowId });
    templateTask[0].workflows.splice(workflowIndex, 1, {
      ...workflow[0],
      approver: newAssignTo,
    });

    templateTasks.splice(templateTaskIndex, 1, {
      ...templateTask[0],
    });

    setTemplateTasks([...templateTasks]);
  };

  const handleChangeApproverActionItem = (
    subCategoryId: number,
    workflowId: number,
    actionItemId: number,
    newAssignTo: string
  ) => {
    const templateTask = filter(templateTasks, { id: subCategoryId });
    const templateTaskIndex = findIndex(templateTasks, { id: subCategoryId });
    const workflowIndex = findIndex(templateTask[0].workflows, {
      id: workflowId,
    });
    const workflow = filter(templateTask[0].workflows, { id: workflowId });
    const actionItemIndex = findIndex(workflow[0].actionItems, {
      id: actionItemId,
    });

    const actionItem = filter(workflow[0].actionItems, { id: actionItemId });
    workflow[0].actionItems.splice(actionItemIndex, 1, {
      ...actionItem[0],
      approver: newAssignTo,
    });

    //console.log(workflow[0]);

    /*templateTask[0].workflows.splice(workflowIndex, 1, {
      ...workflow[0],
    });

    templateTasks.splice(templateTaskIndex, 1, {
      ...templateTask[0],
    });

    setTemplateTasks([...templateTasks]);*/
  };

  /** new functions */

  const getWorkflowsInReportTemplate = (): SimulateWorkflow[] => {
    let defaultvalue: SimulateWorkflow[] = [];
    const sections = get(createReportTemplateDto, "sections");

    forEach(sections, (section) => {
      forEach(section.workflows, (sectionWorkflow) => {
        forEach(sectionWorkflow.subCategories, (subCategory) => {
          forEach(subCategory.workflows, (workflow) => {
            defaultvalue.push({
              id: workflow.workflowId,
              workflowName: workflow.workflowName,
              status: WorkflowStatus.ACTIVE,
              workflowConfig: {
                isApproverRequired: workflow.workflowConfig.isApproverRequired,
                isFileUploadRequired:
                  workflow.workflowConfig.isFileUploadRequired,
                isCommentRequired: workflow.workflowConfig.isCommentRequired,
              },
              workflowDueDate: dayjs().format("YYYY-MM-DD"),
              actionItems: map(workflow.actionItems, (actionItem) => {
                return {
                  actionItemId: actionItem.actionItemId,
                  actionItemName: actionItem.actionItemName,
                  actionItemDueDate: dayjs().format("YYYY-MM-DD"),
                  status: WorkflowStatus.ACTIVE,
                };
              }),
            });
          });
        });
      });
    });

    console.log(defaultvalue);
    return defaultvalue;
  };

  const mappedToSimulateReportTemplateDto = (): SimulateReportTemplateDto => {
    const simulateReportTemplate: SimulateReportTemplateDto = {
      reportTemplateName: createReportTemplateDto.reportTemplateName,
      workflows: getWorkflowsInReportTemplate(),
    };

    return simulateReportTemplate;
  };

  const onApproverChange = (approverName: string) => {
    setSimulateReportTemplate({
      ...simulateReportTemplate,
      approver: approverName,
      workflows: map(simulateReportTemplate.workflows, (workflow) => {
        return {
          ...workflow,
          workflowApprover: approverName,
          actionItems: map(workflow.actionItems, (actionItem) => {
            return {
              ...actionItem,
              actionItemApprover: approverName,
            };
          }),
        };
      }),
    });
  };

  const onAssignToChange = (assignToName: string) => {
    setSimulateReportTemplate({
      ...simulateReportTemplate,
      assignTo: assignToName,
      workflows: map(simulateReportTemplate.workflows, (workflow) => {
        return {
          ...workflow,
          workflowAssignTo: assignToName,
          actionItems: map(workflow.actionItems, (actionItem) => {
            return {
              ...actionItem,
              actionItemAssignTo: assignToName,
            };
          }),
        };
      }),
    });
  };

  const onActionItemComplete = (actionItemId: number) => {
    const workflows = [...simulateReportTemplate.workflows];
    const updateActiveWorkflow = {
      ...activeTemplateTask,
      actionItems: map(activeTemplateTask.actionItems, (actionItem) => {
        return actionItem.actionItemId === actionItemId
          ? { ...actionItem, status: WorkflowStatus.COMPLETED }
          : actionItem;
      }),
    };
    setActiveTemplateTask(updateActiveWorkflow);

    const updatedWorkflows = map(workflows, (workflow) => {
      return workflow.id === updateActiveWorkflow.id
        ? { ...updateActiveWorkflow }
        : workflow;
    });

    setSimulateReportTemplate({
      ...simulateReportTemplate,
      workflows: [...updatedWorkflows],
    });
    setSimulateWorflows(updatedWorkflows);
  };

  const onActionItemSkip = (actionItemId: number) => {
    const workflows = [...simulateReportTemplate.workflows];
    const updateActiveWorkflow = {
      ...activeTemplateTask,
      actionItems: map(activeTemplateTask.actionItems, (actionItem) => {
        return actionItem.actionItemId === actionItemId
          ? { ...actionItem, status: WorkflowStatus.SKIPED }
          : actionItem;
      }),
    };
    setActiveTemplateTask(updateActiveWorkflow);

    const updatedWorkflows = map(workflows, (workflow) => {
      return workflow.id === updateActiveWorkflow.id
        ? { ...updateActiveWorkflow }
        : workflow;
    });

    setSimulateReportTemplate({
      ...simulateReportTemplate,
      workflows: [...updatedWorkflows],
    });
    setSimulateWorflows(updatedWorkflows);
  };

  const onWorkflowCompleteOrSentForApproval = () => {
    const workflows = [...simulateReportTemplate.workflows];

    const updateActiveWorkflow = {
      ...activeTemplateTask,
      status: WorkflowStatus.COMPLETED,
    };

    setActiveTemplateTask(updateActiveWorkflow);

    const updatedWorkflows = map(workflows, (workflow) => {
      return workflow.id === updateActiveWorkflow.id
        ? { ...updateActiveWorkflow }
        : workflow;
    });

    setSimulateReportTemplate({
      ...simulateReportTemplate,
      workflows: [...updatedWorkflows],
    });
    setSimulateWorflows(updatedWorkflows);
  };

  useEffect(() => {
    setSimulateReportTemplate(mappedToSimulateReportTemplateDto());
  }, []);

  useEffect(() => {
    const updateSimulateWorkflows = map(
      [...simulateReportTemplate.workflows],
      (workflow) => {
        return {
          ...workflow,
          actionItems: map(workflow.actionItems, (actionItem) => {
            return {
              ...actionItem,
              actionItemApprover: workflow.workflowApprover,
              actionItemAssignTo: workflow.workflowAssignTo,
            };
          }),
        };
      }
    );
    setSimulateWorflows(
      /*updateSimulateWorkflows*/ [...simulateReportTemplate.workflows]
    );
  }, [simulateReportTemplate]);

  return (
    <>
      <div
        className="modal fade modal-fullscreen"
        id="modal-simulate-report-template"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="card bg-light border mb-3">
                    <div className="card-body">
                      <div className="row">
                        <TemplateInfo
                          reportTemplateName={
                            simulateReportTemplate.reportTemplateName
                          }
                          onApproverChange={onApproverChange}
                          onAssignToChange={onAssignToChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/*<div className="col-1 text-right">
                  <span className="fa-stack fa-2x">
                    <i className="fa fa-circle fa-stack-2x text-primary"></i>
                    <i className="fa-solid fa-chart-bar fa-stack-1x text-white"></i>
                  </span>
                </div>*/}
              </div>
              {/* Start Progress */}
              {/* End Progress */}
              {/* Start Task Table */}
              <div className="row">
                <TemplateTasks
                  activeTemplateTask={activeTemplateTask}
                  simulateWorkflows={
                    /*simulateReportTemplate?.workflows*/ simulateWorkflows
                  }
                  onClick={handleOpenTemplateInfoModal}
                  onChangeAssignTo={handleChangeAssignTo}
                  onChangeApprover={handleChangeApprover}
                  onChangeAssignToActionItem={handleChangeAssignToActionItem}
                  onChangeApproverActionItem={handleChangeApproverActionItem}
                />
              </div>
              {/* End Task Table */}
            </div>
            <div className="modal-footer py-2">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-dismiss="modal"
              >
                Close
              </button>
              {/*<button type="button" className="btn btn-primary btn-sm">
                Save and Download
              </button>*/}
              <button type="button" className="btn btn-primary btn-sm">
                Save
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                data-toggle="modal"
                data-target="#modal-simulate-generate-report"
                onClick={handleGenerateReportModal}
              >
                Generate Report
              </button>
            </div>
          </div>
        </div>
      </div>
      {showTemplateTaskInfo ? (
        <TemplateTaskInformation
          simulateWorkflow={activeTemplateTask}
          onCompleteOrSentForApproval={onWorkflowCompleteOrSentForApproval}
          onActionItemComplete={onActionItemComplete}
          onActionItemSkip={onActionItemSkip}
        />
      ) : null}
      {showGenerateReport ? (
        <GenerateReport data={simulateReportTemplate} />
      ) : null}
      {showApproveReject ? (
        <ApproveReject
          onApproved={handleApproveWorkflow}
          onRejected={handleRejectWorkflow}
        />
      ) : null}
    </>
  );
};
