import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ImporterTabs } from '../constants/enum';

type ImporterConfig = {
  activeTab: ImporterTabs,
  uploadedFile?: File,
  selectedSheets: string[];
}

const  initialState : ImporterConfig = {
  activeTab: ImporterTabs.TASK_CATEGORY,
  selectedSheets: []
}

export const importerConfigSlice = createSlice({
  name: 'importerConfig',
  initialState,
  reducers: {
    changeActiveTab: (state, action: PayloadAction<ImporterTabs>) => {
      state.activeTab = action.payload;
    },
    setUploadedFile: (state, action: PayloadAction<File>) => {
      state.uploadedFile = action.payload;
    },
    addSelectedSheets: (state, action: PayloadAction<string>) => {
      state.selectedSheets.push(action.payload);
    },
    removeInSelectedSheets: (state, action: PayloadAction<number>) => {
      state.selectedSheets.splice(0, 2);
    },
  }
})

export const { changeActiveTab, setUploadedFile, addSelectedSheets, removeInSelectedSheets } = importerConfigSlice.actions;

export default importerConfigSlice.reducer;