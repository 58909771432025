import { get, map } from "lodash";
import React, { useEffect, useState } from "react";
import { AssignmentMocks } from "../../../mockData/AssignmentMockData";
import { Assignment } from "./Assignment";
import { useFetch } from "../../../lib/hooks/useFetch";
import { GET_POST_ASSIGNMENTS_URL } from "../../../constants/api-url";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { GetPostAssignmentResponseDto } from "../../../lib/data-transfer-object/dashboard.dto";
import { useAuthUser } from "react-auth-kit";

export const PostAssignment = () => {
  const profille = useSelector((state: RootState) => state.profile);
  const auth = useAuthUser();

  const { response, loaded, fetchErrors } =
    useFetch<GetPostAssignmentResponseDto>(
      `${GET_POST_ASSIGNMENTS_URL}?email=${auth()?.email}`
    );

  const fetchAssignments = () => {
    const data = get(response, "data");

    return map(data, (d, index) => {
      console.log({ ...d });
      return (
        <Assignment
          id={d.id}
          name={d.name}
          dueDate={d.dueDate}
          assignmentType={d.type}
          status={d.status}
          key={index}
        />
      );
    });
  };

  return (
    <div className="col-lg-6 col-md-6 col-12 mb-5 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">
            <i className="fa-solid fa-clipboard-check"></i> Post Assignment
          </h4>

          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <div className="form-group mb-0 row">
                <label
                  htmlFor="name_of_report"
                  className="col-sm-4 col-form-label text-right"
                >
                  Filter By:
                </label>
                <div className="col-sm-8">
                  <select id="" className="form-control" required>
                    <option disabled selected></option>
                    <option value="Filter Criteria 1">Filter Criteria 1</option>
                    <option value="Filter Criteria 2">Filter Criteria 2</option>
                    <option value="Filter Criteria 3">Filter Criteria 3</option>
                    <option value="Filter Criteria 4">Filter Criteria 4</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-12">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search for..."
                  aria-label="Search for..."
                />
                <div className="input-group-append">
                  <button className="btn btn-sm btn-primary" type="button">
                    <i className="icon-search"></i>
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="my-3">
            <hr />
          </div>
          <div className="overflow-auto">{loaded && fetchAssignments()}</div>

          <nav aria-label="Page navigation example" className="mt-4">
            <ul className="pagination justify-content-center">
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};
