import { map } from "lodash";
import { ReferenceMaterialDto } from "../../lib/data-transfer-object/referenceMaterial.dto";
interface UploadedFilesProps {
  uploadedFiles: ReferenceMaterialDto[];
  canRemove: boolean;
}

export const UploadedFiles = (props: UploadedFilesProps) => {
  const { uploadedFiles, canRemove } = props;

  const loadUploadedFiles = () => {
    return map(uploadedFiles, (uploadedFile, index) => {
      return (
        <div key={index} className="col-12 border-bottom py-2 d-flex">
          <span className="mr-auto">
            <i className="fa-solid fa-file-pdf mr-2 py-2"></i>{" "}
            {` ${uploadedFile.fileName}`}
          </span>
          <button
            type="button"
            className="btn btn-primary btn-icon-text btn-sm ml-2"
          >
            <i className="fa-solid fa-file-export"></i> Preview
          </button>
          {canRemove ? (
            <button
              type="button"
              className="btn btn-danger btn-icon-text btn-sm ml-2"
            >
              <i className="fa-solid fa-trash-can"></i> Remove
            </button>
          ) : null}
          <button
            type="button"
            className="btn btn-success btn-icon-text btn-sm ml-2"
          >
            <i className="fa-solid fa-file-arrow-down"></i> Download
          </button>
        </div>
      );
    });
  };
  return (
    <div className="card bg-light border border-rounded mb-5">
      <div className="card-body pt-4">
        <span className="mb-5 d-block">Document Name</span>
        {loadUploadedFiles()}
      </div>
    </div>
  );
};
