import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  headerModalState: false,
  sectionModalState: false,
  addSectionModal: false,
}

export const modalStatesSlice = createSlice({
  name: 'modalStates',
  initialState,
  reducers: {
    openHeaderModal: (state, action: PayloadAction<boolean>) => {
      state.headerModalState = action.payload;
    },
    openSectionModal: (state, action: PayloadAction<boolean>) => { // To be removed. 
      state.sectionModalState = action.payload;
    },
    showAddSectionModal: (state, action: PayloadAction<boolean>) => {
      state.addSectionModal = action.payload;
    }
  }
});

export const {openHeaderModal, openSectionModal, showAddSectionModal } = modalStatesSlice.actions;

export default modalStatesSlice.reducer;