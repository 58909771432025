import React, { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  changeReportCategory,
  changeReportName,
  changeReportSubCategory,
} from "../../../features/createReportTemplateSlice";

interface ReportPropertiesProps {
  /*fields: {
    reportName: string;
    status?: string;
    tags?: string[];
    allowAssingment?: boolean;
  };*/
  //onChangeField(field: string, value: string | number): void;
}

export const ReportProperties = () => {
  const createReportTemplate = useSelector(
    (state: RootState) => state.createReportTemplate
  );

  const dispatch = useDispatch();

  const onReportNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(changeReportName(event.target.value));
  };

  return (
    <div className="card">
      <div className="card-header p-0" id="headingOne">
        <h2 className="mb-0">
          <button
            className="d-flex py-4 px-4 align-items-center justify-content-between btn btn-link collapsed"
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
          >
            <p className="mb-0">Report Properties</p>
            <i className="myaccordion-fa fa" aria-hidden="true"></i>
          </button>
        </h2>
      </div>
      <div
        id="collapseOne"
        className="collapse show border-bottom"
        aria-labelledby="headingOne"
        data-parent="#accordion"
      >
        <div className="card-body px-0">
          <div className="col-12 my-4">
            <form>
              <div className="form-group mb-2 row">
                <div className="col-sm-12">
                  <input
                    type="text"
                    className="form-control"
                    id="reportName"
                    placeholder="Name of Report"
                    value={createReportTemplate.reportTemplateName}
                    onChange={(e) => onReportNameChange(e)}
                  />
                </div>
              </div>
              <div className="form-group mb-2 row">
                <div className="col-sm-12">
                  <select
                    id="status"
                    className="form-control"
                    onChange={(e) =>
                      dispatch(changeReportCategory(Number(e.target.value)))
                    }
                    required
                  >
                    <option disabled selected>
                      Select Category
                    </option>
                    <option value={1}>Senior Care</option>
                  </select>
                </div>
              </div>
              <div className="form-group mb-2 row">
                <div className="col-sm-12">
                  <select
                    id="status"
                    className="form-control"
                    onChange={(e) =>
                      dispatch(changeReportSubCategory(Number(e.target.value)))
                    }
                    required
                  >
                    <option disabled selected>
                      Select Sub Category
                    </option>
                    <option value={1}>Personal Care</option>
                    <option value={2}>Food preparation</option>
                    <option value={3}>General health care</option>
                    <option value={4}>Basic Cleaning</option>
                  </select>
                </div>
              </div>
              <div className="form-group mb-2 row">
                <div className="col-sm-12">
                  <input
                    type="text"
                    data-role="tagsinput"
                    id="tags"
                    className="form-control"
                    placeholder="Tags"
                  />
                  <small className="form-text text-muted">
                    Use comma "," to separate tags from each other.
                  </small>
                </div>
              </div>
              <div className="form-group mb-0 row">
                <label
                  htmlFor="tags"
                  className="col-sm-6 col-form-label text-right"
                >
                  Allow Assignment
                </label>
                <div className="col-sm-6 pt-2">
                  <div className="custom-toggle">
                    <label className="switch switch-left-right">
                      <input className="switch-input" type="checkbox" />
                      <span
                        className="switch-label"
                        data-on="Yes"
                        data-off="No"
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
