import { configureStore } from '@reduxjs/toolkit';
import reportTemplateReducer from '../features/reportTemplateSlice';
import createReportTemplateReducer from '../features/createReportTemplateSlice';
import modalStatesReducer from '../features/modalStatesSlice';
import importConfigReducer from '../features/importerConfigSlice';
import createReportTemplateSectionReducer from '../features/createReportTemplateSectionSlice';
import assignWorkflowSlice from '../features/assignWorkflowSlice';
import profileSlice from '../features/profileSlice';

export const store = configureStore({
  reducer:{
    reportTemplate: reportTemplateReducer,
    createReportTemplate: createReportTemplateReducer,
    modalStates: modalStatesReducer,
    importerConfig: importConfigReducer,
    createReportTemplateSection: createReportTemplateSectionReducer,
    assignWorkflow: assignWorkflowSlice,
    profile: profileSlice
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;