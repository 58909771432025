import CSS from "csstype";
import dayjs from "dayjs";
import { find, isEmpty, isNull, map } from "lodash";
import { useEffect, useState } from "react";
import { TaskStatus, WorkflowStatus } from "../../../constants/enum";
import { SimulateWorkflow } from "../../../lib/data-transfer-object/simulate.report.template.dto";
import { ErrorModal } from "../../shared/ErrorModal";
import { SuccessModal } from "../../shared/SuccessModal";
import { ApprovalFileUpload } from "./ApprovalFileUpload";
import { ApproveReject } from "./ApproveReject";
import { WorkflowComments } from "./WorkflowComments";

interface TemplateTaskInformationProps {
  simulateWorkflow: SimulateWorkflow;
  onCompleteOrSentForApproval(): void;
  onActionItemComplete: (actionItemId: number) => void;
  onActionItemSkip: (actionItemId: number) => void;
}

export const TemplateTaskInformation = (
  props: TemplateTaskInformationProps
) => {
  const modalBodyStyles: CSS.Properties = {
    background: "#f5f7ff",
  };

  const tableStyles: CSS.Properties = {
    width: "100%",
  };

  const {
    simulateWorkflow,
    onCompleteOrSentForApproval,
    onActionItemComplete,
    onActionItemSkip,
  } = props;
  const {
    id,
    workflowName,
    status,
    workflowConfig,
    actionItems,
    workflowApprover,
    workflowDueDate,
    workflowAssignTo,
  } = simulateWorkflow;
  const { isApproverRequired, isFileUploadRequired, isCommentRequired } =
    workflowConfig;

  const [comments, setComments] = useState<string[]>([]);
  const [references, setReferences] = useState<string[]>([]);
  const [hasErrors, setHasErrors] = useState<boolean>(true);
  const [errors, setErrors] = useState<string[]>([]);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  const handleAddComment = (comment: string) => {
    setComments([...comments, comment]);
  };

  const handleAddFiles = (fileName: string) => {
    setReferences([...references, fileName]);
  };

  const resetErrors = () => {
    //const emptyArr: string[] = [];
    setErrors([]);
  };

  const areActionItemsComplete = (): boolean => {
    return find(actionItems, { status: WorkflowStatus.ACTIVE }) ? false : true;
  };

  const validate = () => {
    let err: string[] = [];

    resetErrors();

    if (!areActionItemsComplete()) {
      //canProceed = false;
      setErrors((oldErrors) => [
        ...oldErrors,
        "Action Items are not all complete!",
      ]);
      err.push("Action Items are not all complete!");
    }

    if (isCommentRequired && isEmpty(comments)) {
      //canProceed = false;
      setErrors((oldErrors) => [...oldErrors, "Comment is required"]);
      err.push("Comment is required");
    }

    if (isFileUploadRequired && isEmpty(references)) {
      //canProceed = false;
      setErrors((oldErrors) => [...oldErrors, "File references is required"]);
      err.push("File references is required");
    }

    if (!isEmpty(err)) {
      setHasErrors(true);
      setErrors([...err]);
    } else {
      setHasErrors(false);
    }
  };

  const onCompleteOrSentForApprovalClick = () => {
    validate();

    if (!hasErrors) {
      onCompleteOrSentForApproval();
      setIsComplete(true);
    }
  };

  const getStatusText = (status: WorkflowStatus): string => {
    switch (status) {
      case WorkflowStatus.ACTIVE: {
        return "Active";
      }
      case WorkflowStatus.COMPLETED: {
        return "Completed";
      }
      case WorkflowStatus.REJECTED: {
        return "Rejected";
      }
      case WorkflowStatus.SKIPED: {
        return "N/A";
      }
    }
    return "Pending";
  };

  const formatDate = (date: string): string =>
    dayjs(date).format("MMMM DD, YYYY");

  useEffect(() => {
    console.log(errors);
  }, [/*data*/ errors]);

  useEffect(() => {
    console.log(comments);
  }, [/*data*/ comments]);

  useEffect(() => {
    console.log(references);
  }, [/*data*/ references]);

  return (
    <>
      <div
        className="modal fade modal-fullscreen"
        id="modal-fullscreen-xl-generate-report-action"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="m-0">Job Description and/or other Document(s)</h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={modalBodyStyles}>
              <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <p className="card-title m-0 mb-2">
                        Assignment Information
                      </p>
                      <div className="card bg-light border mb-5">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12 col-sm-4">
                              <p className="m-0">
                                <b className="text-primary mr-2">Assign to:</b>
                                {workflowAssignTo}
                              </p>
                            </div>
                            <div className="col-12 col-sm-4">
                              <p className="m-0">
                                <b className="text-primary mr-2">
                                  Assigned by:
                                </b>
                                John Doe Creator
                              </p>
                            </div>
                            <div className="col-12 col-sm-4">
                              <p className="m-0">
                                <b className="text-primary mr-2">Approver:</b>
                                {workflowApprover}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <p className="card-title m-0 mb-2">General Information</p>
                      <div className="card bg-light border">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12 col-sm-4">
                              <p className="m-0">
                                <b className="text-primary mr-2">Name:</b>
                                {workflowName}
                              </p>
                            </div>
                            <div className="col-12 col-sm-4">
                              <p className="m-0">
                                <b className="text-primary mr-2">
                                  Description:
                                </b>
                                ----
                              </p>
                            </div>
                            <div className="col-12 col-sm-4">
                              <p className="m-0">
                                <b className="text-primary mr-2">Due Date:</b>
                                {formatDate(workflowDueDate as string)}
                              </p>
                            </div>
                            <div className="col-12 col-sm-4">
                              <b className="text-primary mr-2">Recurrence:</b>
                              <div className="custom-toggle">
                                <label className="switch switch-left-right m-0">
                                  <input
                                    className="switch-input"
                                    type="checkbox"
                                    name="recurrence"
                                    id="recurrence"
                                    disabled
                                  />
                                  <span
                                    className="switch-label"
                                    data-on="Yes"
                                    data-off="No"
                                  ></span>
                                  <span className="switch-handle"></span>
                                </label>
                              </div>
                            </div>
                            <div className="col-12 col-sm-4">
                              <p className="m-0">
                                <b className="text-primary mr-2">
                                  Head's Up Days:
                                </b>
                                2 Days
                              </p>
                            </div>
                            <div className="col-12 col-sm-4">
                              <p className="m-0">
                                <b className="text-primary mr-2">
                                  Urgent Days:
                                </b>
                                1 Day
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pb-5 mb-5 border-bottom"></div>

                      <p className="card-title m-0 mb-2">Related Objectives</p>
                      <table
                        id="data-table-generate-report-action-related-objectives"
                        className="table table-striped table-bordered"
                        style={tableStyles}
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Assign To</th>
                            <th>Status</th>
                            <th>Due Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{workflowName}</td>
                            <td>John Doe</td>
                            <td>{getStatusText(status)}</td>
                            <td>{formatDate(workflowDueDate as string)}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="pb-5 mb-5 border-bottom"></div>

                      <p className="card-title m-0 mb-2">Action Items</p>
                      {/*
                        <div className="mb-4">
                        <small>Progress:</small>
                        <div className="progress" style="height: 20px;">
                          <div className="progress-bar bg-primary" role="progressbar" style="width: 50%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">50%</div>
                        </div>  
                      </div>
                       */}
                      <table
                        id="data-table-generate-report-action-items"
                        className="table table-striped table-bordered mb-5"
                        style={tableStyles}
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Assign To</th>
                            <th>Due Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {map(actionItems, (actionItem, index) => {
                            const {
                              actionItemId,
                              actionItemName,
                              actionItemDescription,
                              actionItemAssignTo,
                              actionItemDueDate,
                              status,
                            } = actionItem;
                            return (
                              <tr key={index}>
                                <td className="col-2">
                                  <div className="text-wrap">
                                    {actionItemName}
                                  </div>
                                </td>
                                <td className="col-3">
                                  {!isNull(actionItemDescription)
                                    ? actionItemDescription
                                    : ""}
                                </td>
                                <td className="col-2">{actionItemAssignTo}</td>
                                <td className="col-1">
                                  {formatDate(actionItemDueDate as string)}
                                </td>
                                <td className="col-1">
                                  {getStatusText(status)}
                                </td>
                                <td className="col-3 text-center">
                                  <a
                                    href="javascript:void(0);"
                                    className="btn btn-primary btn-sm"
                                    onClick={() =>
                                      onActionItemComplete(actionItemId)
                                    }
                                  >
                                    <i className="fa-solid fa-check pr-2"></i>
                                    {` Complete`}
                                  </a>
                                  <a
                                    href="javascript:void(0);"
                                    className="btn btn-dark btn-sm"
                                    onClick={() =>
                                      onActionItemSkip(actionItemId)
                                    }
                                  >
                                    <i className="fa-solid fa-xmark pr-2"></i>
                                    {` Marked as N\\A`}
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="pb-5 mb-5 border-bottom"></div>

                      <p className="card-title m-0 mb-2">
                        Refference Materials
                      </p>
                      <ul
                        className="nav nav-tabs"
                        id="setting-panel"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            id="attachments-tab"
                            data-toggle="tab"
                            href="#attachments-section"
                            role="tab"
                            aria-controls="attachments-section"
                          >
                            Attachments{" "}
                            <span className="badge badge-light ml-3">0</span>
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content" id="setting-content">
                        <div
                          className="tab-pane fade"
                          id="attachments-section"
                          role="tabpanel"
                          aria-labelledby="attachments-section"
                        >
                          <div className="wrapper wrapper-content animated fadeInRight">
                            <div className="card bg-light border mb-5">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-12 col-sm-6">
                                    <p className="m-0">
                                      <b className="text-primary mr-2">Name:</b>
                                      ----
                                    </p>
                                  </div>
                                  <div className="col-12 col-sm-6">
                                    <p className="m-0">
                                      <b className="text-primary mr-2">
                                        Action:
                                      </b>
                                      ----
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-5 border-bottom"></div>

                      {isCommentRequired ? (
                        <WorkflowComments onAddComment={handleAddComment} />
                      ) : null}

                      {isFileUploadRequired ? (
                        <ApprovalFileUpload onUploadFile={handleAddFiles} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer py-2">
              {!isEmpty(simulateWorkflow.actionItems) && isApproverRequired ? (
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  data-toggle="modal"
                  data-target={
                    !hasErrors
                      ? "#modal-sent-for-approval"
                      : "#form-error-modal"
                  }
                  onClick={() => onCompleteOrSentForApprovalClick()}
                >
                  <i className="fa fa-paper-plane mr-3"></i> Sent for Approval
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  data-toggle="modal"
                  data-target={!hasErrors ? "#success-modal" : "#error-modal"}
                  onClick={() => onCompleteOrSentForApprovalClick()}
                >
                  <i className="fa-solid fa-check mr-3"></i> Complete
                </button>
              )}
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {hasErrors && <ErrorModal errors={errors} />}
      {isComplete && <SuccessModal message="Workflow is complete" />}
    </>
  );
};
