import { ReportDto } from "../lib/data-transfer-object/assignReport.dto";

export const AssignReportMockData: ReportDto[] = [
  {
    reportTemplateId: 1,
    documentName: "House Keeper. List of tasks for housekeeper",
    status: 1,
    createdBy: "John Doe",
    createdDate: new Date(),
    lastUpdatedDate: new Date(),
    reportProgress: 25,
  },
  {
    reportTemplateId: 2,
    documentName: "Breast Cancer Exercises. Exercises Post Surgery",
    status: 2,
    createdBy: "John Doe",
    createdDate: new Date(),
    lastUpdatedDate: new Date(),
    reportProgress: 100,
  },
  {
    reportTemplateId: 3,
    documentName: "Take Medication. Schedule Medication Reminder",
    status: 3,
    createdBy: "John Doe",
    createdDate: new Date(),
    lastUpdatedDate: new Date(),
    reportProgress: 50,
  },
  {
    reportTemplateId: 4,
    documentName: "Tutor. Middle School Math.",
    status: 4,
    createdBy: "John Doe",
    createdDate: new Date(),
    lastUpdatedDate: new Date(),
    reportProgress: 75,
  },
];
