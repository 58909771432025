import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { DashboardQuickLinksProps } from "../interfaces/props/DashboardQuickLinksProps";
import { DashboardQuickLinksMockData } from "../mockData/DashboardQuickLinksMockData";
import { QuickLinksComponent } from "../components/sub-components/QuickLinksComponent";
import { CalendarComponent } from "../components/sub-components/CalendarComponent";
import { QuickLinks } from "../components/dashboard/QuickLinks";
import { Tasks } from "../components/dashboard/Tasks";
import { PostAssignment } from "../components/dashboard/post-assignment/PostAssignment";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { useAuthUser } from "react-auth-kit";
// import _ from 'lodash';

ChartJS.register(ArcElement, Tooltip, Legend);

export const Dashboard = () => {
  const auth = useAuthUser();
  const [dashboardQuickLinksData, setDashboardQuickLinksData] = useState<
    DashboardQuickLinksProps[]
  >([]);

  return (
    <div className="content-wrapper">
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="row">
            <div className="col-12 col-xl-12 mb-4 mb-xl-0">
              <h3 className="font-weight-bold">{`Welcome ${auth()?.name}`}</h3>
              <h6 className="font-weight-normal mb-0">
                All systems are running smoothly! You have{" "}
                <span className="text-primary">3 unread alerts!</span>
              </h6>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <QuickLinks />
      </div>

      <div className="row mb-5">
        <div className="col-lg-12 grid-margin grid-margin-lg-0">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12 mb-5 grid-margin stretch-card">
              <Tasks />
            </div>

            <PostAssignment />
          </div>
        </div>

        <div
          className="col-lg-12 grid-margin grid-margin-lg-0 stretch-card collapase-card"
          id="accordionSchedule"
        >
          <div className="card">
            <div className="card-body">
              <div
                className="card-header shadow-none bg-transparent"
                id="postSchedule"
              >
                <a
                  href="#"
                  className="btn btn-header-link text-dark p-0"
                  data-toggle="collapse"
                  data-target="#accordionSectionSchedule"
                  aria-expanded="true"
                  aria-controls="accordionSectionSchedule"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    width="18px"
                    height="18px"
                  >
                    <path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z" />
                  </svg>
                  <h4 className="card-title m-0 d-inline align-middle">
                    Schedule
                  </h4>
                  <span className="badge badge-danger small">4</span>
                </a>
              </div>
              <div className="my-3">
                <hr />
              </div>
              <CalendarComponent />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <p className="card-title">Other Dashboard Details</p>
              <p className="font-weight-500">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Perspiciatis asperiores id alias accusamus esse unde, quasi quia
                nemo impedit fuga deserunt magnam qui sapiente ullam adipisci
                explicabo ut totam? Assumenda. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Perspiciatis asperiores id alias
                accusamus esse unde, quasi quia nemo impedit fuga deserunt
                magnam qui sapiente ullam adipisci explicabo ut totam?
                Assumenda.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
