import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { filter } from 'lodash';
import { ReportTemplateSectionDto, SectionWorkflowDto } from '../lib/data-transfer-object/report.template.dto';

const initialState: ReportTemplateSectionDto = {
  name: "",
  description: "",
  workflows: [],
};

export const createReportTemplateSectionSlice = createSlice({
  name: 'createReportTemplateSection',
  initialState,
  reducers: {
    changeSectionName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    changeSectionDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    addSectionWorkflow: (state, action: PayloadAction<SectionWorkflowDto>) => {
      state.workflows.push(action.payload);
    },
    updateSectionWorkflows: (state, action: PayloadAction<SectionWorkflowDto>) => {
    
    },
    resetCreateReportTemplateSection: () => initialState
  }
})

export const { changeSectionName, changeSectionDescription, addSectionWorkflow, resetCreateReportTemplateSection, updateSectionWorkflows } = createReportTemplateSectionSlice.actions;

export default createReportTemplateSectionSlice.reducer;