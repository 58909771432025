import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ReportTemplate {
  value: string[]
}
const initialState: ReportTemplate = {
  value: []
}

export const reportTemplateSlice = createSlice({
  name:'reportTemplate',
  initialState,
  reducers: {
    saveReportTemplate: (state, action: PayloadAction<string>) => {
      state.value.push(action.payload)
    }
  }
});

export const { saveReportTemplate } = reportTemplateSlice.actions;

export default reportTemplateSlice.reducer;