import React, { useEffect } from "react";
import CSS from "csstype";
import {
  AssignedWorkflowStatus,
  AssingmentType,
} from "../../../constants/enum";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { LONG_DATE_FORMAT } from "../../../constants/tms";

export interface AssingmentProps {
  id: number;
  name: string;
  status: number;
  dueDate?: string;
  assignmentType: AssingmentType;
}

const cardBodyStyles: CSS.Properties = {
  marginLeft: "100px",
};

const progressStyles: CSS.Properties = {
  height: "20px",
};

const progressBarStyles: CSS.Properties = {
  width: "100%",
};

export const Assignment = (props: AssingmentProps) => {
  const { name, id, status, dueDate, assignmentType } = props;

  const getAssignmentTypeIcon = (): string => {
    switch (assignmentType) {
      case AssingmentType.WORKFLOW:
        return "M249.4 25.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3L269.3 96 416 96c53 0 96 43 96 96v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V192c0-17.7-14.3-32-32-32l-146.7 0 25.4 25.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0l-80-80c-12.5-12.5-12.5-32.8 0-45.3l80-80zm13.3 256l80 80c12.5 12.5 12.5 32.8 0 45.3l-80 80c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 416 96 416c-17.7 0-32 14.3-32 32v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V448c0-53 43-96 96-96l146.7 0-25.4-25.4c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0zM512 384c0 35.3-28.7 64-64 64s-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64zM64 64c35.3 0 64 28.7 64 64s-28.7 64-64 64s-64-28.7-64-64S28.7 64 64 64z";
      case AssingmentType.ACTION_ITEM:
        return "M336 48c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zM141.7 175.5c9.9-9.9 23.4-15.5 37.5-15.5c1.9 0 3.8 .1 5.6 .3L153.6 254c-9.3 28 1.7 58.8 26.8 74.5l86.2 53.9-25.4 88.8c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l28.7-100.4c5.9-20.6-2.6-42.6-20.7-53.9L254 299l30.9-82.4 5.1 12.3C305 264.7 339.9 288 378.7 288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H378.7c-12.9 0-24.6-7.8-29.5-19.7l-6.3-15c-14.6-35.1-44.1-61.9-80.5-73.1l-48.7-15c-11.1-3.4-22.7-5.2-34.4-5.2c-31 0-60.8 12.3-82.7 34.3L73.4 153.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l23.1-23.1zM107.2 352H48c-17.7 0-32 14.3-32 32s14.3 32 32 32h69.6c19 0 36.2-11.2 43.9-28.5L173 361.6l-9.5-6c-17.5-10.9-30.5-26.8-37.9-44.9L107.2 352z";
      case AssingmentType.CHECKLIST:
        return "M184.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L39 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L39 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM256 96c0-17.7 14.3-32 32-32H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H288c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H288c-17.7 0-32-14.3-32-32zM192 416c0-17.7 14.3-32 32-32H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32zM80 464c-26.5 0-48-21.5-48-48s21.5-48 48-48s48 21.5 48 48s-21.5 48-48 48z";
      default:
        return "";
    }
  };

  const getBadge = () => {
    switch (status) {
      case AssignedWorkflowStatus.ACTIVE:
        return "badge-secondary";
      case AssignedWorkflowStatus.COMPLETED:
        return "badge-success";
      default:
        return "";
    }
  };

  const getStatus = () => {
    switch (status) {
      case AssignedWorkflowStatus.ACTIVE:
        return "Active";
      case AssignedWorkflowStatus.COMPLETED:
        return "Completed";
      default:
        return "";
    }
  };

  return (
    <div
      className="accordion collapase-card"
      id={`accordionPostAssignment${id}`}
    >
      <div className="card bg-light border mb-2">
        <div className="card-header" id={`postAssignment${id}`}>
          <a
            href="#"
            className="btn btn-header-link text-dark"
            data-toggle="collapse"
            data-target={`#accordionSectionPostAssignment${id}`}
            aria-expanded="true"
            aria-controls={`accordionSectionPostAssignment${id}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
              width="25px"
              height="25px"
            >
              <path d={getAssignmentTypeIcon()} />
            </svg>
            <span className="h5 font-weight-bold mx-2">{name}</span>
            <span className={`badge ${getBadge()} ml-2`}>
              <b>Status:</b> {`${getStatus()}`}
            </span>
          </a>
        </div>
        <div
          id={`accordionSectionPostAssignment${id}`}
          className="collapse"
          aria-labelledby={`postAssignment${id}`}
          data-parent={`#accordionPostAssignment${id}`}
        >
          <div className="card-body pt-4">
            <div className="row">
              <div className="col-12 mb-3">
                <div className="float-right mt-0" style={cardBodyStyles}>
                  <Link to={`/assignedWorkflow/${id}`}>
                    <button
                      type="button"
                      className="btn btn-primary btn-rounded btn-icon"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Open"
                    >
                      <i
                        className="fa-solid fa-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </Link>
                </div>

                <span className="text-secondary">
                  <i className="far fa-calendar-check"></i> <b>Due Date:</b>
                  {` ${dayjs(dueDate).format(LONG_DATE_FORMAT)}`}
                </span>
                <p className="m-0">{/** Only for Action Items DESCIPTION */}</p>
              </div>
              <div className="col-12 col-sm-6">
                <div className="progress" style={progressStyles}>
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    style={progressBarStyles}
                  >
                    100%
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <span className="badge badge-dark float-right ml-2">
                  <b>Completition:</b> Pending
                </span>
                <span className="badge badge-warning float-right ml-2">
                  <b>Priority:</b> Normal
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
