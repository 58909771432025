import { isEmpty, map } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import {
  CreateReportTemplateDto,
  ReportTemplateDto,
  ReportTemplateSectionDto,
} from "../../lib/data-transfer-object/report.template.dto";
import { SectionCard } from "./SectionCard";

interface CanvassProps {
  //data: CreateReportTemplateDto;
  isPreview: boolean;
}

export const Canvass = (props: CanvassProps) => {
  const { isPreview } = props;

  const createReportTemplate = useSelector(
    (state: RootState) => state.createReportTemplate
  );

  const loadSections = (sections: ReportTemplateSectionDto[]) => {
    return map(sections, (section, index) => {
      return <SectionCard key={index} data={section} isDisabled={isPreview} />;
    });
  };

  const previewValue: CreateReportTemplateDto = {
    isPreview: false,
    reportTemplateName: "",
    header: {
      name: "Report Template Header",
    },
    sections: [
      {
        name: "Section Name",
        workflows: [
          {
            categoryId: 1,
            categoryName: "Category",
            subCategories: [
              {
                subCategoryId: 1,
                subCategoryName: "Sub Category",
                workflows: [
                  {
                    workflowId: 1,
                    workflowName: "Workflow Name",
                    workflowConfig: {
                      isApproverRequired: false,
                      isCommentRequired: false,
                      isFileUploadRequired: false,
                    },
                    actionItems: [
                      {
                        actionItemId: 1,
                        actionItemName: "Action Item 1",
                      },
                      {
                        actionItemId: 2,
                        actionItemName: "Action Item 2",
                      },
                      {
                        actionItemId: 3,
                        actionItemName: "Action Item 2",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    footer: "Report Template Footer",
  };

  useEffect(() => {
    isEmpty(createReportTemplate.header.name);
  }, [createReportTemplate.header.name]);

  return (
    <div className="row">
      <div className="col-12">
        <h3 className="text-center font-weight-bold pb-4 text-muted">
          {!isEmpty(createReportTemplate.header.name)
            ? createReportTemplate.header.name
            : previewValue.header.name}
          {/* <span className="badge badge-pill badge-primary float-right">
            Preview
          </span> Uncomment when function to set the preview to false  */}
        </h3>
        <div className="card mb-3 rounded-0">
          <div id="accordion-template-sections" className="myaccordion w-100">
            {loadSections(
              !isEmpty(createReportTemplate.sections)
                ? createReportTemplate.sections
                : previewValue.sections
            )}
          </div>
        </div>
        <p className="text-muted text-right pt-2">
          {!isEmpty(createReportTemplate.footer)
            ? createReportTemplate.footer
            : previewValue.footer}
        </p>
      </div>
    </div>
  );
};
