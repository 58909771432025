import React, { ChangeEvent, FC, useEffect } from "react";

interface ToggleSwitchProps {
  id: string;
  label?: string;
  name: string;
  value: boolean;
  value_on: string | number;
  value_off: string | number;
  onChange?: (flag: boolean) => void;
}

/*const defaultProps: ToggleSwitchProps = {
  label       : "",
  id          : "",
  name        : "",
  checked     : true,
  class_name  : "btn btn-primary", 
  style       : {},   
  data_on     : "On",
  data_off    : "Off",
}*/

export const ToggleSwitch: FC<ToggleSwitchProps> = (
  props: ToggleSwitchProps
) => {
  const { id, label, name, value, value_on, value_off, onChange } = props;

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) event.target.checked ? onChange(true) : onChange(false);
  };

  return (
    <div className="custom-toggle">
      <small className="font-weight-bold mr-1">{props.label}</small>
      <label className="switch switch-left-right">
        <input
          className="switch-input"
          type="checkbox"
          id={props.id}
          name={props.name}
          checked={props.value}
          onChange={(e) => handleOnChange(e)}
        />
        <span
          className="switch-label"
          data-on={props.value_on}
          data-off={props.value_off}
        />
        <span className="switch-handle" />
      </label>
    </div>
  );
};

//ToggleSwitch.defaultProps = defaultProps;
