import { get, map } from "lodash";
import { useEffect } from "react";
import { ActionItemDto } from "../../lib/data-transfer-object/actionItem.dto";
import {
  ActionItemActionType,
  AssignedWorkflowActionItemStatus,
  AssignedWorkflowStatus,
} from "../../constants/enum";
import { AssignedWorkflowActionItemDto } from "../../lib/data-transfer-object/assignWorkflow.dto";
import {
  ASSIGNED_WORKFLOW_ACTIONITEM_STATUS_ACTIVE,
  ASSIGNED_WORKFLOW_ACTIONITEM_STATUS_NOT_APPLICABLE,
  ASSIGNED_WORKFLOW_ACTIONITEM_STATUS_COMPLETED,
} from "../../constants/tms";

interface ActionItemsProps {
  assignmentStatus: AssignedWorkflowStatus;
  data?: ActionItemDto[] | AssignedWorkflowActionItemDto[];
  onActionItemCompletedOrMarked?: (
    actionItemId: number,
    action: ActionItemActionType
  ) => void;
  onActionItemMarked?: (actionItemId: number) => void;
}

export const ActionItems = (props: ActionItemsProps) => {
  const { data, assignmentStatus, onActionItemCompletedOrMarked } = props;

  const getStatus = (status: number): string => {
    switch (status) {
      case AssignedWorkflowActionItemStatus.ACTIVE: {
        return ASSIGNED_WORKFLOW_ACTIONITEM_STATUS_ACTIVE;
      }
      case AssignedWorkflowActionItemStatus.COMPLETED: {
        return ASSIGNED_WORKFLOW_ACTIONITEM_STATUS_COMPLETED;
      }
      case AssignedWorkflowActionItemStatus.MARKED_NOT_APPLICABLE: {
        return ASSIGNED_WORKFLOW_ACTIONITEM_STATUS_NOT_APPLICABLE;
      }
      default: {
        return "";
      }
    }
  };

  const populateActionItems = () => {
    return map(data, (actionItem, index) => {
      const status = get(actionItem, "actionItemStatus");
      return (
        <tr key={index}>
          {assignmentStatus === AssignedWorkflowStatus.NEW ? (
            <>
              <td>
                <div className="text-wrap">{actionItem.actionItemName}</div>
              </td>
              <td>{actionItem.actionItemDescription}</td>
            </>
          ) : (
            <>
              <td>
                <div className="text-wrap">{actionItem.actionItemName}</div>
              </td>
              <td>{actionItem.actionItemDescription}</td>

              <td>{getStatus(status)}</td>
              <td className="text-center">
                <a
                  href="javascript:void(0);"
                  className="btn btn-primary btn-sm"
                  onClick={() =>
                    onActionItemCompletedOrMarked &&
                    onActionItemCompletedOrMarked(
                      actionItem.actionItemId,
                      ActionItemActionType.TO_COMPLETE
                    )
                  }
                >
                  <i className="fa-solid fa-check pr-2"></i> Complete
                </a>
                &nbsp;
                <a
                  href="javascript:void(0);"
                  className="btn btn-dark btn-sm"
                  onClick={() =>
                    onActionItemCompletedOrMarked &&
                    onActionItemCompletedOrMarked(
                      actionItem.actionItemId,
                      ActionItemActionType.TO_MARK_NOT_APPLICABLE
                    )
                  }
                >
                  <i className="fa-solid fa-xmark pr-2"></i> Mark as N/A
                </a>
              </td>
            </>
          )}
        </tr>
      );
    });
  };

  return (
    <div id="action-items">
      <p className="card-title m-0 mb-2">Action Items</p>
      <div className="mb-4">
        {/** Progress Bar */}
        {/*
                      <small>Progress:</small>
                      <div className="progress" style={{ height: '20px'}}>
                        <div className="progress-bar bg-primary" role="progressbar" style={{width: '50%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">50%</div>
  </div>  */}
      </div>
      <table
        id="data-table-generate-report-action-items"
        className="table table-striped table-bordered mb-5"
        style={{ width: "100%" }}
      >
        <thead>
          {assignmentStatus === AssignedWorkflowStatus.NEW ? (
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
          ) : (
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          )}
        </thead>
        <tbody>{populateActionItems()}</tbody>
      </table>
      <div className="pb-5 mb-5 border-bottom"></div>
    </div>
  );
};
