import React, { useState } from "react";
import CSS from "csstype";
import { CreateActionItemDto } from "../../lib/data-transfer-object/actionItem.dto";
import { ActionItemList } from "./ActionItemList";

interface AddActionItemProps {
  onCancel: () => void;
  onAddActionItem: (actionItem: CreateActionItemDto) => void;
  actionItems: CreateActionItemDto[];
}

const modalBodyStyle: CSS.Properties = {
  height: "500px",
  scrollbarWidth: "thin",
  background: "#f5f7ff",
};

export const AddActionItem = (props: AddActionItemProps) => {
  const { onCancel, onAddActionItem, actionItems } = props;

  const initialValues: CreateActionItemDto = {
    name: "",
    description: "",
  };

  const [actionItem, setActionItem] =
    useState<CreateActionItemDto>(initialValues);

  const handleOnCancelClick = () => {
    onCancel();
  };

  const handleOnAddClick = () => {
    onAddActionItem(actionItem);
  };

  return (
    <div
      className="modal fade"
      id="modal-add-action-items"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header p-3 pr-4">
            <h5 className="modal-title">
              <i className="mdi  mdi mdi-format-indent-increase"></i> Add
              Reference
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body p-4 overflow-auto" style={modalBodyStyle}>
            <div className="row">
              <div className="col-12 col-sm-4">
                <div className="card">
                  <div className="card-body">
                    <p className="card-title">
                      <i className="fa-solid fa-plus-circle mr-2"></i>
                      Add Action Items
                    </p>
                    <div className="my-3">
                      <hr />
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Action Item"
                          value={actionItem.name}
                          onChange={(e) =>
                            setActionItem({
                              ...actionItem,
                              name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <textarea
                          className="form-control"
                          id="description"
                          rows={4}
                          placeholder="Description"
                          value={actionItem.description}
                          onChange={(e) =>
                            setActionItem({
                              ...actionItem,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm float-right"
                      onClick={() => handleOnAddClick()}
                    >
                      <i className="fa fa-plus-circle mr-2"></i>
                      Add
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-8">
                <div className="card">
                  <div className="card-body">
                    <p className="card-title">
                      <i className="fa-fw fa-solid fa-person-running mr-2"></i>
                      List of Action Items
                    </p>
                    <div className="my-3">
                      <hr />
                    </div>
                    <ActionItemList actionItems={actionItems} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer py-2">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              data-dismiss="modal"
              onClick={() => handleOnCancelClick}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
