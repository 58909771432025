import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import {
  CATEGORY_TEMPLATE_FILE_NAME,
  DOWNLOAD_TEMPLATE_API,
} from "../../constants/tms";
import { ImportSettings } from "./ImportSettings";
import { UploadFile } from "./UploadFile";

export interface ImporterProps {
  onUpload: () => void;
  onUploadError: () => void;
}

export const TaskCategoryDataImporter = (props: ImporterProps) => {
  const { onUpload, onUploadError } = props;
  const downloadTemplate = (templateName: string) => {
    const fileExtension = "xlsx";
    const fileName = `${templateName}.${fileExtension}`;
    const API_URL = `${DOWNLOAD_TEMPLATE_API}/${fileName}`;

    axios
      .request({
        url: API_URL,
        method: "GET",
        responseType: "blob",
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-md-12 col-12">
            <p className="card-title">
              <i className="fa-solid fa-file-import"></i>
              Import Data
              <small>(Task Categories &amp; Sub Categories)</small>
            </p>
            <div className="my-3">
              <hr />
            </div>
          </div>
          <div className="col-md-12 col-12">
            <div className="callout callout-main">
              <h4>Instructions</h4>
              <ol>
                <li>
                  <Link
                    to="/importdata"
                    onClick={() =>
                      downloadTemplate(CATEGORY_TEMPLATE_FILE_NAME)
                    }
                    className="text-primary"
                  >
                    Download the example file and check the file format.
                  </Link>
                </li>
                <li>Do not forget to respect the format.</li>
                <li>
                  Fill the template you donloaded on step 1. Then upload this
                  file to the system.
                </li>
                <li>Select the Sheet Name and Row Number where data begins.</li>
                <li>
                  Click on import and wait for the process to finish. Then check
                  the results on the table below.
                </li>
              </ol>
            </div>
          </div>

          <div className="col-md-12 col-12 mt-5">
            <ul className="nav nav-tabs" id="setting-panel" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="upload-file-tab"
                  data-toggle="tab"
                  href="#upload-file-section"
                  role="tab"
                  aria-controls="upload-file-section"
                  aria-expanded="true"
                >
                  <i className="fa-solid fa-upload"></i> Upload File
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="import-settings-tab"
                  data-toggle="tab"
                  href="#import-settings-section"
                  role="tab"
                  aria-controls="import-settings-section"
                >
                  <i className="fa-solid fa-gears"></i> Import Settings
                </a>
              </li>
            </ul>
            <div className="tab-content pt-0" id="setting-content">
              <div
                className="tab-pane fade show active scroll-wrapper mt-4"
                id="upload-file-section"
                role="tabpanel"
                aria-labelledby="upload-file-section"
              >
                <div className="wrapper wrapper-content animated fadeInRight">
                  <UploadFile />
                </div>
              </div>
              <div
                className="tab-pane fade mt-4"
                id="import-settings-section"
                role="tabpanel"
                aria-labelledby="import-settings-section"
              >
                <ImportSettings
                  source="TaskCategory"
                  onSuccessUpload={onUpload}
                  onErrorUpload={onUploadError}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
