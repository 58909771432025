import { get, isEmpty, map } from "lodash";
import Multiselect from "multiselect-react-dropdown";
import { FC, useState } from "react";

interface CustoMultiSelectProps {
  options: unknown;
  displayValue: string;
  onSelect: (selectedValues: { userId?: string }[]) => void;
  onRemove: (selectedValues: { userId?: string }[]) => void;
}

const styles = {
  searchBox: {
    // To change search box element look
    border: "none",
    "font-size": "10",
    "min-height": "15px",
  },
  chips: {
    background: "#4b49ac",
  },
  option: {
    // To change css for dropdown options
    color: "#4b49ac",
    background: "white",
  },
  inputField: {},
};

export const CustomMultiSeclect: FC<CustoMultiSelectProps> = (
  props: CustoMultiSelectProps
) => {
  const { options, onSelect, onRemove, displayValue } = props;
  const [selectedValues, setSelectedValues] = useState<unknown[]>([]);

  const handleOnSelect = (e: unknown[]) => {
    setSelectedValues(e);
    const values = map(e, (x) => {
      return {
        userId: get(x, "userId"),
      };
    });
    onSelect(values);
  };

  const handleOnRemove = (e: unknown[]) => {
    setSelectedValues(e);
    const values = map(e, (x) => {
      return {
        userId: get(x, "userId"),
      };
    });
    onRemove(values);
  };

  return (
    <Multiselect
      options={options}
      selectedValues={selectedValues} // Preselected value to persist in dropdown
      onSelect={(e) => handleOnSelect(e)} // Function will trigger on select event
      onRemove={(e) => handleOnRemove(e)} // Function will trigger on remove event
      displayValue={displayValue}
      className="creators-hub-simulate-modal-input-text w-100 mt-2"
      style={styles}
      placeholder={isEmpty(selectedValues) ? "Select.." : ""}
    />
  );
};
