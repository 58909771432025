import dayjs from "dayjs";
import { get, map } from "lodash";
import React, { useEffect, useState } from "react";
import { useFetch } from "../lib/hooks/useFetch";
import {
  GetAllUserFileResponseDto,
  UserFileDto,
} from "../lib/data-transfer-object/userFile.dto";
import { GET_ALL_REFERENCE_MATERIALS } from "../constants/api-url";
import { useAuthUser } from "react-auth-kit";
import { AssignedWorkflowStatus, FileType } from "../constants/enum";
import { LONG_DATE_FORMAT } from "../constants/tms";

enum FileLocationTab {
  HOME = 1,
  REPORT_ATTACHMENT = 2,
  TICKET_ATTACHMENT = 3,
  OTHER = 4,
}

enum DataView {
  GRID = 1,
  LIST = 2,
}

interface FileLocationProps {
  tabId: number;
  text: string;
  active: boolean;
  onTabClick: (tabId: number) => unknown;
}

interface ItemProps {
  fileId: number;
  fileType: FileType;
  fileName: string;
  status?: AssignedWorkflowStatus; // ENUM???
  workflowName?: string;
  dueDate?: string;
  actions?: {
    onOpen: () => void;
    onArchive: () => void;
    onPin: () => void;
  };
}

interface ViewProps {
  data: UserFileDto[];
}

const FileLocation = (props: FileLocationProps) => {
  const { tabId, text, active, onTabClick } = props;
  const getIcon = () => {
    switch (tabId) {
      case FileLocationTab.HOME:
        return "fa-house";
      case FileLocationTab.REPORT_ATTACHMENT:
        return "fa-file-lines";
      case FileLocationTab.TICKET_ATTACHMENT:
        return "fa-ticket";
      case FileLocationTab.OTHER:
        return "fa-file-circle-exclamation";
    }
  };
  return (
    <a
      href="#"
      className={`list-group-item list-group-item-action ${
        active ? "active" : ""
      }`}
      onClick={() => onTabClick(tabId)}
    >
      <i className={`fa-solid ${getIcon()} mr-2`} />
      {text}
    </a>
  );
};

const FileLocations = [
  {
    tabId: 1,
    text: "Home",
    active: true,
  },
  {
    tabId: 2,
    text: "Report Attachment",
    active: false,
  },
  {
    tabId: 3,
    text: "Ticket Attachment",
    active: false,
  },
  {
    tabId: 4,
    text: "Other Files",
    active: false,
  },
];

const GridViewItem = (props: ItemProps) => {
  const { fileName, fileType } = props;

  const renderIcon = (): string => {
    const base = "fa-solid";
    switch (fileType) {
      case FileType.PDF: {
        return `${base} fa-file-pdf fa-3x text-danger`;
      }
      case FileType.SPREEDSHEET: {
        return `${base} fa-file-excel fa-3x text-success`;
      }
      case FileType.WORD: {
        return `${base} fa-file-word fa-3x text-info`;
      }
      case FileType.IMG: {
        return `${base} fa-file-image fa-3x text-primary`;
      }
      case FileType.POWERPOINT: {
        return `${base} fa-file-powerpoint fa-3x text-danger`;
      }
      case FileType.VIDEO: {
        return `${base} fa-file-video fa-3x text-warning`;
      }
      default: {
        return "";
      }
    }
  };
  return (
    <div className="col mb-4 text-center">
      <div className="card border">
        <div className="card-body">
          <i className={renderIcon()} />
          <p className="font-weight-bold mt-2">{fileName}</p>
          <div className="border-top pt-3">
            <a
              href="#"
              className="mx-2 text-dark text-decoration-none"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Open File"
            >
              <i className="ti-arrow-circle-right" />
            </a>
            <a
              href="#"
              className="mx-2 text-danger text-decoration-none"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Archive File"
            >
              <i className="ti-archive" />
            </a>
            <a
              href="#"
              className="mx-2 text-primary text-decoration-none"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Unpin File"
            >
              <i className="ti-pin2" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const GridView = (props: ViewProps) => {
  const { data } = props;
  return (
    <div
      className="tab-pane fade show active scroll-wrapper"
      id="grid-view-section"
      role="tabpanel"
      aria-labelledby="grid-view-section"
    >
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row row-cols-2 row-cols-md-4">
          {map(data, (item, index) => {
            return <GridViewItem key={index} {...item} />;
          })}
        </div>
      </div>
    </div>
  );
};

const ListViewItem = (props: ItemProps) => {
  const { fileName, dueDate, workflowName, status, fileType } = props;
  const renderIcon = (): string => {
    const base = "fa-solid";
    switch (fileType) {
      case FileType.PDF: {
        return `${base} fa-file-pdf text-danger mr-2`;
      }
      case FileType.SPREEDSHEET: {
        return `${base} fa-file-excel text-success mr-2`;
      }
      case FileType.WORD: {
        return `${base} fa-file-word text-info mr-2`;
      }
      case FileType.IMG: {
        return `${base} fa-file-image text-primarys mr-2`;
      }
      case FileType.POWERPOINT: {
        return `${base} fa-file-powerpoint text-danger mr-2`;
      }
      case FileType.VIDEO: {
        return `${base} fa-file-video text-warning mr-2`;
      }
      default: {
        return "";
      }
    }
  };
  return (
    <tr>
      <td>
        <i className={renderIcon()} />
        {fileName}
      </td>
      <td>{workflowName}</td>
      <td>{status}</td>
      <td>{dayjs(dueDate).format(LONG_DATE_FORMAT)}</td>
      <td>
        <a
          href="#"
          className="mx-2 text-dark text-decoration-none"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Open File"
        >
          <i className="ti-arrow-circle-right" />
        </a>
        <a
          href="#"
          className="mx-2 text-danger text-decoration-none"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Archive File"
        >
          <i className="ti-archive" />
        </a>
        <a
          href="#"
          className="mx-2 text-primary text-decoration-none"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Unpin File"
        >
          <i className="ti-pin2" />
        </a>
      </td>
    </tr>
  );
};

const ListView = (props: ViewProps) => {
  const { data } = props;
  return (
    <div
      className="tab-pane fade show active scroll-wrapper"
      id="list-section"
      role="tabpanel"
      aria-labelledby="list-section"
    >
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="table-responsive table-hover">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">File Name</th>
                <th scope="col">Task Name</th>
                <th scope="col">Status</th>
                <th scope="col">Due Date</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {map(data, (item, index) => {
                return <ListViewItem key={index} {...item} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export const Files = () => {
  const [files, setFiles] = useState<UserFileDto[]>([]);
  const [activeLocation, setActiveLocation] = useState<number>(
    FileLocationTab.HOME
  );
  const [dataView, setDataView] = useState<number>(DataView.GRID);

  const auth = useAuthUser();
  const { loaded, response, fetchErrors } = useFetch<GetAllUserFileResponseDto>(
    `${GET_ALL_REFERENCE_MATERIALS}?userId=${auth()?.userId}`
  );

  const onChangeTab = (tabId: number) => {
    setActiveLocation(tabId);
  };

  const mappedResponse = () => {
    const data = get(response, "data", []);
    setFiles([...data]);
  };

  useEffect(() => {
    loaded && mappedResponse();
  }, [loaded, response]);

  const renderLocations = () => {
    return map(FileLocations, (location, index) => {
      return (
        <FileLocation
          key={index}
          {...location}
          active={activeLocation === location.tabId ? true : false}
          onTabClick={onChangeTab}
        />
      );
    });
  };

  return (
    <div className="content-wrapper">
      <div className="row">
        <div className="col-md-3 col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 col-12">
                  <p className="card-title">
                    <i className="fa-solid fa-folder-tree mr-1" />
                    File Locations
                  </p>
                  <div className="my-3">
                    <hr />
                  </div>
                </div>
                <div className="col-md-12 col-12">
                  <div className="list-group">{renderLocations()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-9 col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 col-12">
                  <p className="card-title">
                    <i className="fa-solid fa-laptop-file mr-1" />
                    Files
                  </p>
                  <div className="my-3">
                    <hr />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 col-12 mb-2">
                  <button type="button" className="btn btn-primary mr-1">
                    <i className="fa-solid fa-arrows-rotate" />
                  </button>
                  <div className="btn-group">
                    <button type="button" className="btn btn-primary">
                      <i className="fa-solid fa-file" /> All Files
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary dropdown-toggle dropdown-toggle-split mr-1"
                      id="dropdownMenuSplitButton1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span className="sr-only">Toggle Dropdown</span>
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuSplitButton1"
                    >
                      <a className="dropdown-item" role="button">
                        <i className="fa-solid fa-file-pdf mr-2" /> Documents
                      </a>
                      <a className="dropdown-item" role="button">
                        <i className="fa-solid fa-file-image mr-2" />
                        Images
                      </a>
                      <a className="dropdown-item" role="button">
                        <i className="fa-solid fa-file-video mr-2" />
                        Media Files
                      </a>
                    </div>
                  </div>
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                      id="dropdownMenuSplitButton2"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span className="mr-2">Sort</span>
                      <span className="sr-only">Toggle Dropdown</span>
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuSplitButton2"
                    >
                      <a className="dropdown-item" role="button">
                        Newest
                      </a>
                      <a className="dropdown-item" role="button">
                        Oldest
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-12 mb-2">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search for..."
                      aria-label="Search for..."
                    />
                    <div className="input-group-append">
                      <button className="btn btn-sm btn-primary" type="button">
                        <i className="icon-search" />
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="my-3">
                    <hr />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <ul
                    className="nav nav-tabs float-right"
                    id="setting-panel"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          dataView === DataView.GRID ? "active" : ""
                        }`}
                        id="grid-view-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="grid-view-section"
                        aria-expanded="true"
                        onClick={() => setDataView(DataView.GRID)}
                      >
                        <i className="fa-solid fa-table-cells" />
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          dataView === DataView.LIST ? "active" : ""
                        }`}
                        id="list-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="list-section"
                        onClick={() => setDataView(DataView.LIST)}
                      >
                        <i className="fa-solid fa-list-ul" />
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content mt-5" id="setting-content">
                    {dataView === DataView.GRID ? (
                      <GridView data={files} />
                    ) : (
                      <ListView data={files} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
