import React, { useEffect, useState } from "react";
import { AssignedWorkflowStatus, CompletionMode } from "../../constants/enum";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import {
  changeAlertDays,
  changeCompeteDays,
  changeCompletionMode,
  changeDueDate,
} from "../../features/assignWorkflowSlice";

interface GeneralInfoProps {
  isReadOnly: boolean;
  data?: GeneralInfoDto;
}

type GeneralInfoDto = {
  dueDate: string;
  competeDays?: number;
  alertDays?: number;
  recurrence: boolean;
  completionMode: CompletionMode;
};

const initialValue: GeneralInfoDto = {
  dueDate: dayjs().format("YYYY-MM-DD"),
  recurrence: false,
  completionMode: CompletionMode.EVERY_USER,
};

export const GeneralInformation = (props: GeneralInfoProps) => {
  const { isReadOnly, data } = props;
  const [generalInfoValue, setGeneralInfoValue] =
    useState<GeneralInfoDto>(initialValue);

  const dispatch = useDispatch();

  const onDueDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGeneralInfoValue({ ...generalInfoValue, dueDate: event.target.value });
    dispatch(changeDueDate(event.target.value));
  };

  const onCompeteOrAlertChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setGeneralInfoValue({
      ...generalInfoValue,
      [event.target.id]: event.target.value,
    });

    if (event.target.id === "competeDays") {
      dispatch(changeCompeteDays(Number(event.target.value)));
    } else {
      dispatch(changeAlertDays(Number(event.target.value)));
    }
  };

  const onCompletionModeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === "1") {
      setGeneralInfoValue({
        ...generalInfoValue,
        completionMode: CompletionMode.EVERY_USER,
      });
    } else {
      setGeneralInfoValue({
        ...generalInfoValue,
        completionMode: CompletionMode.AT_LEAST_ONE,
      });
    }
    dispatch(changeCompletionMode(Number(event.target.value)));
  };

  useEffect(() => {
    data && setGeneralInfoValue({ ...data });
  }, [data]);

  return (
    <div id="general-information">
      <p className="card-title m-0 mb-2">General Information</p>
      <div className="card bg-light border">
        <div className="card-body pb-3">
          <div className="row">
            <div className="col-12 col-sm-3">
              <p className="m-0">
                <b className="text-primary mr-2">Due Date:</b>
                <input
                  type="date"
                  name="dueDate"
                  id="dueDate"
                  value={generalInfoValue.dueDate}
                  disabled={isReadOnly}
                  className="creators-hub-simulate-modal-input-text w-100 mt-4"
                  onChange={(e) => onDueDateChange(e)}
                />
              </p>
            </div>
            <div className="col-12 col-sm-3">
              <p className="m-0">
                <b className="text-primary mr-2">Compete:</b>
                <input
                  type="text"
                  name="competeDays"
                  id="competeDays"
                  disabled={isReadOnly}
                  value={generalInfoValue.competeDays}
                  className="creators-hub-simulate-modal-input-text w-100 mt-4"
                  onChange={(e) => onCompeteOrAlertChange(e)}
                />
              </p>
            </div>
            <div className="col-12 col-sm-3">
              <p className="m-0">
                <b className="text-primary mr-2">Alerts:</b>
                <input
                  type="text"
                  name="alertDays"
                  id="alertDays"
                  value={generalInfoValue.alertDays}
                  disabled={isReadOnly}
                  className="creators-hub-simulate-modal-input-text w-100 mt-4"
                  onChange={(e) => onCompeteOrAlertChange(e)}
                />
              </p>
            </div>
            <div className="col-12 col-sm-3">
              <b className="text-primary mr-2">Recurrence:</b>
              <div className="custom-toggle">
                <label className="switch switch-left-right m-0">
                  <input
                    className="switch-input"
                    type="checkbox"
                    name="recurrence"
                    id="recurrence"
                    disabled={isReadOnly}
                  />
                  <span
                    className="switch-label"
                    data-on="Yes"
                    data-off="No"
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
            <div className="col-12 mt-4">
              <div className="form-inline float-right">
                <b className="text-primary mr-3">Completion Mode:</b>
                <div className="form-check mr-5">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="every-user"
                      id="every-user"
                      value={CompletionMode.EVERY_USER}
                      disabled={isReadOnly}
                      checked={
                        generalInfoValue.completionMode ===
                        CompletionMode.EVERY_USER
                      }
                      onChange={(e) => onCompletionModeChange(e)}
                    />
                    Every User
                    <i className="input-helper"></i>
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="atleast-one"
                      id="atleast-one"
                      disabled={isReadOnly}
                      value={CompletionMode.AT_LEAST_ONE}
                      checked={
                        generalInfoValue.completionMode ===
                        CompletionMode.AT_LEAST_ONE
                      }
                      onChange={(e) => onCompletionModeChange(e)}
                    />
                    At least one
                    <i className="input-helper"></i>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-5 mb-5 border-bottom"></div>
    </div>
  );
};
