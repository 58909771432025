import React from "react";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="d-sm-flex justify-content-center justify-content-sm-between">
        <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
          Copyright © 2022 - My Work Product. All rights reserved.
        </span>
      </div>
    </footer>
  );
};
