import dayjs from "dayjs";
import { findIndex, map } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { TaskStatus, WorkflowStatus } from "../../../constants/enum";
import { SectionWorkflowDto } from "../../../lib/data-transfer-object/report.template.dto";
import { SimulateWorkflow } from "../../../lib/data-transfer-object/simulate.report.template.dto";
import { TemplateTaskTable } from "./TemplateTaskTable";

// TODO: Change the name of this component it should be Workflow/SimulateWorflows
interface TemplateTaskProps {
  activeTemplateTask: any;
  simulateWorkflows: SimulateWorkflow[];
  onClick(activeTemplateTask: any): void;
  onChangeAssignTo?(
    subCategoryId: number,
    workflowId: number,
    newAssignTo: string
  ): void;
  onChangeAssignToActionItem?(
    subCategoryId: number,
    workflowId: number,
    actionItemId: number,
    newAssignTo: string
  ): void;
  onChangeApprover?(
    subCategoryId: number,
    workflowId: number,
    newAssignTo: string
  ): void;
  onChangeApproverActionItem?(
    subCategoryId: number,
    workflowId: number,
    actionItemId: number,
    newAssignTo: string
  ): void;
}

export const TemplateTasks = (props: TemplateTaskProps) => {
  const {
    onClick,
    simulateWorkflows,
    onChangeAssignTo,
    onChangeApprover,
    onChangeAssignToActionItem,
    onChangeApproverActionItem,
  } = props;

  //const [templateTasks, setTemplateTasks] = useState<any[]>([]);

  const handleTemplateTaskClick = (activeTemplateTask: any) => {
    console.log(activeTemplateTask);
    onClick(activeTemplateTask);
  };

  const handleChangeAssignTo = (
    subCategoryid: number,
    workflowId: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onChangeAssignTo &&
      onChangeAssignTo(subCategoryid, workflowId, event.target.value);
  };

  const handleChangeAssignToActionItem = (
    subCategoryid: number,
    workflowId: number,
    actionItemId: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onChangeAssignToActionItem &&
      onChangeAssignToActionItem(
        subCategoryid,
        workflowId,
        actionItemId,
        event.target.value
      );
  };

  const handleChangeApprover = (
    subCategoryid: number,
    workflowId: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onChangeApprover &&
      onChangeApprover(subCategoryid, workflowId, event.target.value);
  };

  const handleChangeApproverActionItem = (
    subCategoryid: number,
    workflowId: number,
    actionItemId: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onChangeApproverActionItem &&
      onChangeApproverActionItem(
        subCategoryid,
        workflowId,
        actionItemId,
        event.target.value
      );
  };

  const getStatusStyle = (status: WorkflowStatus): string => {
    switch (status) {
      case WorkflowStatus.ACTIVE: {
        return "#fc8a34";
      }
      case WorkflowStatus.COMPLETED: {
        return "#529f50";
      }
      case WorkflowStatus.REJECTED: {
        return "#ba3742";
      }
      case WorkflowStatus.SKIPED: {
        return "#959ca6";
      }
    }
    return "#000";
  };

  const getStatusText = (status: WorkflowStatus): string => {
    switch (status) {
      case WorkflowStatus.ACTIVE: {
        return "Active";
      }
      case WorkflowStatus.COMPLETED: {
        return "Completed";
      }
      case WorkflowStatus.REJECTED: {
        return "Rejected";
      }
      case WorkflowStatus.SKIPED: {
        return "N/A";
      }
    }
    return "Pending";
  };

  const handleDataChange = () => {};

  return (
    <div className="col-12">
      {/*map(data, (task, index) => {
        return (
          <TemplateTaskTable
            key={index}
            id={task.id}
            data={task.workflows}
            onRowClick={handleTemplateTaskClick}
          />
        );
      })*/}
      {map(simulateWorkflows, (workflow, index) => {
        const {
          id,
          workflowName,
          status,
          workflowDueDate,
          workflowApprover,
          workflowAssignTo,
        } = workflow;
        return (
          <div className="table-responsive" key={index}>
            <span className="badge badge-primary">
              <i className="fa-solid fa-list-check mr-2"></i> {`Task ${1}`}
            </span>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Task Name</th>
                  <th>Recurrence</th>
                  <th>Assigned To</th>
                  <th>Approver</th>
                  <th>Due Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr className="font-weight-bold" key={index}>
                  <td>{workflowName}</td>
                  <td>
                    <div className="col-12">
                      <div className="custom-toggle">
                        <label className="switch switch-left-right m-0">
                          <input
                            className="switch-input"
                            type="checkbox"
                            name="recurrence"
                            id="recurrence"
                          />
                          <span
                            className="switch-label"
                            data-on="Yes"
                            data-off="No"
                          ></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input
                      type="text"
                      value={workflowAssignTo}
                      className="creators-hub-simulate-modal-input-text w-75 font-weight-bold"
                      id={`workflow-assignto-${id}`}
                      placeholder="&#xf304;"
                      onChange={(e) =>
                        //handleChangeAssignTo(task.id, workflowId, e)
                        console.log("foo")
                      }
                    />
                  </td>
                  <td>
                    {/*<img
                      src="images/faces/face1.jpg"
                      alt="image"
                      className="mr-3"
            />*/}
                    <input
                      type="text"
                      value={workflowApprover}
                      className="creators-hub-simulate-modal-input-text w-75 font-weight-bold"
                      id={`workflow-apporver-${id}`}
                      placeholder="&#xf304;"
                      onChange={(e) =>
                        //handleChangeApprover(task.id, workflowId, e)
                        console.log("foo")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="date"
                      value={workflowDueDate}
                      className="creators-hub-simulate-modal-input-text w-100 font-weight-bold"
                    />
                  </td>
                  <td className="my-auto">
                    <svg height="13" width="10">
                      <circle
                        cx="5"
                        cy="5"
                        r="5"
                        fill={getStatusStyle(status)}
                      />
                    </svg>
                    {` ${getStatusText(status)}`}
                  </td>
                  <td>
                    <a
                      href="javascript:void(0);"
                      data-toggle="modal"
                      data-target="#modal-fullscreen-xl-generate-report-action"
                      onClick={() => handleTemplateTaskClick(workflow)}
                    >
                      <i className="fa-solid fa-arrow-up-right-from-square"></i>
                    </a>
                  </td>
                </tr>
                {map(workflow.actionItems, (actionItem, index) => {
                  const {
                    actionItemId,
                    actionItemName,
                    status,
                    actionItemDueDate,
                    actionItemApprover,
                    actionItemAssignTo,
                  } = actionItem;
                  return (
                    <tr key={index}>
                      <td className="pl-5">{actionItemName}</td>
                      <td className="pl-5"></td>
                      <td>
                        <input
                          type="text"
                          className="creators-hub-simulate-modal-input-text w-75 font-weight-bold"
                          value={actionItemAssignTo}
                          id={`action-item-assignto-${workflowName}-${actionItemName}`}
                          placeholder="&#xf304;"
                          onChange={(e) =>
                            /*handleChangeAssignToActionItem(
                                    task.id,
                                    workflowId,
                                    actionItem.id,
                                    e
                                  )*/
                            console.log("foo")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="creators-hub-simulate-modal-input-text w-75 font-weight-bold"
                          value={actionItemApprover}
                          id={`action-item-approver-${workflowName}-${actionItemName}`}
                          placeholder="&#xf304;"
                        />
                      </td>
                      <td>
                        <input
                          type="date"
                          value={actionItemDueDate}
                          className="creators-hub-simulate-modal-input-text w-100"
                        />
                      </td>
                      <td>
                        <svg height="13" width="10">
                          <circle
                            cx="5"
                            cy="5"
                            r="5"
                            fill={getStatusStyle(status)}
                          />
                        </svg>
                        {` ${getStatusText(status)}`}
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
};
