import React, { useEffect, useState } from 'react';
import { DataTable } from "../../components/custom-web-controls/DataTable";
import { GenerateReportDetails } from '../../components/report-templates/GenerateReportDetails';
import { GenerateReportProps } from '../../interfaces/props/GenerateReportProps';
import { GenerateReportMockData } from '../../mockData/GenerateReportMockData';
import { GenerateReportArchiveReportProps } from '../../interfaces/props/GenerateReportArchiveReportProps';
import { GenerateReportArchiveReportMockData } from '../../mockData/GenerateReportArchiveReportMockData';
import { map } from 'lodash';





export const GenerateReport = () => {
  let mappedGenerateReportDetailsData: GenerateReportProps[];  
  let mappedGenerateReportArchiveReportData: GenerateReportArchiveReportProps[];  

  const [generateReportsData, setGenerateReportsData] = useState<GenerateReportProps[]>([]);
  const [archiveReportsData, setArchiveReportsData] = useState<GenerateReportArchiveReportProps[]>([]);

  // Fetch from API
  // Might need to convert this in a custom hooks
  useEffect(() => {
    mappedGenerateReportDetailsData = GenerateReportMockData.map(report => {
      return {
        ...report        
      }
    });

    mappedGenerateReportArchiveReportData = GenerateReportArchiveReportMockData.map(report => {
      return {
        ...report        
      }
    });
  }, []);
    
  useEffect(() => {    
    mappedGenerateReportDetailsData && setGenerateReportsData(mappedGenerateReportDetailsData);
    mappedGenerateReportArchiveReportData && setArchiveReportsData(mappedGenerateReportArchiveReportData);
  },[generateReportsData, archiveReportsData]);

  const archiveReportsTableHeader = [
    { heading : 'Document Name', value : 'documentName' }, 
    { heading: 'Generated By', value : 'generatedBy'},
    { heading: 'Belongs To', value : 'belongsTo'},
    { heading: 'From', value : 'from'},
    { heading: 'To', value : 'to'},
    { heading: 'Generation', value : 'generation'},
    { heading: 'Actions', value : 'actions'} 
  ];
  
  // console.log(archiveReportsData);  

  return (
    <>      
      <div className="content-wrapper">
        <div className="row">
          {
            map(generateReportsData, (item, index) => (
              <GenerateReportDetails key={index} {...item} />
            ))
          }
          
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="form-check">
                  <label className="form-check-label">
                    <input type="checkbox" className="form-check-input" />
                    Show Archived Reports
                    <i className="input-helper" />
                  </label>
                </div>
                <hr />  
                <DataTable headers={archiveReportsTableHeader} data={archiveReportsData}/>              
              </div>
            </div>
          </div>
        </div>
      </div>      
    </>
  )
}