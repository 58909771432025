import { get } from "lodash";
import React, { useState } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { setUploadedFile } from "../../features/importerConfigSlice";

export const UploadFile = () => {
  const [file, setFile] = useState<File>();
  const dispatch = useDispatch();

  const { getRootProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) => {
        setFile(file);
        dispatch(setUploadedFile(file));
      });
    },
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
      "application/vnd.ms-excel": [],
    },
    multiple: false,
  });

  const changeIconClass = (): string => {
    return get(file, "name")
      ? "fa-sharp fa-solid fa-file-excel fa-5x d-block mt-5"
      : "fa-solid fa-cloud-arrow-up fa-5x d-block mt-5";
  };

  return (
    <section>
      <div {...getRootProps({ className: "dropzone" })}>
        <form className="needsclick">
          <div className="dz-message needsclick">
            <i className={changeIconClass()}></i>
            {get(file, "name")
              ? file?.name
              : "Drop files here or click to upload."}
            <br />
          </div>
        </form>
      </div>
    </section>
  );
};
