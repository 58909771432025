import CSS from "csstype";

const modalBodyStyles: CSS.Properties = {
  scrollbarWidth: "thin",
  background: "#f5f7ff",
};

interface ApproveRejectProps {
  onApproved(): void;
  onRejected(): void;
}

export const ApproveReject = (props: ApproveRejectProps) => {
  const { onApproved, onRejected } = props;

  const handleOnApprovedClick = () => {
    onApproved();
  };

  const handleOnRejectClick = () => {
    onRejected();
  };
  return (
    <div
      className="modal fade"
      id="modal-sent-for-approval"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header p-3 pr-4">
            <h5 className="modal-title">
              <i className="mdi  mdi mdi-format-indent-increase"></i> Approve /
              Reject
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body p-4 overflow-auto" style={modalBodyStyles}>
            <div className="row">
              <div className="col-12">
                <textarea
                  name="reason"
                  id="reason"
                  rows={10}
                  placeholder="Reason"
                  className="form-control"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="modal-footer py-2">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              data-dismiss="modal"
              onClick={() => handleOnApprovedClick()}
            >
              <i className="fa-regular fa-thumbs-up"></i> Approve
            </button>
            <button
              type="button"
              className="btn btn-danger btn-sm"
              data-dismiss="modal"
              onClick={() => handleOnRejectClick()}
            >
              <i className="fa-regular fa-thumbs-down"></i> Reject
            </button>
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
