import { map } from "lodash";
import React, { ChangeEvent, useEffect } from "react";

export interface OptionsProps {
  text: string;
  value: string | number;
}
export interface SelectProps {
  id: string;
  name: string;
  required?: boolean;
  placeholder?: string;
  options: OptionsProps[];
  onChange?: (value: string | number) => void;
}

export const Select: React.FC<SelectProps> = (props) => {
  const { id, name, placeholder, required, options, onChange } = props;

  const populateOptions = (options: OptionsProps[]) => {
    return map(options, (option, key) => {
      return (
        <option key={key} value={option.value}>
          {option.text}
        </option>
      );
    });
  };

  const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onChange && onChange(event.target.value);
  };

  return (
    <select
      id={id}
      className="form-control"
      required={required}
      name={name}
      onChange={(e) => handleOnChange(e)}
    >
      <option disabled selected>
        {placeholder && placeholder}
      </option>
      {populateOptions(options)}
    </select>
  );
};

Select.defaultProps = {
  id: "",
  required: false,
  options: [],
};
