import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { ImportOptions } from "../../components/settings/ImportOptions";
import { TaskCategoryDataImporter } from "../../components/settings/TaskCategoryDataImporter";
import { TemplateTaskDataImporter } from "../../components/settings/TemplateTaskDataImporter";
import { ImporterTabs } from "../../constants/enum";
import { ErrorModal, SuccessModal } from "../../components/shared";

export const ImportData = () => {
  const importerConfig = useSelector(
    (state: RootState) => state.importerConfig
  );

  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const onUpload = () => {
    setShowSuccessModal(true);
  };

  const onUploadError = () => {
    console.log("foo1");
    setHasError(true);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="row">
          <div className="col-md-3 col-12 grid-margin stretch-card">
            <ImportOptions />
          </div>

          <div className="col-md-9 col-12 grid-margin stretch-card">
            {importerConfig.activeTab === ImporterTabs.TASK_CATEGORY ? (
              <TaskCategoryDataImporter
                onUpload={onUpload}
                onUploadError={onUploadError}
              />
            ) : (
              <TemplateTaskDataImporter
                onUpload={onUpload}
                onUploadError={onUploadError}
              />
            )}
          </div>
        </div>
      </div>
      {hasError && <ErrorModal errors={[]} />}
      {showSuccessModal ? (
        <SuccessModal
          message={`${
            importerConfig.activeTab === ImporterTabs.TASK_CATEGORY
              ? "Categories"
              : "Workflows"
          } have been successfully uploaded!`}
        />
      ) : null}
    </>
  );
};
