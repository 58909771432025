import dayjs from "dayjs";
import { map } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { TaskStatus, WorkflowStatus } from "../../../constants/enum";
import {
  SimulateReportTemplateDto,
  SimulateWorkflow,
} from "../../../lib/data-transfer-object/simulate.report.template.dto";

// Pass the value to this report

interface GenerateReportProps {
  data: SimulateReportTemplateDto;
}

interface TaskTableProps {
  id: number;
  simulateWorkflows: SimulateWorkflow[];
  onRowClick(activeTemplateTask: any): void;
}

interface TemplateTaskProps {
  //activeTemplateTask: any;
  data: SimulateWorkflow[];
  //onClick(activeTemplateTask: any): void;
}

const ReportTemplateInfo = () => {
  const createReportTemplate = useSelector(
    (state: RootState) => state.createReportTemplate
  );

  return (
    <div className="row">
      <div className="col-12 col-sm-2">
        <p className="m-0">
          <b className="text-primary mr-2">ID No:</b>
          1001
        </p>
      </div>
      <div className="col-12 col-sm-2">
        <p className="m-0">
          <b className="text-primary mr-2">Name:</b>
          Jay Strouse
        </p>
      </div>
      <div className="col-12 col-sm-3">
        <p className="m-0">
          <b className="text-primary mr-2">Report:</b>
          {createReportTemplate.reportTemplateName}
        </p>
      </div>
      <div className="col-12 col-sm-3">
        <p className="m-0">
          <b className="text-primary mr-2">Range:</b>
          Feb 01, 2022 - Feb 28, 2022
        </p>
      </div>
      <div className="col-12 col-sm-2">
        <p className="m-0">
          <b className="text-primary mr-2">Date:</b>
          Mar 04, 2022
        </p>
      </div>
    </div>
  );
};

const ReportTemplateProgress = () => {
  return (
    <div className="col-12">
      <small>Report Progress:</small>
      <div className="progress" style={{ height: "20px" }}>
        <div
          className="progress-bar bg-primary"
          role="progressbar"
          style={{ width: "50%" }}
        >
          50%
        </div>
      </div>
    </div>
  );
};

const TaskTable = (props: TaskTableProps) => {
  const { id, simulateWorkflows, onRowClick } = props;

  const getStatusStyle = (status: WorkflowStatus): string => {
    switch (status) {
      case WorkflowStatus.ACTIVE: {
        return "#fc8a34";
      }
      case WorkflowStatus.COMPLETED: {
        return "#529f50";
      }
      case WorkflowStatus.REJECTED: {
        return "#ba3742";
      }
      case WorkflowStatus.SKIPED: {
        return "#959ca6";
      }
    }
    return "#000";
  };

  const getStatusText = (status: WorkflowStatus): string => {
    switch (status) {
      case WorkflowStatus.ACTIVE: {
        return "Active";
      }
      case WorkflowStatus.COMPLETED: {
        return "Completed";
      }
      case WorkflowStatus.REJECTED: {
        return "Rejected";
      }
      case WorkflowStatus.SKIPED: {
        return "N/A";
      }
    }
    return "Pending";
  };

  const handleOnRowClick = (activeTemplateTask: any) =>
    onRowClick(activeTemplateTask);
  return (
    <div className="table-responsive">
      <span className="badge badge-primary">
        <i className="fa-solid fa-list-check mr-2"></i> {`Task ${id}`}
      </span>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Task Name</th>
            <th>Assign To</th>
            <th>Approver</th>
            <th>Due Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {map(simulateWorkflows, (workflow, index) => {
            const {
              workflowName,
              workflowAssignTo,
              workflowApprover,
              workflowDueDate,
              status,
            } = workflow;
            return (
              <>
                <tr className="font-weight-bold" key={index}>
                  <td>{workflowName}</td>
                  <td>{workflowAssignTo}</td>
                  <td>
                    {/*<img
                      src="images/faces/face1.jpg"
                      alt="image"
                      className="mr-3"
            />*/}
                    {workflowApprover}
                  </td>
                  <td>{dayjs(workflowDueDate).format("MM/DD/YYYY")}</td>
                  <td className="my-auto">
                    <svg height="13" width="10">
                      <circle
                        cx="5"
                        cy="5"
                        r="5"
                        fill={getStatusStyle(status)}
                      />
                    </svg>
                    {` ${getStatusText(status)}`}
                  </td>
                </tr>
                {map(workflow.actionItems, (actionItem, index) => {
                  const {
                    actionItemName,
                    actionItemAssignTo,
                    actionItemApprover,
                    actionItemDueDate,
                    status,
                  } = actionItem;
                  return (
                    <tr>
                      <td className="pl-5">{actionItemName}</td>
                      <td>{actionItemAssignTo}</td>
                      <td>{actionItemApprover}</td>
                      <td>{dayjs(actionItemDueDate).format("MM/DD/YYYY")}</td>
                      <td>
                        <svg height="13" width="10">
                          <circle
                            cx="5"
                            cy="5"
                            r="5"
                            fill={getStatusStyle(status)}
                          />
                        </svg>
                        {` ${getStatusText(status)}`}
                      </td>
                    </tr>
                  );
                })}
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const TemplateTasks = (props: TemplateTaskProps) => {
  const { data } = props;

  const createReportTemplate = useSelector(
    (state: RootState) => state.createReportTemplate
  );

  const handleTemplateTaskClick = (activeTemplateTask: any) => console.log("");

  return (
    <div className="col-12">
      {/*map(data, (task, index) => {
        return (
          <TaskTable
            key={index}
            id={task.id}
            data={task.workflows}
            onRowClick={handleTemplateTaskClick}
          />
        );
      })*/}
    </div>
  );
};

export const GenerateReport = (props: GenerateReportProps) => {
  const { data } = props;
  const createReportTemplateDto = useSelector(
    (state: RootState) => state.createReportTemplate
  );

  //Temporary Solution

  const { sections } = createReportTemplateDto;
  //const categories = sections[0].references;
  //const subCategories = categories[0].children;

  /*const mappedData = map(subCategories, (subCategory, index) => {
    return {
      id: index + 1,
      name: subCategory.text,
      workflows: map(subCategory.children, (workflow, index) => {
        return {
          id: index + 1,
          name: workflow.text,
          status: TaskStatus.PENDING,
          actionItems: map(workflow.children, (actionItem, index) => {
            return {
              id: index + 1,
              name: actionItem.text,
              status: TaskStatus.PENDING,
            };
          }),
        };
      }),
    };
  });*/

  return (
    <div
      className="modal fade modal-fullscreen"
      id="modal-simulate-generate-report"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div className="row">
              <div className="col-11">
                <div className="card bg-light border mb-3">
                  <div className="card-body">
                    <ReportTemplateInfo />
                  </div>
                </div>
              </div>
              <div className="col-1 text-right">
                <span className="fa-stack fa-2x">
                  <i className="fa fa-circle fa-stack-2x text-primary"></i>
                  <i className="fa-solid fa-chart-bar fa-stack-1x text-white"></i>
                </span>
              </div>
            </div>
            <div className="row mb-5">
              <ReportTemplateProgress />
            </div>
            <div className="row">{/* <TemplateTasks data={data} /> */}</div>
          </div>
          <div className="modal-footer py-2">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
