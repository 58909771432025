import { useParams } from "react-router-dom";
import {
  AssignmentInformation,
  GeneralInformation,
} from "../../components/shared";
import {
  AssignWorkflowDto,
  AssignWorkflowRequestDto,
} from "../../lib/data-transfer-object/assignWorkflow.dto";
import { useEffect, useState } from "react";
import { ActionItems } from "../../components/shared/ActionItems";
import { get, isEmpty } from "lodash";
import { ReferenceMaterials } from "../../components/shared/ReferenceMaterials";
import { AddCommentNotes } from "../../components/shared/AddCommentNotes";
import dayjs from "dayjs";
import {
  GetWorkflowResponseDto,
  WorkflowConfigDto,
} from "../../lib/data-transfer-object/workflow.dto";
import { useFetch } from "../../lib/hooks/useFetch";
import { ASSIGN_WORKFLOW_API, GET_WORKFLOW_API } from "../../constants/tms";
import { ActionItemDto } from "../../lib/data-transfer-object/actionItem.dto";
import { useDispatch, useSelector } from "react-redux";
import {
  changeActionItems,
  changeWorkflowId,
  resetAssignWorkflow,
} from "../../features/assignWorkflowSlice";
import { RootState } from "../../app/store";
import { usePost } from "../../lib/hooks/usePost";
import { AssignedWorkflowStatus } from "../../constants/enum";
import { UPLOAD_REFERENCE_MATERIALS } from "../../constants/api-url";
import axios from "../../lib/axios/axios";
import { useAuthUser } from "react-auth-kit";

export const AssignWorkflowForm = () => {
  const auth = useAuthUser();
  const dispatch = useDispatch();
  const { workflowId } = useParams();
  const [actionItems, setActionItems] = useState<ActionItemDto[]>([]);
  const [workflowConfig, setWorkflowConfig] = useState<WorkflowConfigDto>();
  const [referenceMaterials, setReferenceMaterials] = useState<File[]>([]);

  const { response, fetchErrors, loaded } = useFetch<GetWorkflowResponseDto>(
    `${GET_WORKFLOW_API}/${workflowId}`
  );

  const assignWorkflow = useSelector(
    (state: RootState) => state.assignWorkflow
  );

  const profille = useSelector((state: RootState) => state.profile);

  const assignWorkflowRequest = usePost<unknown>();

  const setValue = () => {
    const workflowId = get(response, "workflowId");
    const temp_ai: ActionItemDto[] = get(response, "actionItems"); // TODO: Change the variable name
    const workflowConfig = get(response, "workflow.workflowConfig");
    setActionItems(temp_ai);
    setWorkflowConfig({ ...workflowConfig });
    dispatch(changeWorkflowId(workflowId));
    dispatch(changeActionItems(temp_ai));
  };

  const onAddReferenceMaterial = (file: File) => {
    setReferenceMaterials([...referenceMaterials, file]);
  };

  const onCancel = () => {
    dispatch(resetAssignWorkflow());
  };

  const upload = async (assignedWorkflowId: number) => {
    console.log(referenceMaterials);
    let formData = new FormData();
    for (let ctr = 0; ctr < referenceMaterials.length; ctr++) {
      formData.append("files", referenceMaterials[ctr]);
    }
    const response = await axios.post(
      `${UPLOAD_REFERENCE_MATERIALS}?assignWorkflowId=${assignedWorkflowId}&userId=${
        auth()?.userId
      }`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log(response);
  };

  const onAssign = async () => {
    const {
      workflowId,
      assignTo,
      monitors,
      assignedBy,
      approver,
      dueDate,
      completionMode,
      assignmentType,
      compete,
      alert,
    } = assignWorkflow;
    const assingWorkflowRequestDto: AssignWorkflowRequestDto = {
      workflowId,
      assignedUsers: assignTo,
      viewers: monitors,
      assignedBy: auth()?.userId, //profille.email,
      approver: "4A2BD70D-B9E1-4FD4-973D-8A38C1FCE835",
      dueDate: dueDate ? dueDate : dayjs().format("YYYY-MM-DD"),
      competeDays: compete ? compete : 0,
      alertDays: alert ? alert : 0,
      completionMode,
      assignmentType,
    };

    const request =
      /*await assignWorkflowRequest(
      ASSIGN_WORKFLOW_API,
      assingWorkflowRequestDto
    );*/
      await axios
        .post(ASSIGN_WORKFLOW_API, JSON.stringify(assingWorkflowRequestDto), {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: false,
        })
        .catch((err) => {});
    if (get(request, "status") === 201) {
      const data = get(request, "data");
      const assingedWorkflowId = get(data, "assingedWorkflowId");
      console.log(assingedWorkflowId);
      upload(assingedWorkflowId);
      window.alert("Success");
    }
  };

  useEffect(() => {
    setValue();
    console.log(response);
  }, [workflowId, response, loaded]);

  return (
    <div className="content-wrapper">
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body pb-5">
              <button
                type="button"
                className="btn btn-primary btn-sm float-right"
                onClick={() => onAssign()}
              >
                <i className="fa-solid fa-circle-check mr-2"></i> Assign
              </button>
              <button
                type="button"
                className="btn btn-secondary btn-sm float-right mr-2"
                onClick={() => onCancel()}
              >
                <i className="fa-solid fa-rectangle-xmark pr-2"></i> Cancel
              </button>
              <p className="card-title">
                <i className="fa-solid fa-arrows-turn-to-dots mr-2"></i>
                Assign Workflow (Task)
              </p>
              <div className="my-3">
                <hr />
              </div>

              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <AssignmentInformation isReadOnly={false} />
                    <GeneralInformation isReadOnly={false} />
                    {/** TODO: To ask if this is needed */}
                    {/* <RelatedObjectives /> */}
                    {!isEmpty(actionItems) && (
                      <ActionItems
                        data={actionItems}
                        assignmentStatus={AssignedWorkflowStatus.NEW}
                      />
                    )}
                    <ReferenceMaterials
                      status={AssignedWorkflowStatus.NEW}
                      canUpload={
                        workflowConfig?.isFileUploadRequired as boolean
                      }
                      onAddReferenceMaterial={onAddReferenceMaterial}
                    />
                    <AddCommentNotes />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
