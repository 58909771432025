import { map } from "lodash";
import { Relationship } from "../../constants/enum";
import {
  RELATIONSHIP_OPTION_DAUGHTER,
  RELATIONSHIP_OPTION_FAMILY,
  RELATIONSHIP_OPTION_FATHER,
  RELATIONSHIP_OPTION_FRIEND,
  RELATIONSHIP_OPTION_MOTHER,
  RELATIONSHIP_OPTION_SON,
} from "../../constants/tms";

interface PeopleFormProps {
  name: string;
  email: string;
  relationship: number;
}

const PeopleForm = (props: PeopleFormProps) => {
  const relationshipOptions = [
    {
      value: Relationship.FAMILY,
      text: RELATIONSHIP_OPTION_FAMILY,
    },
    {
      value: Relationship.FATHER,
      text: RELATIONSHIP_OPTION_FATHER,
    },
    {
      value: Relationship.MOTHER,
      text: RELATIONSHIP_OPTION_MOTHER,
    },
    {
      value: Relationship.SON,
      text: RELATIONSHIP_OPTION_SON,
    },
    {
      value: Relationship.DAUGTHER,
      text: RELATIONSHIP_OPTION_DAUGHTER,
    },
    {
      value: Relationship.FRIEND,
      text: RELATIONSHIP_OPTION_FRIEND,
    },
  ];

  const fetchRelationshipOptions = () => {
    return map(relationshipOptions, (option, index) => {
      return <option value={option.value}>{option.text}</option>;
    });
  };
  return (
    <>
      <div className="form-group col-md-3 mb-3">
        <div className="picture-container mt-0">
          <div className="picture photo">
            <img
              src="images/faces/profile.jpg"
              className="picture-src"
              id="photoPreview1"
              title=""
            />
            <input type="file" id="photo1" className="" />
          </div>
          <h6 className="mt-2">Change Photo</h6>
        </div>
      </div>
      <div className="form-group col-md-8 mb-3">
        <div className="row">
          <div className="form-group col-md-12 mb-3">
            <label htmlFor="relationship" className="m-0">
              Relationship
            </label>
            <select
              id="sex"
              className="form-control"
              value={props.relationship}
              required
            >
              {fetchRelationshipOptions()}
            </select>
          </div>
          <div className="form-group col-md-6 mb-3">
            <label htmlFor="name" className="m-0">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Name"
              value={props.name}
            />
          </div>
          <div className="form-group col-md-6 mb-3">
            <label htmlFor="name" className="m-0">
              Email Address
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Email Address"
              value={props.email}
            />
          </div>
        </div>
      </div>
      <div className="form-group col-md-1 mb-3 text-right pr-4">
        <a
          href="#"
          className="text-dark"
          data-toggle="tooltip"
          data-placement="left"
          title=""
          data-original-title="Remove"
        >
          <i className="fa-solid fa-xmark"></i>
        </a>
      </div>
      <div className="col-12">
        <hr />
      </div>
    </>
  );
};

export const People = () => {
  const mockPeoples = [
    {
      relationship: 0,
      name: "Harry Potter",
      email: "harry@yopmail.com",
    },
    {
      relationship: 1,
      name: "Samuel Jackson",
      email: "sam@yopmail.com",
    },
    {
      relationship: 2,
      name: "Marry Poppins",
      email: "marrry@yopmail.com",
    },
  ];

  const fetchPeople = () => {
    return map(mockPeoples, (people, index) => {
      return <PeopleForm {...people} />;
    });
  };

  return (
    <>
      <p className="card-title">
        <i className="ti-user"></i>
        &nbsp;People
        <button
          type="button"
          className="btn btn-dark btn-sm btn-icon-text float-right"
        >
          <i className="fa-sharp fa-solid fa-plus mr-1"></i>
          Add New
        </button>
        <button
          type="button"
          className="btn btn-primary btn-sm btn-icon-text float-right mr-2"
        >
          <i className="fa-sharp fa-solid fa-save mr-1"></i>
          Save Changes
        </button>
      </p>
      <div className="my-3">
        <hr />
      </div>
      <form className="form-row">{fetchPeople()}</form>
    </>
  );
};
