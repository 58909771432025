import React, { useEffect } from "react";
import CSS from "csstype";
import { Link } from "react-router-dom";
import { get, map } from "lodash";
import { useFetch } from "../../lib/hooks/useFetch";
import { QuickLinkDto } from "../../lib/data-transfer-object/quickLink.dto";
import { DASHBOARD_GET_QUICKLINKS } from "../../constants/tms";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { type } from "os";
import { count } from "console";
import { useAuthUser } from "react-auth-kit";

const styles: CSS.Properties = {
  height: "60px",
};

interface QuickLinkProps {
  type: string;
  count: number;
  icon: string;
  href: string;
}

const quickLinkData = [
  {
    type: "TASK",
    count: 0,
    icon: "fa-tasks",
    href: "/",
  },
  {
    type: "Report Templates",
    icon: "fa-file-lines",
    count: 0,
    href: "/",
  },
  {
    type: "Tickets",
    count: 0,
    icon: "fa-ticket",
    href: "/",
  },
  {
    type: "Approval",
    count: 0,
    icon: "fa-thumbs-up",
    href: "/",
  },
  {
    type: "Comments",
    count: 0,
    icon: "fa-comments",
    href: "/",
  },
  {
    type: "Market Place",
    count: 0,
    icon: "fa-shopping-bag",
    href: "/",
  },
];

const QuickLink = (props: QuickLinkProps) => {
  const { type, icon, href, count } = props;
  return (
    <div className="col-lg-2 col-md-2 col-sm-4 col-6 stretch-card mb-4">
      <div className="card bg-white shadow">
        <div className="card-body p-3">
          <div className="row no-gutters align-items-center">
            <div className="col-12">
              <div style={styles}>
                <h3 className="m-0">
                  {count} <i className={`fa-solid ${icon} float-right`}></i>
                </h3>
                <small className="text-uppercase smallest">{type}</small>
              </div>
              <Link to={href} className="small text-decoration-none">
                more details
                <i className="fa-solid fa-circle-chevron-right btn-icon-append ml-3"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const QuickLinks = () => {
  const profille = useSelector((state: RootState) => state.profile);
  const auth = useAuthUser();

  const { response, fetchErrors, loaded } = useFetch<QuickLinkDto>(
    `${DASHBOARD_GET_QUICKLINKS}?email=${auth()?.email}`
  );

  const populateQuickLinks = () => {
    return map(quickLinkData, (d, index) => {
      const type = String(get(response, "type"));
      const count = Number(get(response, "count"));
      const data = {
        ...d,
        count: type === d.type ? count : 0,
      };
      return <QuickLink {...data} />;
    });
  };

  useEffect(() => {
    console.log(response);
  }, [response, loaded]);

  return (
    <div className="col-lg-12 col-md-12 col-12 mt-4 mb-4">
      <h4 className="card-title">
        <i className="fa-solid fa-arrow-up-right-from-square"></i> Quick Links
      </h4>
      <div className="my-3">
        <hr />
      </div>
      <div className="row mb-4">{loaded && populateQuickLinks()}</div>
    </div>
  );
};
