import { map } from "lodash";
import { useState, useEffect } from "react";
import Dropzone, { useDropzone } from "react-dropzone";

interface ApprovalFileUploadProps {
  onUploadFile(fileName: string): void;
}
// This will be move to a different folder

export const ApprovalFileUpload = (props: ApprovalFileUploadProps) => {
  const { onUploadFile } = props;
  const [files, setFiles] = useState<File[]>([]);

  const { getRootProps, acceptedFiles } = useDropzone({
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) => {
        setFiles([...files, file]);
        //dispatch(setUploadedFile(file));
        onUploadFile(file.name);
      });
    },
  });

  /*const acceptedFileItems = acceptedFiles.map(file => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));*/

  useEffect(() => {
    console.log(files);
  }, [files]);

  const showUploadedFiles = () => {
    return (
      <>
        {map(files, (file, index) => {
          return (
            <div key={index}>
              <span>
                <i className="fa-solid fa-file-pdf mr-2"></i> {file.name}
              </span>
              <button
                type="button"
                className="btn btn-primary btn-icon-text btn-sm float-right"
              >
                <i className="fa-solid fa-file-export"></i> Preview
              </button>
              <br />
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      <p className="card-title m-0 mb-2">Approval File Upload</p>
      <div
        className="accordion collapase-card"
        id="approvalFileUploadAccordion"
      >
        <div className="card bg-light border">
          <div className="card-header" id="approvalFileUpload">
            <a
              href="#"
              className="btn btn-header-link text-dark"
              data-toggle="collapse"
              data-target="#approvalFileUploadSection"
              aria-expanded="true"
              aria-controls="approvalFileUploadSection"
            >
              Document Name
            </a>
          </div>
          <div
            id="approvalFileUploadSection"
            className="collapse"
            aria-labelledby="approvalFileUpload"
            data-parent="#approvalFileUploadAccordion"
          >
            <div className="card-body pt-4">
              <section>
                <div {...getRootProps({ className: "dropzone" })}>
                  <form className="needsclick">
                    <div className="dz-message needsclick">
                      <i className="fa-solid fa-cloud-arrow-up fa-5x d-block mt-5"></i>
                      {"Drop files here or click to upload."}
                      <br />
                    </div>
                  </form>
                </div>
              </section>
              <hr className="mx-5"></hr>
              {showUploadedFiles()}
            </div>
          </div>
        </div>
      </div>
      <div className="pb-4 mb-4 border-bottom"></div>
    </>
  );
};
