export enum ReportStatus {
  SAVED = 1,
  PUBLISHED = 2,
  SENT_FOR_APPROVAL = 3,
}

export enum AssignReportStatus {
  ARCHIVE = 1,
  ACTIVE = 2,
  INACTIVE = 3,
  PENDING = 4,
}

export enum LightIndicatorStatus {
  PENDING = 1,
  ACTIVE = 2,
  COMPLETED = 3,
}

export enum ImporterTabs {
  TASK_CATEGORY,
  TEMPLATE_TASK,
}

export enum TaskStatus {
  PENDING = 1,
  ACTIVE = 2,
  COMPLETED = 3,
  REJECTED = 4,
}

export enum AssingmentType {
  WORKFLOW = "Workflow",
  ACTION_ITEM = "Action Item",
  CHECKLIST = "Checklist",
}

export enum ToggleSwitchValue {
  YES = "Yes",
  NO = "No",
}

export enum ReferenceType {
  CATEGORY = "CATEGORY",
  SUB_CATEGORY = "SUB_CATEGORY",
  WORKFLOW = "WORKFLOW",
  ACTION_ITEM = "ACTION_ITEM"
}

export enum WorkflowStatus {
  PENDING = 1,
  ACTIVE = 2,
  COMPLETED = 3,
  REJECTED = 4,
  SKIPED = 5
}

export enum ACTIONITEMSTATUS {
  PENDING = 1,
  ACTIVE = 2,
  COMPLETED = 3,
  REJECTED = 4,
}

export enum AssignmentType {
  INDIVIDUAL = 1,
  SHARED = 2
}

export enum CompletionMode {
  EVERY_USER = 1,
  AT_LEAST_ONE = 2
}

export enum AssignedWorkflowStatus {
  NEW = 0,
  ACTIVE = 1,
  PENDING = 2,
  PARTIALCOMPLETE = 3,
  COMPLETED = 4,
  CANCEL = 5,
  APPROVED = 6,
  REJECTED = 7,
}

export enum AssignedWorkflowActionItemStatus {
  ACTIVE = 0,
  COMPLETED = 1,
  MARKED_NOT_APPLICABLE = 2
}

export enum ActionItemActionType {
  TO_COMPLETE = 0,
  TO_MARK_NOT_APPLICABLE = 1
}

export enum ProfileTab {
  PERSONAL_INFORMATION,
  FAVORITES,
  PEOPLE,
  LOGIN_SECURITY,
  BILLING
}

export enum Relationship {
  FAMILY,
  FATHER,
  MOTHER,
  SON,
  DAUGTHER,
  FRIEND
}

export enum FileType {
  PDF = 'PDF',
  SPREEDSHEET = 'SPREEDSHEET',
  WORD = 'WORD',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  POWERPOINT = 'POWERPOINT',
  IMG = 'IMG'
}

