import React, { Component, useState } from "react";
import { useDispatch } from "react-redux";
import { ImporterTabs } from "../../constants/enum";
import { changeActiveTab } from "../../features/importerConfigSlice";

export const ImportOptions = () => {
  const [activeTabClass, setActiveTabClass] = useState<ImporterTabs>(
    ImporterTabs.TASK_CATEGORY
  );

  const dispatch = useDispatch();

  const onTabClicked = (tab: ImporterTabs) => {
    setActiveTabClass(tab);
    dispatch(changeActiveTab(tab));
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-md-12 col-12">
            <p className="card-title">
              <i className="fa-solid fa-sliders"></i>
              Import Options
            </p>
            <div className="my-3">
              <hr />
            </div>
          </div>
          <div className="col-md-12 col-12">
            <div className="list-group">
              <a
                href="#"
                className={`list-group-item list-group-item-action ${
                  activeTabClass === ImporterTabs.TASK_CATEGORY ? "active" : ""
                }`}
                onClick={() => onTabClicked(ImporterTabs.TASK_CATEGORY)}
              >
                <div className="row">
                  <div className="col-1">
                    <i className="fa-solid fa-list-check fa-fw"></i>
                  </div>
                  <div className="col-10">
                    Task Categories &amp; Sub Categories
                  </div>
                </div>
              </a>
              <a
                href="#"
                className={`list-group-item list-group-item-action ${
                  activeTabClass === ImporterTabs.TEMPLATE_TASK ? "active" : ""
                }`}
                onClick={() => onTabClicked(ImporterTabs.TEMPLATE_TASK)}
              >
                <div className="row">
                  <div className="col-1">
                    <i className="fa-solid fa-clipboard-list fa-fw mr-2"></i>
                  </div>
                  <div className="col-10">Template Task Data</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
