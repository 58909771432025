import React, { Component } from 'react'

export const CommingSoon = () => {
    return (
        <div className="content-wrapper pb-0 mb-0 d-flex align-items-end">
          <div className="row">
            <div className="col-md-12 grid-margin pb-0 mb-0">
              <div className="row">
                <div className="col-12 col-xl-12 mb-4 mb-xl-0 d-flex justify-content-center">
                  <div className="w-75 text-center">
                    <h1 className="mb-3 text-primary">We are coming Soon</h1>
                    <p className="mb-5 lead muted">This feature is under construction! We are preparing something amazing and exciting for you.</p>
                    <button type="button" className="btn btn-outline-primary btn-lg btn-icon-text my-5">
                      <i className="fa-solid fa-bell mr-2"></i>
                      Notify Me!
                    </button>
                    <br /><br /><br /><br /><br />
                    <img src="images/comming-soon.png" className="w-50" alt="" />
                  </div>
                </div>                                
              </div>              
            </div>
          </div>
        </div>
    )
}