import CSS from "csstype";
import { chain, cloneDeep, forEach, groupBy, map, mapValues } from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  changeSectionDescription,
  changeSectionName,
  resetCreateReportTemplateSection,
} from "../../../features/createReportTemplateSectionSlice";
import { addReportTemplateSection } from "../../../features/createReportTemplateSlice";
import { showAddSectionModal } from "../../../features/modalStatesSlice";
import {
  ActionItem,
  Category,
  SubCategory,
  Workflow,
} from "../../../lib/data-transfer-object/reference.dto";
import { ReportTemplateSectionDto } from "../../../lib/data-transfer-object/report.template.dto";
import { SectionWorkflowsTree } from "../SectionWorflowsTree";
import { AvailableWorkflows } from "./AvailableWorkflows";

const styles: CSS.Properties = {
  height: "500px",
  scrollbarWidth: "thin",
  background: "#f5f7ff",
};

export const AddSection = () => {
  const initialValues: ReportTemplateSectionDto = {
    name: "",
    description: "",
    workflows: [],
  };

  /*const [reportTemplateSection, setReportTemplateSection] =
    useState<ReportTemplateSectionDto>(initialValues);*/

  const reportTemplateSection = useSelector(
    (state: RootState) => state.createReportTemplateSection
  );

  const dispatch = useDispatch();

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) =>
    /*setReportTemplateSection({
      ...reportTemplateSection,
      name: event.target.value,
    });*/
    dispatch(changeSectionName(event.target.value));

  const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) =>
    /*setReportTemplateSection({
      ...reportTemplateSection,
      description: event.target.value,
    });*/
    dispatch(changeSectionDescription(event.target.value));

  const onSave = () => {
    dispatch(addReportTemplateSection(reportTemplateSection));
    dispatch(resetCreateReportTemplateSection());
  };

  const onCloseCancel = () => {
    dispatch(showAddSectionModal(false));
    dispatch(resetCreateReportTemplateSection());
  };

  useEffect(() => {
    console.log(reportTemplateSection);
  }, [reportTemplateSection]);

  return (
    <div
      className="modal fade"
      id="modal-add-section"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header p-3 pr-4">
            <h5 className="modal-title">
              <i className="mdi  mdi mdi-format-indent-increase"></i>
              {"Add Section"}
            </h5>
            <button
              type="button"
              className="close"
              aria-label="Close"
              data-dismiss="modal"
              onClick={onCloseCancel}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body p-4 overflow-auto" style={styles}>
            <div className="row h-100">
              <div className="col-8">
                <div className="card h-100 mb-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="card mb-3">
                          <div className="card-header bg-light shadow-sm rounded-top">
                            <div className="position-relative">
                              <input
                                type="text"
                                className="creators-hub-input-text bg-light h4 font-weight-bold"
                                id="name"
                                placeholder="&#xf304; Section Header"
                                value={reportTemplateSection.name}
                                onChange={(e) => onNameChange(e)}
                              />
                              <span className="creators-hub-input-text-border"></span>
                            </div>
                            <div className="position-relative">
                              <input
                                type="text"
                                className="creators-hub-input-text bg-light text-muted"
                                id="description"
                                placeholder="&#xf304; Section Header Description"
                                value={reportTemplateSection.description}
                                onChange={(e) => onDescriptionChange(e)}
                              />
                              <span className="creators-hub-input-text-border"></span>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="card-body px-3">
                              {map(
                                reportTemplateSection.workflows,
                                (workflow, index) => {
                                  return (
                                    <SectionWorkflowsTree
                                      key={index}
                                      data={workflow}
                                      isEditable={true}
                                    />
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">{<AvailableWorkflows />}</div>
            </div>
          </div>
          <div className="modal-footer py-2">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              data-dismiss="modal"
              onClick={onSave}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              data-dismiss="modal"
              onClick={onCloseCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
