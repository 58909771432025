import axios from "axios";
import { map } from "lodash";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { General } from "./General";
import { Organization } from "./Organization";
import { ReportProperties } from "./ReportProperties";
import { TemplateOptions } from "./TemplateOptions";

type PalletContainerEvents = {
  //onAddSection(): void;
  //onAddHeader(): void;
  onToogleOpen(): void;
  onSimulateReportTemplate(): void;
};
interface PalletContainerProps {
  events?: PalletContainerEvents;
}

export const PalletContainer = ({ events }: PalletContainerProps) => {
  // global variable

  const TITLE = "PALLET CONTAINER"; // To move in TMS

  const createReportTemplate = useSelector(
    (state: RootState) => state.createReportTemplate
  );

  // state
  const [toogleContainer, setToogleContainer] = useState<boolean>(false);

  // functions
  const handleToogleContainer = () => {
    setToogleContainer(!toogleContainer);
    events?.onToogleOpen();
  };

  const contructSection = () => {
    // TODO move and improve this
    return map(createReportTemplate.sections, (section) => {
      return {
        name: section.name,
        description: section.description,
        order: section.order,
      };
    });
  };

  const handleOnSaveReportTemplate = async () => {
    /*const data = JSON.stringify({
      documentName: createReportTemplate.reportName, // Missing Header and Footer
      reportTemplateSections: contructSection(),
    });

    const result = await api.post("/", data, {
      headers: { "Content-Type": "application/json" },
    });
    console.log(result);*/
  };
  const handleOnSimulateReportTemplate = () =>
    events?.onSimulateReportTemplate();

  useEffect(() => {
    //console.log(showSectionModal);
  });

  return (
    <>
      <div className="theme-setting-wrapper">
        <div id="settings-trigger" onClick={handleToogleContainer}>
          <i className="fa-solid fa-chevron-left"></i>
        </div>
        <div
          id="theme-settings"
          className={`settings-panel ${toogleContainer ? "open" : ""}`}
        >
          <i
            className="settings-close ti-close d-block"
            onClick={handleToogleContainer}
          ></i>
          <p className="settings-heading">{TITLE}</p>

          <div id="accordion" className="myaccordion w-100">
            <ReportProperties />
            <TemplateOptions
              onSaveClick={handleOnSaveReportTemplate}
              onSimulateClick={handleOnSimulateReportTemplate}
            />
            <General />
            <Organization />
          </div>
        </div>
      </div>
    </>
  );
};
