const API_URL =  process.env.REACT_APP_MY_WORKPRODUCT_API;

// Authentication
export const LOGIN_URL: string = `${API_URL}Auth/Authenticate`
export const REFRESH_TOKEN_URL: string = `${API_URL}Auth/RefreshToken`

export const GET_ASSIGNED_WORKFLOW: string = `${API_URL}Workflow/GetAssignedWorkflow`;
export const COMPLETE_ASSIGNED_WORKFLOW: string = `${API_URL}Workflow/CompleteWorkflow`;

// UserProfile
export const GET_USER_PROFILE_URL: string = `${API_URL}UserProfile/GetUserProfile`;
export const UPDATE_USER_PROFILE_URL: string = `${API_URL}UserProfile/UpdateUserProfile`

// Dashboard
export const GET_CHART_DATA_URL: string = `${API_URL}Dashboard/GetChartData`
export const GET_POST_ASSIGNMENTS_URL: string = `${API_URL}Dashboard/GetPostAssignments`

//File
export const UPLOAD_REFERENCE_MATERIALS: string = `${API_URL}Importer/UploadReferenceMaterials`
export const GET_ALL_REFERENCE_MATERIALS: string = `${API_URL}UserFile/GetAllUserFiles`

//Workflow
export const CREATE_WORKFLOW_URL: string = `${API_URL}Workflow/createWorkflow`;
