import { EventInput } from "@fullcalendar/react";
import dayjs from "dayjs";

let eventGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today
let monday = dayjs("2023-03-06").toISOString().replace(/T.*$/, "");
let tuesday = dayjs("2023-03-07").toISOString().replace(/T.*$/, "");
let wednesday = dayjs("2023-03-08").toISOString().replace(/T.*$/, "");
let thursday = dayjs("2023-03-09").toISOString().replace(/T.*$/, "");
let friday = dayjs("2023-03-10").toISOString().replace(/T.*$/, "");
export const createEventId = () => String(eventGuid++);
export const INITIAL_EVENTS: EventInput[] = [
  {
    id: createEventId(),
    title: "Take Medication",
    start: monday,
  },
  {
    id: createEventId(),
    title: "Breast Excercise",
    start: monday,
  },
  {
    id: createEventId(),
    title: "Walk Dog",
    start: monday,
  },
  {
    id: createEventId(),
    title: "Take Medication",
    start: tuesday,
  },
  {
    id: createEventId(),
    title: "Breast Excercise",
    start: tuesday,
  },
  {
    id: createEventId(),
    title: "Walk Dog",
    start: tuesday,
  },
  {
    id: createEventId(),
    title: "Laundry",
    start: wednesday,
  },
  {
    id: createEventId(),
    title: "Take Medication",
    start: wednesday,
  },
  {
    id: createEventId(),
    title: "Breast Excercise",
    start: wednesday,
  },
  {
    id: createEventId(),
    title: "Walk Dog",
    start: wednesday,
  },
  {
    id: createEventId(),
    title: "Son's Math Test",
    start: thursday,
  },
  {
    id: createEventId(),
    title: "Take Medication",
    start: thursday,
  },
  {
    id: createEventId(),
    title: "Breast Excercise",
    start: thursday,
  },
  {
    id: createEventId(),
    title: "Walk Dog",
    start: thursday,
  },
  {
    id: createEventId(),
    title: "Doctor Appointment",
    start: friday,
  },
  {
    id: createEventId(),
    title: "Take Medication",
    start: friday,
  },
  {
    id: createEventId(),
    title: "Breast Excercise",
    start: friday,
  },
  {
    id: createEventId(),
    title: "Walk Dog",
    start: friday,
  },
];
