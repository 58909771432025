import React from "react";
import { ReferenceTree } from "./ReferenceTree";
import { ReferenceLegends } from "./ReferenceLegends";
import {
  ActionItem,
  Category,
  SubCategory,
  Workflow,
} from "../../../lib/data-transfer-object/reference.dto";
//import { ReferenceTree } from "./ReferenceTree";

export const AvailableWorkflows = () => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row mb-4">
          <div className="col-md-12 col-12">
            <p className="card-title">
              <i className="fa-solid fa-list-check"></i>
              Workflows
            </p>
            <div className="my-3">
              <hr />
            </div>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search for..."
                aria-label="Search for..."
              />
              <div className="input-group-append">
                <button className="btn btn-sm btn-primary" type="button">
                  <i className="icon-search"></i>
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-12 mb-3">
            <div
              id="accordion-reference-legends"
              className="myaccordion w-100 rounded"
            >
              <div className="card">
                <ReferenceLegends />
              </div>
            </div>
          </div>

          <div className="col-md-12 col-12">
            <div className="my-3">
              <hr />
            </div>
            <p className="font-weight-bold">Reference</p>
            <div className="col-12 p-0">{<ReferenceTree />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
