import React, { useEffect, useState } from "react";
import CSS from "csstype";

import { Canvass } from "../components/creators-hub/Canvass";
import { PalletContainer } from "../components/creators-hub/pallet-container/PalletContainer";
import { SectionModal } from "../components/creators-hub/SectionModal";
import {
  CreateReportTemplateDto,
  ReportTemplateSectionDto,
} from "../lib/data-transfer-object/report.template.dto";
import { isEqual, map } from "lodash";
import { HeaderModal } from "../components/creators-hub/HeaderModal";
import axios from "axios";
import { Simulate } from "../components/creators-hub/modals/Simulate";
import { TemplateTaskInformation } from "../components/creators-hub/modals/TemplateTaskInformation";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { AddSection } from "../components/creators-hub/add-section/AddSection";

const styles: CSS.Properties = {
  width: "80.5%",
};

const styles2: CSS.Properties = {
  // Rename this
  width: "100%",
};

const api = axios.create({
  baseURL: `https://dev-us01-backend-api.azurewebsites.net/api/ReportTempate/CreateReportTemplate`,
});

export const CreatorsHub = () => {
  // Global Variables

  // States
  const [showSectionModal, setShowSectionModal] = useState<boolean>(false);
  const [showHeaderModal, setShowHeadernModal] = useState<boolean>(false);
  const [openPalletContainer, setOpenPalletContainer] =
    useState<boolean>(false);
  const [isPreview, setIsPreview] = useState<boolean>(true);

  const [showSimulate, setShowSimulate] = useState<boolean>(false);
  const handleSimulateReportTemplate = () => {
    setShowSimulate(true);
  };

  const createReportTemplate = useSelector(
    (state: RootState) => state.createReportTemplate
  );

  const modalStates = useSelector((state: RootState) => state.modalStates);

  //Functions
  const handleShowPalletContainer = () =>
    setOpenPalletContainer(!openPalletContainer);
  const handleShowSectionModal = () => setShowSectionModal(!showSectionModal);
  const handleShowHeaderModal = () => setShowHeadernModal(!showSectionModal);

  const handleAddReportTemplateHeader = (name: string) => {
    setShowHeadernModal(false);
    setIsPreview(false);
  };

  const handleAddReportTemplateSection = (
    sectionDto: ReportTemplateSectionDto
  ) => {
    const sections: ReportTemplateSectionDto[] = [
      ...createReportTemplate.sections,
      sectionDto,
    ];

    //setCreateReportTemplate({ ...createReportTemplate, sections: sections });
    setShowSectionModal(false);
    setIsPreview(false);
  };

  const handleSaveReportTemplate = async () => {
    // TODO: Validation
    // TODO: Create a separate file for this services/handler
  };

  useEffect(() => {
    console.log(createReportTemplate);
  }, [createReportTemplate]);

  return (
    <>
      <div className="content-wrapper">
        <div className="row" style={!openPalletContainer ? styles2 : styles}>
          <div className="col-md-12 col-12 align-items-stretch">
            <div className="row">
              <div className="col-md-12 col-12 grid-margin stretch-card">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <Canvass isPreview={isPreview} />
                  </div>
                </div>
              </div>
              <PalletContainer
                events={{
                  onToogleOpen: handleShowPalletContainer,
                  onSimulateReportTemplate: handleSimulateReportTemplate,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {modalStates.headerModalState ? <HeaderModal isEdit={false} /> : null}
      {modalStates.sectionModalState ? <AddSection /> : null}
      {showSimulate ? <Simulate /> : null}
    </>
  );
};
