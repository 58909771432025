export const REPORT_STATUS_SAVED: string = "Saved";
export const REPORT_STATUS_PUBLISHED: string = "Published";
export const REPORT_STATUS_SENT_FOR_APPROVAL: string = "Sent for Approval";

export const REPORT_STATUS_ARCHIVE: string = "Archive";
export const REPORT_STATUS_ACTIVE: string = "Active";
export const REPORT_STATUS_INACTIVE: string = "Inactive";
export const REPORT_STATUS_PENDING: string = "Pending";
export const LIGHT_INDICATOR_STATUS_PENDING: string = "Pending";
export const LIGHT_INDICATOR_STATUS_ACTIVE: string = "Active";
export const LIGHT_INDICATOR_STATUS_COMPLETED: string = "Completed";

/** API URL **/

const API_URL =  process.env.REACT_APP_MY_WORKPRODUCT_API;

export const GETALL_REFERENCE_API: string = `${API_URL}Reference/GetAll`;

export const REPORT_TEMPLATE_API_GET_ALL: string = `${API_URL}ReportTemplate/GetAll`;

export const GETALL_CATEGORY_LOOKUP_API: string = `${API_URL}Category/GetAllCategoryLookup`;

export const CREATE_WORKFLOW_API: string = `${API_URL}Workflow/createWorkflow`;

export const CREATE_REPORT_TEMPLATE_API: string = `${API_URL}ReportTemplate/CreateReportTemplate`;

export const GETALL_SUBCATEGORY_LOOKUP_API: string = `${API_URL}SubCategory/GetAllSubCategoryLookup`;

export const DOWNLOAD_TEMPLATE_API: string = `${API_URL}importer/download/ImportCategoriesTemplate`;

export const GETALL_WORKFLOW_API: string = `${API_URL}Workflow/getAll`;
export const GET_WORKFLOW_API: string = `${API_URL}Workflow/get`;
export const ASSIGN_WORKFLOW_API: string = `${API_URL}Workflow/AssignWorkflow`;

/** Importer Template File Names */

export const CATEGORY_TEMPLATE_FILE_NAME = "Category Importer Template";
export const TASK_TEMPLATE_FILE_NAME = "Task Importer Template";

export const DASHBOARD_GET_QUICKLINKS = `${API_URL}Dashboard/GetAssignedWorflow`

export const DATE_PICKER_FORMAT = 'YYYY-MM-DD';
export const LONG_DATE_FORMAT = 'MMMM DD, YYYY'

export const ASSIGNED_WORKFLOW_ACTIONITEM_STATUS_ACTIVE = "Active";
export const ASSIGNED_WORKFLOW_ACTIONITEM_STATUS_COMPLETED = "Completed";
export const ASSIGNED_WORKFLOW_ACTIONITEM_STATUS_NOT_APPLICABLE = "Not Applicable";

export const PROFILE_TAB_PROFILE_INFORMATION = "Profile Information";
export const PROFILE_TAB_FAVORITES = "Favorites";
export const PROFILE_TAB_PEOPLE = "People";
export const PROFILE_TAB_LOGIN_SECURITY = "Login & Security";
export const PROFILE_TAB_BILLING = "Billing";

export const RELATIONSHIP_OPTION_FAMILY = "Family"
export const RELATIONSHIP_OPTION_FATHER = "Father"
export const RELATIONSHIP_OPTION_MOTHER = "Mother"
export const RELATIONSHIP_OPTION_SON = "Son"
export const RELATIONSHIP_OPTION_DAUGHTER = "Daugther"
export const RELATIONSHIP_OPTION_FRIEND = "Friend"

