import { GETALL_REFERENCE_API } from "../../../constants/tms";
import { useFetch } from "../../../lib/hooks/useFetch";
import {
  ActionItem,
  Category,
  GetAllReferencesReponseDto,
  References,
  SubCategory,
  Workflow,
} from "../../../lib/data-transfer-object/reference.dto";
import { useEffect, useState } from "react";
import { filter, forEach, get, isEmpty, isUndefined, map } from "lodash";
import {
  ParentsNode,
  ReferenceTreeNode,
  ReferenceTreeNodeProps,
} from "./ReferenceTreeNode";
import { ReferenceType } from "../../../constants/enum";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  addSectionWorkflow,
  updateSectionWorkflows,
} from "../../../features/createReportTemplateSectionSlice";
import { addReportTemplateSection } from "../../../features/createReportTemplateSlice";
import { SectionWorkflowDto } from "../../../lib/data-transfer-object/report.template.dto";

export const ReferenceTree = () => {
  const [referenceTreeNodes, setReferenceTreeNodes] =
    useState<ReferenceTreeNodeProps[]>();
  const { response, fetchErrors, loaded } =
    useFetch<GetAllReferencesReponseDto>(GETALL_REFERENCE_API);

  const reportTemplateSection = useSelector(
    (state: RootState) => state.createReportTemplateSection
  );

  const dispatch = useDispatch();

  const onReferenceTreeNodeClick = (
    node: Category | SubCategory | Workflow | ActionItem,
    referenceType: ReferenceType,
    parents?: ParentsNode
  ) => {
    if (referenceType === ReferenceType.WORKFLOW) {
      const category = get(parents, "category");
      const subCategory = get(parents, "subCategory");
      const workflow = node as Workflow;

      const sectionWorkflow: SectionWorkflowDto = {
        categoryId: Number(get(category, "categoryId")),
        categoryName: String(get(category, "categoryName")),
        subCategories: [
          {
            subCategoryId: Number(get(subCategory, "subCategoryId")),
            subCategoryName: String(get(subCategory, "subCategoryName")),
            workflows: [workflow],
          },
        ],
      };

      dispatch(addSectionWorkflow(sectionWorkflow));
    }

    if (referenceType === ReferenceType.ACTION_ITEM) {
    }
  };

  const populateReferences = () => {
    return map(referenceTreeNodes, (referenceTreeNode, index) => {
      return <ReferenceTreeNode key={index} {...referenceTreeNode} />;
    });
  };

  const convertActionItemsToTreeNodeProp = (
    category: Category,
    subCategory: SubCategory,
    workflow: Workflow,
    actionItems: ActionItem[]
  ): ReferenceTreeNodeProps[] => {
    return map(actionItems, (actionItem) => {
      return {
        id: actionItem.actionItemId,
        parents: {
          category,
          subCategory,
          workflow,
        },
        text: actionItem.actionItemName,
        referenceType: ReferenceType.ACTION_ITEM,
        data: actionItem,
        onTreeNodeChecked: onReferenceTreeNodeClick,
        checked: false,
      };
    });
  };

  const convertWorkflowsToTreeNodeProp = (
    category: Category,
    subCategory: SubCategory,
    workflows: Workflow[]
  ): ReferenceTreeNodeProps[] => {
    return map(workflows, (workflow) => {
      return {
        id: workflow.workflowId,
        parents: {
          category,
          subCategory,
        },
        text: workflow.workflowName,
        referenceType: ReferenceType.WORKFLOW,
        data: workflow,
        onTreeNodeChecked: onReferenceTreeNodeClick,
        children: convertActionItemsToTreeNodeProp(
          category,
          subCategory,
          workflow,
          workflow.actionItems
        ),
        checked: false,
      };
    });
  };

  const convertSubCategoriesToTreeNodeProp = (
    category: Category,
    subCategories: SubCategory[]
  ): ReferenceTreeNodeProps[] => {
    return map(subCategories, (subCategory) => {
      return {
        id: subCategory.subCategoryId,
        parents: {
          category,
        },
        text: subCategory.subCategoryName,
        referenceType: ReferenceType.SUB_CATEGORY,
        data: subCategory,
        onTreeNodeChecked: onReferenceTreeNodeClick,
        children: convertWorkflowsToTreeNodeProp(
          category,
          subCategory,
          subCategory.workflows
        ),
        checked: false,
      };
    });
  };

  const convertToReferenceTreeNodeProp = (
    references: References
  ): ReferenceTreeNodeProps[] => {
    return map(references?.categories, (category) => {
      return {
        id: category.categoryId,
        text: category.categoryName,
        referenceType: ReferenceType.CATEGORY,
        data: category,
        onTreeNodeChecked: onReferenceTreeNodeClick,
        children: convertSubCategoriesToTreeNodeProp(
          category,
          category.subCategories
        ),
        checked: false,
      };
    });
  };

  useEffect(() => {
    loaded &&
      response &&
      setReferenceTreeNodes(
        convertToReferenceTreeNodeProp(response?.references)
      );
  }, [loaded, response]);

  return (
    <div>
      <ul className="tree">{loaded ? populateReferences() : null}</ul>
    </div>
  );
};
