import { get, isEmpty, isUndefined, map } from "lodash";
import React, { useState, useEffect } from "react";
import { AddActionItem } from "../../components/create-workflow/AddActionItem";
import { WorkflowList } from "../../components/create-workflow/WorkflowList";
import {
  OptionsProps,
  Select,
} from "../../components/custom-web-controls/Select";
import { ToggleSwitch } from "../../components/custom-web-controls/ToggleSwitch";
import { SuccessModal } from "../../components/shared/SuccessModal";
import { ToggleSwitchValue } from "../../constants/enum";
import {
  CREATE_WORKFLOW_API,
  GETALL_CATEGORY_LOOKUP_API,
  GETALL_SUBCATEGORY_LOOKUP_API,
  GETALL_WORKFLOW_API,
} from "../../constants/tms";
import { CreateActionItemDto } from "../../lib/data-transfer-object/actionItem.dto";
import {
  CategoryLookupDto,
  SubCategoryLookupDto,
} from "../../lib/data-transfer-object/category.dto";
import {
  CreateWorkflowDto,
  CreateWorkflowRequestDto,
  GetAllWorkflowResponseDto,
  WorkflowDto,
} from "../../lib/data-transfer-object/workflow.dto";
import { useFetch } from "../../lib/hooks/useFetch";
import axios from "../../lib/axios/axios";
import { StatusCode } from "../../lib/common/error";

export const CreateWorkflow = () => {
  const initialValues: CreateWorkflowDto = {
    workflowName: "",
    subCategoryId: 0,
    isApproverRequired: false,
    isFileUploadRequired: false,
    isCommentRequired: false,
    actionItems: [],
  };

  const [createWorkflow, setCreateWorkflow] = useState(initialValues);
  const [workflows, setWorkflows] = useState<WorkflowDto[]>([]);
  const [categoriesLookup, setCategoriesLookup] = useState<OptionsProps[]>([]);
  const [subCategoriesLookup, setSubCategoriesLookup] = useState<
    OptionsProps[]
  >([]);
  const [showAddActionItem, setShowAddActionItem] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const getAllWorkflowsRequest =
    useFetch<GetAllWorkflowResponseDto>(GETALL_WORKFLOW_API);

  const getAllCategoryRequest = useFetch<CategoryLookupDto[]>(
    GETALL_CATEGORY_LOOKUP_API
  );

  const getAllSubCategoryRequest = useFetch<SubCategoryLookupDto[]>(
    GETALL_SUBCATEGORY_LOOKUP_API
  );

  const onCancelAddActionItem = () => {
    setShowAddActionItem(false);
  };

  /* This function is responsible for adding action item in state */
  const onAddActionItemClick = (actionItem: CreateActionItemDto) => {
    setCreateWorkflow({
      ...createWorkflow,
      actionItems: [...createWorkflow.actionItems, actionItem],
    });
  };

  const onToogleSwitchChange = (name: string, value: boolean) => {
    setCreateWorkflow({
      ...createWorkflow,
      [name]: value,
    });
  };

  const onSaveClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const {
      workflowName,
      subCategoryId,
      isApproverRequired,
      isCommentRequired,
      isFileUploadRequired,
      actionItems,
    } = createWorkflow;

    /** TODO: Add validation here */

    const createWorkflowRequestDto: CreateWorkflowRequestDto = {
      workflowName,
      subCategoryId,
      workflowConfig: {
        isApproverRequired,
        isCommentRequired,
        isFileUploadRequired,
      },
      actionItems: map(actionItems, (actionItem) => {
        return {
          actionItemName: actionItem.name,
          actionItemDescription: actionItem.description,
        };
      }),
    };
    const data = {
      ...createWorkflowRequestDto,
    };

    //createWorkflowRequest(CREATE_WORKFLOW_URL, createWorkflowRequestDto);
    var response = await axios
      .post(CREATE_WORKFLOW_API, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {});

    const status = get(response, "status");
    if (status === StatusCode.Created) {
      const data = get(response, "data");
      setWorkflows([
        ...workflows,
        {
          workflowId: data.workflowId,
          workflowName: data.workflowName,
          subCategoryName: data.subCategoryName,
          workflowConfig: data.workflowConfig,
          actionItems: data.actionItems,
        },
      ]);
      setShowSuccessModal(true); //TODO: Not working...
      setCreateWorkflow(initialValues);
    }
  };

  const mappedCategoryLookup = () => {
    return map(getAllCategoryRequest.response, (d) => {
      return {
        text: d.categoryName,
        value: d.categoryId,
      };
    });
  };

  const mappedSubCategoryLookup = () => {
    return map(getAllSubCategoryRequest.response, (d) => {
      return {
        text: d.subCategoryName,
        value: d.subCategoryId,
      };
    });
  };

  useEffect(() => {
    const { response, loaded } = getAllWorkflowsRequest;
    const workflows = get(response, "workflows");
    loaded && !isUndefined(workflows) && setWorkflows([...workflows]);
  }, [getAllCategoryRequest.response, getAllCategoryRequest.loaded]);

  useEffect(() => {
    getAllCategoryRequest.loaded && setCategoriesLookup(mappedCategoryLookup());
  }, [getAllCategoryRequest.response, getAllCategoryRequest.loaded]);

  useEffect(() => {
    getAllSubCategoryRequest.loaded &&
      setSubCategoriesLookup(mappedSubCategoryLookup());
  }, [getAllSubCategoryRequest.response, getAllSubCategoryRequest.loaded]);

  return (
    <>
      <div className="content-wrapper">
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <p className="card-title">
                  <i className="fa-solid fa-arrows-turn-to-dots mr-2"></i>
                  Create Workflow
                  <button
                    type="button"
                    className="btn btn-primary btn-sm btn-icon-text float-right"
                    data-toggle="modal"
                    data-target="#success-modal"
                    onClick={(e) => onSaveClick(e)}
                  >
                    <i className="fa-solid fa-save mr-2"></i>
                    Save Workflow
                  </button>
                </p>
                <div className="my-3">
                  <hr />
                </div>
                <form>
                  <div className="form-group mb-0 row">
                    <label
                      htmlFor="category"
                      className="col-sm-3 col-form-label text-right required"
                    >
                      Category
                    </label>
                    <div className="col-sm-6">
                      <Select
                        id="category"
                        name="category"
                        placeholder="Select Category"
                        required={true}
                        options={categoriesLookup}
                        onChange={
                          (e) =>
                            console.log(
                              e
                            ) /** TODO The value of sub categories will depend on this value */
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group mb-0 row">
                    <label
                      htmlFor="sub_category"
                      className="col-sm-3 col-form-label text-right required"
                    >
                      Sub Category
                    </label>
                    <div className="col-sm-6">
                      <Select
                        id="subCategory"
                        name="subCategroy"
                        placeholder="Select Sub Category"
                        options={subCategoriesLookup}
                        required={true}
                        onChange={(e) =>
                          setCreateWorkflow({
                            ...createWorkflow,
                            subCategoryId: Number(e),
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group mb-0 row">
                    <label
                      htmlFor="name"
                      className="col-sm-3 col-form-label text-right required"
                    >
                      Name
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        className="form-control"
                        id="workflowName"
                        value={createWorkflow.workflowName}
                        onChange={(e) =>
                          setCreateWorkflow({
                            ...createWorkflow,
                            workflowName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group mb-0 row">
                    <label
                      htmlFor="tags"
                      className="col-sm-3 col-form-label text-right required"
                    >
                      Approver Required
                    </label>
                    <div className="col-sm-6">
                      <div className="switchToggle">
                        <div className="switchToggle">
                          <ToggleSwitch
                            id="isapprover-required-switch"
                            name="isApproverRequired"
                            value_on={ToggleSwitchValue.YES}
                            value_off={ToggleSwitchValue.NO}
                            value={createWorkflow.isApproverRequired}
                            onChange={(e) =>
                              onToogleSwitchChange("isApproverRequired", e)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-0 row">
                    <label
                      htmlFor="tags"
                      className="col-sm-3 col-form-label text-right required"
                    >
                      File Upload Required
                    </label>
                    <div className="col-sm-6">
                      <div className="switchToggle">
                        <div className="switchToggle">
                          <ToggleSwitch
                            id="isfileupload-required-switch"
                            name="isFileUploadRequired"
                            value_on={ToggleSwitchValue.YES}
                            value_off={ToggleSwitchValue.NO}
                            value={createWorkflow.isFileUploadRequired}
                            onChange={(e) =>
                              onToogleSwitchChange("isFileUploadRequired", e)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-0 row">
                    <label
                      htmlFor="tags"
                      className="col-sm-3 col-form-label text-right required"
                    >
                      Comment Required
                    </label>
                    <div className="col-sm-6">
                      <div className="switchToggle">
                        <div className="switchToggle">
                          <ToggleSwitch
                            id="iscomment-required-switch"
                            name="isCommentRequired"
                            value_on={ToggleSwitchValue.YES}
                            value_off={ToggleSwitchValue.NO}
                            value={createWorkflow.isCommentRequired}
                            onChange={(e) =>
                              onToogleSwitchChange("isCommentRequired", e)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-0 row">
                    <label
                      htmlFor="tags"
                      className="col-sm-3 col-form-label text-right required"
                    >
                      Add Action Items
                    </label>
                    <div className="col-sm-6">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm text-left"
                        data-toggle="modal"
                        data-target="#modal-add-action-items"
                        onClick={() => setShowAddActionItem(true)}
                      >
                        <i className="fa fa-plus-circle mr-2"></i> Add Action
                        Items
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <WorkflowList workflows={workflows} />
        </div>
      </div>
      {showAddActionItem && (
        <AddActionItem
          onCancel={onCancelAddActionItem}
          onAddActionItem={onAddActionItemClick}
          actionItems={createWorkflow.actionItems}
        />
      )}
      {showSuccessModal && <SuccessModal message="Workflow has been saved." />}
    </>
  );
};
