import { useSelector } from "react-redux";
import {
  GET_USER_PROFILE_URL,
  UPDATE_USER_PROFILE_URL,
} from "../../constants/api-url";
import {
  GetUserProfileResponse,
  UserProfileDto,
} from "../../lib/data-transfer-object/userProfile.dto";
import { useFetch } from "../../lib/hooks/useFetch";
import { RootState } from "../../app/store";
import { ChangeEvent, useEffect, useState } from "react";
import { usePost } from "../../lib/hooks/usePost";
import dayjs from "dayjs";
import { DATE_PICKER_FORMAT } from "../../constants/tms";
import { useAuthUser } from "react-auth-kit";

const initialValue: UserProfileDto = {
  firstName: "",
  lastName: "",
  birthDate: "",
  gender: "",
  email: "",
  contactNo: "",
  address: "",
  facebook: "",
  instagram: "",
  tiktok: "",
  twitter: "",
  blog: "",
};

export const ProfileInformation = (props: { onUpdate(): void }) => {
  const profile = useSelector((state: RootState) => state.profile);
  const auth = useAuthUser();

  const [userProfile, setUserProfile] = useState<UserProfileDto>(initialValue);

  const { response, loaded, fetchErrors } = useFetch<GetUserProfileResponse>(
    `${GET_USER_PROFILE_URL}?email=${auth()?.email}`
  );

  const updateUserProfileRequest = usePost<unknown>();

  const update = () => {
    // TODO: Validation
    updateUserProfileRequest(UPDATE_USER_PROFILE_URL, userProfile);
    props.onUpdate();
  };

  const infoChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setUserProfile({
      ...userProfile,
      [event.target.id]: event.target.value,
    });
  };

  useEffect(() => {
    if (response) {
      const userProfile: UserProfileDto = {
        firstName: response.firstName,
        lastName: response.lastName,
        birthDate:
          response.birthDate !== null
            ? dayjs(response.birthDate).format(DATE_PICKER_FORMAT)
            : dayjs(dayjs().subtract(18, "year")).format(DATE_PICKER_FORMAT),
        gender: response.gender,
        email: response.email,
        contactNo: response.contactNo,
        address: response.address,
        facebook: response.facebookUrl,
        instagram: response.instagramUrl,
        tiktok: response.tiktokUrl,
        twitter: response.twitterUrl,
        blog: response.blog,
      };
      setUserProfile(userProfile);
    }
  }, [loaded, response]);

  return (
    <>
      <p className="card-title">
        <i className="ti-user"></i>
        &nbsp;Profile Information
        <button
          type="button"
          className="btn btn-dark btn-sm btn-icon-text float-right"
        >
          <i className="fa-sharp fa-solid fa-users-viewfinder mr-1"></i>
          View Profile
        </button>
        <button
          type="button"
          className="btn btn-primary btn-sm btn-icon-text float-right mr-2"
          data-toggle="modal"
          data-target="#success-modal"
          onClick={() => update()}
        >
          <i className="fa-sharp fa-solid fa-save mr-1"></i>
          Save Changes
        </button>
      </p>
      <div className="my-3">
        <hr />
      </div>
      <ul className="nav nav-tabs" id="setting-panel" role="tablist">
        <li className="nav-item">
          <a
            className="nav-link active"
            id="all-accounts-tab"
            data-toggle="tab"
            href="#all-accounts-section"
            role="tab"
            aria-controls="all-accounts-section"
            aria-expanded="true"
          >
            <i className="fa-solid fa-id-card mr-2"></i> Account
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="interests-tab"
            data-toggle="tab"
            href="#interests-section"
            role="tab"
            aria-controls="interests-section"
          >
            <i className="fa-solid fa-heart-circle-bolt mr-2"></i> Interest
            &amp; Happenings
          </a>
        </li>
      </ul>
      <div className="tab-content" id="setting-content">
        <div
          className="tab-pane fade show active scroll-wrapper"
          id="all-accounts-section"
          role="tabpanel"
          aria-labelledby="all-accounts-section"
        >
          <div className="wrapper wrapper-content animated fadeInRight">
            <form className="form-row">
              <div className="form-group col-md-6 mb-3">
                <label htmlFor="first_name" className="m-0">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="First Name"
                  value={userProfile.firstName}
                  onChange={(e) => infoChange(e)}
                  autoFocus
                />
              </div>
              <div className="form-group col-md-6 mb-3">
                <label htmlFor="last_name" className="m-0">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder="Last Name"
                  value={userProfile.lastName}
                  onChange={(e) => infoChange(e)}
                />
              </div>
              <div className="form-group col-md-6 mb-3">
                <label htmlFor="date_of_birth" className="m-0">
                  Date of Birth
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="birthDate"
                  placeholder="Date of Birth"
                  value={userProfile.birthDate}
                  onChange={(e) => infoChange(e)}
                />
              </div>
              <div className="form-group col-md-6 mb-3">
                <label htmlFor="sex" className="m-0">
                  Sex
                </label>
                <select
                  id="gender"
                  className="form-control"
                  value={userProfile.gender}
                  onChange={(e) => infoChange(e)}
                  required
                >
                  <option disabled selected>
                    Sex
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div className="form-group col-md-6 mb-3">
                <label htmlFor="email" className="m-0">
                  Email Address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Email Address"
                  disabled
                  value={userProfile.email}
                />
              </div>
              <div className="form-group col-md-6 mb-3">
                <label htmlFor="phone" className="m-0">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="contactNo"
                  placeholder="Phone Number"
                  value={userProfile.contactNo}
                  onChange={(e) => infoChange(e)}
                />
              </div>
              <div className="form-group col-md-12 mb-3">
                <label htmlFor="address" className="m-0">
                  Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  placeholder="Address"
                  value={userProfile.address}
                  onChange={(e) => infoChange(e)}
                />
              </div>
              <div className="form-group col-md-3 mb-3">
                <label htmlFor="profile-picture" className="m-0">
                  Profile Picture
                </label>
                <div className="picture-container mt-2 border p-3 rounded">
                  <div className="picture profile-picture">
                    <img
                      src="images/faces/face0.jpg"
                      className="picture-src"
                      id="profilePicturePreview"
                      title=""
                    />
                    <input type="file" id="profile-picture" className="" />
                  </div>
                  <h6 className="mt-2">Change Profile Picture</h6>
                </div>
              </div>
              <div className="form-group col-md-9 mb-3">
                <label htmlFor="cover-picture" className="m-0">
                  Cover Picture
                </label>
                <div className="picture-container mt-2  border p-3 rounded">
                  <div className="picture cover-picture">
                    <img
                      src="images/senior-care1.jpg"
                      className="picture-src"
                      id="coverPicturePreview"
                      title=""
                    />
                    <input type="file" id="cover-picture" className="" />
                  </div>
                  <h6 className="mt-2">Change Cover Picture</h6>
                </div>
              </div>
              <div className="col-12 mt-5">
                <h5>External Links:</h5>
                <hr />
              </div>
              <div className="form-group col-md-12 mb-3">
                <label htmlFor="facebook" className="m-0">
                  Facebook profile URL
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text bg-primary text-white px-3">
                      <i className="fa-brands fa-facebook"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="facebook"
                    placeholder="https://www.facebook.com/"
                    value={userProfile.facebook}
                    onChange={(e) => infoChange(e)}
                  />
                </div>
              </div>
              <div className="form-group col-md-12 mb-3">
                <label htmlFor="instagram" className="m-0">
                  Instagram profile URL
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text bg-primary text-white px-3">
                      <i className="fa-brands fa-instagram"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="instagram"
                    placeholder="https://www.instagram.com/"
                    value={userProfile.instagram}
                    onChange={(e) => infoChange(e)}
                  />
                </div>
              </div>
              <div className="form-group col-md-12 mb-3">
                <label htmlFor="tiktok" className="m-0">
                  TikTok profile URL
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text bg-primary text-white px-3">
                      <i className="fa-brands fa-tiktok"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="tiktok"
                    placeholder="https://www.tiktok.com/"
                    value={userProfile.tiktok}
                    onChange={(e) => infoChange(e)}
                  />
                </div>
              </div>
              <div className="form-group col-md-12 mb-3">
                <label htmlFor="twitter" className="m-0">
                  Twitter profile URL
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text bg-primary text-white px-3">
                      <i className="fa-brands fa-twitter"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="twitter"
                    placeholder="https://www.twitter.com/"
                    value={userProfile.twitter}
                    onChange={(e) => infoChange(e)}
                  />
                </div>
              </div>
              <div className="form-group col-md-12 mb-3">
                <label htmlFor="blog" className="m-0">
                  Blog profile URL
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text bg-primary text-white px-3">
                      <i className="fa-solid fa-earth-americas"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="blog"
                    placeholder="https://www.your-blog-website.com/"
                    value={userProfile.blog}
                    onChange={(e) => infoChange(e)}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="interests-section"
          role="tabpanel"
          aria-labelledby="interests-section"
        >
          <div className="wrapper wrapper-content animated fadeInRight">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui,
            fugiat ducimus libero numquam sapiente aliquid. Impedit corporis
            nihil similique voluptatum, eius dolor perferendis rerum itaque
            pariatur eos vitae, tempore facere!
          </div>
        </div>
      </div>
    </>
  );
};
