import React from "react";
import CSS from "csstype";
import { CreateActionItemDto } from "../../lib/data-transfer-object/actionItem.dto";
import { isEmpty, map } from "lodash";

interface ActionItemListProps {
  actionItems: CreateActionItemDto[];
}

const tableStyle: CSS.Properties = {
  width: "100%",
};

export const ActionItemList = (props: ActionItemListProps) => {
  const { actionItems } = props;

  const populateActionItems = () => {
    return map(actionItems, (actionItem, index) => {
      return (
        <tr key={index}>
          <td>{actionItem.name}</td>
          <td>{actionItem.description}</td>
          <td className="py-0 px-5">
            <button
              type="button"
              className="btn btn-success btn-rounded btn-icon"
              data-toggle="tooltip"
              data-placement="bottom"
              title=""
              data-original-title="Edit"
            >
              <i className="fas fa-edit" aria-hidden="true"></i>
            </button>
            <button
              type="button"
              className="btn btn-danger btn-rounded btn-icon"
              data-toggle="tooltip"
              data-placement="bottom"
              title=""
              data-original-title="Remove"
            >
              <i className="fas fa-times" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      );
    });
  };

  return (
    <table className="table table-striped table-bordered" style={tableStyle}>
      <thead>
        <tr>
          <th className="col-5">Action Items</th>
          <th className="col-5">Description</th>
          <th className="col-2">Options</th>
        </tr>
      </thead>
      <tbody>
        {!isEmpty(actionItems) ? (
          populateActionItems()
        ) : (
          <tr>
            <td colSpan={3}>No data available</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
