export const Billing = () => {
  return (
    <>
      <p className="card-title">
        <i className="fa-solid fa-credit-card"></i>
        Payment Information
        <a
          href="profile-billing-information.html"
          className="btn btn-primary btn-sm btn-icon-text float-right"
        >
          <i className="fa-sharp fa-solid fa-plus mr-1"></i>
          Add Payment Information
        </a>
      </p>
      <div className="my-3">
        <hr />
      </div>
      <form className="form-row">
        <div className="form-group col-md-8 my-3">
          <p className="m-0">
            <img
              src="images/logos/mastercard-logo.png"
              className="thumb-icon border shadow-sm mr-2"
            />
            •••• •••• •••• 1234
            <span className="badge badge-pill badge-dark ml-2">PREFERRED</span>
          </p>
          <p className="small text-muted m-0">
            To remove, add another payment method first.
          </p>
        </div>
        <div className="form-group col-md-4 my-3 text-right pr-4">
          <a
            href="profile-billing-information.html"
            className="text-primary text-decoration-none mr-2"
            data-toggle="tooltip"
            data-placement="left"
            title=""
            data-original-title="Edit"
          >
            <i className="fa-solid fa-pen fa-xs"></i>
          </a>
          <a
            href="#"
            className="text-dark"
            data-toggle="tooltip"
            data-placement="left"
            title=""
            data-original-title="Remove"
          >
            <i className="fa-solid fa-xmark"></i>
          </a>
        </div>
        <div className="col-12">
          <hr />
        </div>
        <div className="form-group col-md-8 my-3">
          <p className="m-0">
            <img
              src="images/logos/american-express-logo.png"
              className="thumb-icon border shadow-sm mr-2"
            />
            •••• •••• •••• 5678
          </p>
        </div>
        <div className="form-group col-md-4 my-3 text-right pr-4">
          <a
            href="profile-billing-information.html"
            className="text-primary text-decoration-none mr-2"
            data-toggle="tooltip"
            data-placement="left"
            title=""
            data-original-title="Edit"
          >
            <i className="fa-solid fa-pen fa-xs"></i>
          </a>
          <a
            href="#"
            className="text-dark"
            data-toggle="tooltip"
            data-placement="left"
            title=""
            data-original-title="Remove"
          >
            <i className="fa-solid fa-xmark"></i>
          </a>
        </div>
        <div className="col-12">
          <hr />
        </div>
        <div className="form-group col-md-8 my-3">
          <p className="m-0">
            <img
              src="images/logos/apple-logo.jpg"
              className="thumb-icon border shadow-sm mr-2"
            />
            •••• •••• •••• 9101
          </p>
        </div>
        <div className="form-group col-md-4 my-3 text-right pr-4">
          <a
            href="profile-billing-information.html"
            className="text-primary text-decoration-none mr-2"
            data-toggle="tooltip"
            data-placement="left"
            title=""
            data-original-title="Edit"
          >
            <i className="fa-solid fa-pen fa-xs"></i>
          </a>
          <a
            href="#"
            className="text-dark"
            data-toggle="tooltip"
            data-placement="left"
            title=""
            data-original-title="Remove"
          >
            <i className="fa-solid fa-xmark"></i>
          </a>
        </div>
      </form>
    </>
  );
};
