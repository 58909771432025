import CSS from "csstype";
import { map } from "lodash";
import { FC } from "react";

const modalStyle: CSS.Properties = {
  scrollbarWidth: "thin",
  background: "#f5f7ff",
};

interface ErrorModalProps {
  errors: string[];
}

export const ErrorModal: FC<ErrorModalProps> = (props: ErrorModalProps) => {
  const { errors } = props;
  return (
    <div
      className="modal fade"
      id="error-modal"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header p-3 pr-4">
            <h5 className="modal-title">
              <i className="fa-solid fa-circle-xmark"></i> Error
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body p-4 overflow-auto" style={modalStyle}>
            <div className="row">
              <div className="col-12">
                <h2 className="text-center">Sorry!</h2>
                <p className="text-center">
                  Your transaction has failed. Please go back and try again.
                </p>
                {map(errors, (error, index) => {
                  return <p key={index}>{error}</p>;
                })}
              </div>
            </div>
          </div>
          <div className="modal-footer py-2">
            <button
              type="button"
              className="btn btn-danger btn-sm btn-block"
              data-dismiss="modal"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
