import axios from "axios";
import { filter, get } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StatusCode } from "../lib/common/error";
import jwt_decode from "jwt-decode";
import AuthContext from "../context/AuthProvide";
import { useDispatch } from "react-redux";
import { setProfile } from "../features/profileSlice";
import { REFRESH_TOKEN_URL } from "../constants/api-url";
import { useSignIn } from "react-auth-kit";

export const Redirect = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setAuth } = useContext(AuthContext);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  let navigate = useNavigate();
  const singIn = useSignIn();
  const dispatch = useDispatch();

  const login = async (token: string) => {
    try {
      const response = await axios
        .post(REFRESH_TOKEN_URL, JSON.stringify({ refresh_token: token }), {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: false,
        })
        .catch((err) => {
          if (err?.response.status === StatusCode.Unauthorized) {
          }
        });

      if (response?.status === StatusCode.SuccessOK) {
        const data = get(response, "data");
        console.log(data);
        const accessToken = get(data, "accessToken");
        const expiresIn = get(data, "expiresIn");
        const refreshToken = get(data, "refreshToken");
        if (
          singIn({
            token: accessToken,
            expiresIn: expiresIn,
            tokenType: "Bearer",
            authState: { email: "" },
            refreshToken: refreshToken,
          })
        ) {
          setLoggedIn(true);
          setTimeout(() => navigate("/", { replace: true }));
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    if (searchParams) {
      const token = searchParams.get("key") as string;
      login(token);
    }
  }, [searchParams]);

  return <div>{loggedIn ? <>{navigate("/")}</> : <></>}</div>;
};
