import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import CSS from "csstype";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { useFetch } from "../../lib/hooks/useFetch";
import { GetChartDataResponseDto } from "../../lib/data-transfer-object/dashboard.dto";
import { GET_CHART_DATA_URL } from "../../constants/api-url";
import { forEach, get, map } from "lodash";
import { useAuthUser } from "react-auth-kit";

const chartStyle: CSS.Properties = {
  height: "60px",
};

const options = {
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 100,
      right: 100,
    },
  },
};

type ChartData = {
  labels: string[];
  datasets: ChartDataSet[];
};

type ChartDataSet = {
  data: number[];
  backgroundColor: string[];
  borderColor: string;
  borderWidth: number;
};

const initialValues: ChartData = {
  labels: ["Completed", "Compete", "Alert", "Overdue"],
  datasets: [
    {
      data: [39.7, 24.1, 6.9, 29.3],
      backgroundColor: ["#00CC66", "#330099", "#FFFF66", "#FF0066"],
      borderColor: "rgba(255, 255, 255, 1)",
      borderWidth: 1,
    },
  ],
};

export const Tasks = () => {
  const [charData, setChartData] = useState<ChartData>(initialValues);

  const profille = useSelector((state: RootState) => state.profile);
  const auth = useAuthUser();

  const { response, loaded, fetchErrors } = useFetch<GetChartDataResponseDto>(
    `${GET_CHART_DATA_URL}?email=${auth()?.email}`
  );

  const mappedResponseToCharData = () => {
    const data = get(response, "data");
    const legends: string[] = [];
    const values: number[] = [];

    forEach(data, (d) => {
      legends.push(d.legend);
      values.push(d.value);
    });

    setChartData({
      ...charData,
      labels: legends,
      datasets: map(charData.datasets, (ds) => {
        return {
          ...ds,
          data: values,
        };
      }),
    });
  };

  useEffect(() => {
    loaded && mappedResponseToCharData();
  }, [loaded, response]);

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">
          <i className="fa-solid fa-tasks"></i> Tasks
        </h4>
        <div className="my-3">
          <hr />
        </div>
        <div id="sales-legend" className="chartjs-legend mt-4 mb-4"></div>
        <div className="mt-5">
          {loaded && (
            <Pie height={400} width={400} options={options} data={charData} />
          )}
        </div>
      </div>
    </div>
  );
};
