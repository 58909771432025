import { isEmpty, map } from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { ReferenceType } from "../../../constants/enum";
import {
  Category,
  SubCategory,
  WorkflowConfig,
  Workflow,
  ActionItem,
} from "../../../lib/data-transfer-object/reference.dto";

export type ParentsNode = {
  category?: Category;
  subCategory?: SubCategory;
  worflow?: Workflow;
};

export interface ReferenceTreeNodeProps {
  id: number;
  parents?: ParentsNode;
  text: string;
  referenceType: ReferenceType;
  data: Category | SubCategory | Workflow | ActionItem;
  checked: boolean;
  children?: ReferenceTreeNodeProps[];
  onTreeNodeChecked: (
    node: Category | SubCategory | Workflow | ActionItem,
    referenceType: ReferenceType,
    parents?: ParentsNode
  ) => void;
}

export const ReferenceTreeNode = (props: ReferenceTreeNodeProps) => {
  const {
    id,
    text,
    parents,
    data,
    referenceType,
    children,
    checked,
    onTreeNodeChecked,
  } = props;
  const [showChildren, setShowChildren] = useState<boolean>(false);
  const [childNodes, setChildNodes] = useState<
    ReferenceTreeNodeProps[] | undefined
  >(children);
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  const composeDataContent = () => {
    return `<h5 class='m-0 py-2 border-bottom'>
                  <b>${text}</b>
              </h5>
              <span class='panel_toolbox-v2' style='right: 3px;'>
                  <a href='#' class='mx-1'>
                      <i class='fa fa-edit'></i>
                  </a>
                  <a href='#' class='mx-1'>
                      <i class='fa fa-close'></i>
                  </a>
              </span>`;
  };

  const getIconStyle = (referenceType: ReferenceType) => {
    switch (referenceType) {
      case ReferenceType.CATEGORY: {
        return "fa-solid fa-folder-tree mx-1 text-dark";
      }
      case ReferenceType.SUB_CATEGORY: {
        return "fa-solid fa-person-running mx-1 text-warning";
      }
      case ReferenceType.WORKFLOW: {
        return "fa-solid fa-file-circle-check mx-1 text-success";
      }
      case ReferenceType.ACTION_ITEM: {
        return "fa-solid fa-file-circle-xmark mx-1 text-danger";
      }
      default: {
        return "";
      }
    }
  };

  //TODO: Checking of checking of paren

  const populateChildrenNode = (isParentChecked: boolean) => {
    return (
      <ul>
        {map(children, (child, index) => {
          const childNode = {
            ...child,
            checked: isParentChecked,
          };
          return <ReferenceTreeNode key={index} {...childNode} />;
        })}
      </ul>
    );
  };

  const onShowChildrenClick = () => {
    setShowChildren(!showChildren);
  };

  const onNodeChecked = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      onTreeNodeChecked(data, referenceType, parents);
    }

    /*const foo: ReferenceTreeNodeProps[] = map(childNodes, (childNode) => {
      return {
        ...childNode,
        checked: true,
      };
    });
    console.log(foo);
    setChildNodes(foo);
    console.log(childNodes);*/
    populateChildrenNode(event.target.checked);
  };

  const isDisabled = () => {
    return referenceType === ReferenceType.CATEGORY ||
      referenceType === ReferenceType.SUB_CATEGORY
      ? true
      : false;
  };

  useEffect(() => {
    //console.log("parent is checked");
  }, [childNodes]);

  return (
    <li className="branch">
      <i
        className={`indicator fa ${
          !isEmpty(children) && showChildren
            ? "fa-minus-circle"
            : "fa-plus-circle"
        }`}
        onClick={onShowChildrenClick}
      />
      <input
        type="checkbox"
        className={`item${id}`}
        id={`item${id}`}
        data-content={composeDataContent()}
        checked={isChecked}
        onChange={(e) => onNodeChecked(e)}
        disabled={isDisabled()}
      />
      <i className={getIconStyle(referenceType)}></i>
      <a href="#">{text}</a>
      {showChildren ? populateChildrenNode(isChecked) : null}
    </li>
  );
};
