export const LoginSecurity = () => {
  return (
    <>
      <p className="card-title">
        <i className="fa-solid fa-shield-halved"></i>
        Login &amp; Security
        <span className="float-right mr-1 small text-muted">
          <svg height="13" width="10">
            <circle cx="5" cy="5" r="5" fill="#ba3742"></circle>
          </svg>
          <b>Account Status: </b> Not Verified
        </span>
      </p>
      <div className="my-3">
        <hr />
      </div>
      <div id="accordion" className="myaccordion w-100 rounded mb-5">
        <div className="card">
          <div className="card-header p-0" id="change_password_section">
            <h2 className="mb-0">
              <button
                className="d-flex py-4 px-4 align-items-center justify-content-between btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#change_password_section_body"
                aria-expanded="false"
                aria-controls="change_password_section_body"
              >
                <p className="mb-0 text-left" style={{ lineHeight: "0.95rem" }}>
                  <span className="ml-3 text-primary font-weight-bold">
                    Change Password
                  </span>
                </p>
              </button>
            </h2>
          </div>
          <div
            id="change_password_section_body"
            className="collapse"
            aria-labelledby="change_password_section"
            data-parent="#accordion"
          >
            <div className="card-body px-3">
              <div className="col-12 my-4">
                <h5>Change password</h5>
                <small className="text-muted">
                  Your password must be at least 6 characters and should include
                  a combination of numbers, letters and special characters
                  (!$@%).
                </small>
                <hr />
                <form className="form-row">
                  <div className="form-group col-md-12 mb-3">
                    <label htmlFor="current_password" className="m-0">
                      Current Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="current_password"
                      placeholder="Current Password"
                      autoFocus
                    />
                  </div>
                  <div className="form-group col-md-12 mb-3">
                    <label htmlFor="new_password" className="m-0">
                      New Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="new_password"
                      placeholder="New Password"
                    />
                  </div>
                  <div className="form-group col-md-12 mb-3">
                    <label htmlFor="retype_password" className="m-0">
                      Re-type Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="retype_password"
                      placeholder="Re-type Password"
                    />
                  </div>
                  <p className="pl-2">
                    <a href="#" className="text-primary text-decoration-none">
                      Forgot your password?
                    </a>
                  </p>
                  <div className="form-group col-md-12 mb-3">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm btn-icon-text float-right mr-2"
                    >
                      <i className="fa-sharp fa-solid fa-save mr-1"></i>
                      Change Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div
            className="card-header p-0"
            id="multifactor_authentication_section"
          >
            <h2 className="mb-0">
              <button
                className="d-flex py-4 px-4 align-items-center justify-content-between btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#multifactor_authentication_section_body"
                aria-expanded="false"
                aria-controls="multifactor_authentication_section_body"
              >
                <p className="mb-0 text-left" style={{ lineHeight: "0.95rem" }}>
                  <span className="ml-3 text-primary font-weight-bold">
                    Multi-factor Authentication
                  </span>
                </p>
              </button>
            </h2>
          </div>
          <div
            id="multifactor_authentication_section_body"
            className="collapse"
            aria-labelledby="multifactor_authentication_section"
            data-parent="#accordion"
          >
            <div className="card-body px-3">
              <div className="col-12 my-4">
                <h5>Multi-factor Authentication</h5>
                <small className="text-muted">
                  We'll now ask for a login code anytime you log in on a device
                  we don't recognize.
                </small>
                <hr />
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="border shadow-sm rounded p-4">
                      <div className="form-group mb-0 row">
                        <label
                          htmlFor="tags"
                          className="col-sm-6 col-form-label text-right"
                        >
                          <i className="fa-solid fa-mobile-screen mr-1"></i>{" "}
                          Text Message (SMS)
                        </label>
                        <div className="col-sm-6 pt-2">
                          <div className="custom-toggle">
                            <label className="switch switch-left-right">
                              <input className="switch-input" type="checkbox" />
                              <span
                                className="switch-label"
                                data-on="On"
                                data-off="Off"
                              ></span>
                              <span className="switch-handle"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="border shadow-sm rounded p-4">
                      <div className="form-group mb-0 row">
                        <label
                          htmlFor="tags"
                          className="col-sm-6 col-form-label text-right"
                        >
                          <i className="fa-solid fa-envelope-open-text mr-1"></i>{" "}
                          Email Address
                        </label>
                        <div className="col-sm-6 pt-2">
                          <div className="custom-toggle">
                            <label className="switch switch-left-right">
                              <input className="switch-input" type="checkbox" />
                              <span
                                className="switch-label"
                                data-on="On"
                                data-off="Off"
                              ></span>
                              <span className="switch-handle"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header p-0" id="manage_access_section">
            <h2 className="mb-0">
              <button
                className="d-flex py-4 px-4 align-items-center justify-content-between btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#manage_access_section_body"
                aria-expanded="false"
                aria-controls="manage_access_section_body"
              >
                <p className="mb-0 text-left" style={{ lineHeight: "0.95rem" }}>
                  <span className="ml-3 text-primary font-weight-bold">
                    Manage Access
                  </span>
                </p>
              </button>
            </h2>
          </div>
          <div
            id="manage_access_section_body"
            className="collapse"
            aria-labelledby="manage_access_section"
            data-parent="#accordion"
          >
            <div className="card-body px-3">
              <div className="col-12 my-4">
                <h5>Manage Access</h5>
                <small className="text-muted">
                  These signed-in devices have recently been active on this
                  account. You can sign any unfamiliar devices out or change
                  your password for added security.
                </small>
                <hr />
                <div className="row">
                  <div className="col-12 col-sm-4">
                    <div className="border shadow-sm rounded p-3">
                      <b>Android 12</b>
                      <button className="btn btn-outline-primary btn-sm float-right">
                        Sign Out
                      </button>
                      <hr />
                      <small className="text-muted d-block font-weight-bold">
                        Los Angeles Califoria, United States
                      </small>
                      <small className="text-muted d-block">
                        <i className="fa-regular fa-calendar-days mr-1"></i>
                        18/06/23, 6:48 AM GMT-7
                      </small>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="border shadow-sm rounded p-3">
                      <b>Firefox for Windows 10</b>
                      <button className="btn btn-outline-primary btn-sm float-right">
                        Sign Out
                      </button>
                      <hr />
                      <small className="text-muted d-block font-weight-bold">
                        Los Angeles Califoria, United States
                      </small>
                      <small className="text-muted d-block">
                        <i className="fa-regular fa-calendar-days mr-1"></i>
                        18/06/23, 6:48 AM GMT-7
                      </small>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="border shadow-sm rounded p-3">
                      <b>Safari on Mac OS X 10.15</b>
                      <button className="btn btn-outline-primary btn-sm float-right">
                        Sign Out
                      </button>
                      <hr />
                      <small className="text-muted d-block font-weight-bold">
                        Los Angeles Califoria, United States
                      </small>
                      <small className="text-muted d-block">
                        <i className="fa-regular fa-calendar-days mr-1"></i>
                        18/06/23, 6:48 AM GMT-7
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header p-0" id="account_verification">
            <h2 className="mb-0">
              <button
                className="d-flex py-4 px-4 align-items-center justify-content-between btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#account_verification_body"
                aria-expanded="false"
                aria-controls="account_verification_body"
              >
                <p className="mb-0 text-left" style={{ lineHeight: "0.95rem" }}>
                  <span className="ml-3 text-primary font-weight-bold">
                    Account Verification
                  </span>
                </p>
              </button>
            </h2>
          </div>
          <div
            id="account_verification_body"
            className="collapse"
            aria-labelledby="account_verification"
            data-parent="#accordion"
          >
            <div className="card-body px-3">
              <div className="col-12 my-4">
                <h5>Account Verification</h5>
                <small className="text-muted">
                  Please check your email:{" "}
                  <span className="font-weight-bold text-danger">
                    youremailaddress@email.com
                  </span>{" "}
                  for your account verification code and enter it below to
                  confirm your email address.
                </small>
                <hr />
                <form className="form-row">
                  <div className="col-12 col-sm-12">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your verification code"
                        aria-label="Search for..."
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-sm btn-primary"
                          type="button"
                        >
                          <i className="fa-solid fa-user-check mr-1"></i>
                          Confirm your Email
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <hr />
                <p>
                  Need us to send the verification email again?{" "}
                  <a href="#" className="text-decoration-none text-primary">
                    Send Again.
                  </a>
                </p>
                <ul>
                  <li>Make sure your email address is correct.</li>
                  <li>Make sure to check your spam folder.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-3">
        <hr />
      </div>
      <div className="col-12">
        <h4 className="text-danger font-weight-bold">
          <i className="fa-solid fa-trash-can"></i> Delete account
        </h4>
        <p>
          Once you delete your account, there is no going back. Please be
          certain.
        </p>
        <button type="button" className="btn btn-danger btn-sm btn-icon-text">
          <i className="fa-solid fa-user-xmark mr-1"></i>
          Delete your account
        </button>
      </div>
    </>
  );
};
