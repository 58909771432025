import dayjs from "dayjs";
import CSS from "csstype";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { ChangeEvent, FC, useState } from "react";

const styles: CSS.Properties = {
  width: "49%",
};

interface TemplateTaskProps {
  reportTemplateName: string;
  onApproverChange: (approverName: string) => void;
  onAssignToChange: (approverName: string) => void;
}

export const TemplateInfo: FC<TemplateTaskProps> = (
  props: TemplateTaskProps
) => {
  const { reportTemplateName, onApproverChange, onAssignToChange } = props;

  const [approver, setApprover] = useState<string>("");
  const [assignTo, setAssignTo] = useState<string>("");

  const onApproverChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setApprover(event.target.value);
    onApproverChange(event.target.value);
  };

  const onAssignToChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setAssignTo(event.target.value);
    onAssignToChange(event.target.value);
  };

  return (
    <>
      <div className="col-12 col-sm-4 py-3 px-4 border-right">
        <div className="row">
          <div className="col-12">
            <p className="m-0">
              <b className="text-primary mr-2">Approver:</b>
              <input
                list="simulate-approvers-list"
                name="simulate-approvers"
                id="simulate-approvers"
                value={approver}
                className="creators-hub-simulate-modal-input-text w-100"
                placeholder="&#xf304;"
                onChange={(e) => onApproverChanged(e)}
              />
              <datalist id="simulate-approvers-list">
                <option value="John Smith" />
                <option value="Ryan Reynolds" />
                <option value="Gal Gadoth" />
                <option value="Crystal Liu" />
                <option value="Tobey Maguire" />
              </datalist>
            </p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <p className="m-0 mb-3">
              <b className="text-primary mr-2">Assign To:</b>
              <input
                list="simulate-assign-to-list"
                name="simulate-assign-to"
                id="simulate-assign-to"
                value={assignTo}
                className="creators-hub-simulate-modal-input-text w-100"
                placeholder="&#xf304;"
                onChange={(e) => onAssignToChanged(e)}
              />
              <datalist id="simulate-assign-to-list">
                <option value="John Smith" />
                <option value="Ryan Reynolds" />
                <option value="Gal Gadoth" />
                <option value="Crystal Liu" />
                <option value="Tobey Maguire" />
              </datalist>
            </p>
            <p className="m-0">
              <b className="text-primary mr-2">Monitors:</b>
              <div id="creators-hub-simulate-viewer" className="pt-2">
                <input
                  type="text"
                  data-role="tagsinput"
                  id="simulateViewers"
                  className="creators-hub-simulate-modal-input-text w-100"
                  placeholder="&#xf304;"
                />
              </div>
            </p>
          </div>
        </div>
      </div>

      <div className="col-12 col-sm-4 py-3 px-4 border-right">
        <div className="row">
          <div className="col-12">
            <p className="m-0">
              <b className="text-primary mr-2">Report Range:</b>
              <span className="d-block mt-1">
                <input
                  type="date"
                  className="creators-hub-simulate-modal-input-text"
                  id="simulateDateFrom"
                  style={styles}
                />
                <input
                  type="date"
                  className="creators-hub-simulate-modal-input-text"
                  id="simulateDateTo"
                  style={styles}
                />
              </span>
            </p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-6">
            <p className="m-0">
              <b className="text-primary mr-2">ID No:</b>
              <span className="d-block mt-1">1001</span>
            </p>
          </div>
          <div className="col-6">
            <p className="m-0">
              <b className="text-primary mr-2">Date:</b>
              <span className="d-block mt-1">
                {dayjs().format("MMM DD, YYYY")}
              </span>
            </p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <p className="m-0">
              <b className="text-primary mr-2">Report:</b>
              <span className="d-block mt-1">{reportTemplateName}</span>
            </p>
          </div>
        </div>
      </div>

      <div className="col-12 col-sm-4 py-3 px-4">
        <div className="card text-white bg-danger mb-3 w-100 h-100">
          <div className="card-body d-flex justify-content-center">
            <h2 className="text-white text-center align-self-center">
              Simulation Mode Only
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};
