import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { CreateReportTemplateDto, ReportTemplateHeaderDto, ReportTemplateSectionDto } from '../lib/data-transfer-object/report.template.dto';


const initialState: CreateReportTemplateDto = {
  isPreview: false,
  reportTemplateName: '',
  header: {
    name: '',
    description: ''
  },
  sections: [
  ],
  footer: '',
  createdDate: new Date(dayjs().format('YYYY-MM-DD')),
  createdBy: '',
  lastUpdatedDate: new Date(dayjs().format('YYYY-MM-DD'))
}

export const createReportTemplateSlice = createSlice({
  name: 'createReportTemplate',
  initialState,
  reducers: {
    changeReportName: (state, action: PayloadAction<string>) => {
      state.reportTemplateName = action.payload;
    },
    changeReportTemplateHeader: (state, action: PayloadAction<ReportTemplateHeaderDto>) => {
      state.header = action.payload;
    },
    changeReportCategory: (state, action: PayloadAction<number>) => {
      state.categoryId = action.payload
    },
    changeReportSubCategory: (state, action: PayloadAction<number>) => {
      state.subCategoryId = action.payload
    },
    addReportTemplateSection: (state, action: PayloadAction<ReportTemplateSectionDto>) => {
      state.sections.push(action.payload);
    }
  }
});

export const { changeReportTemplateHeader, changeReportName, addReportTemplateSection, changeReportCategory, changeReportSubCategory } = createReportTemplateSlice.actions;

export default createReportTemplateSlice.reducer;