import { MainLayout } from "./containers/MainLayout";
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { Dashboard } from "./containers/Dashboard";
import { Register } from "./containers/Register";
import { RootLayout } from "./containers/RootLayout";
import { Login } from "./containers/Login";
import { CreateWorkflow } from "./containers/task/CreateWorkflow";
import { AssignWorkflow } from "./containers/task/AssignWorkflow";
import { ViewTemplateReport } from "./containers/reports/ViewTemplateReport";
import { GenerateReport } from "./containers/reports/GenerateReport";
import { AssignReport } from "./containers/reports/AssignReport";
import { CreatorsHub } from "./containers/CreatorsHub";
import { CommingSoon } from "./containers/CommingSoon";
import { ImportData } from "./containers/settings/ImportData";
import { AssignWorkflowForm } from "./containers/task/AssignWorflowForm";
import { Redirect } from "./containers/Redirect";
import { AssignedWorkflow } from "./containers/task/AssignedWorkflow";
import { Profile } from "./containers/Profile";
import { AuthProvider, RequireAuth, useIsAuthenticated } from "react-auth-kit";
import { Files } from "./containers/Files";

const PrivateRoute = ({ Component }: any) => {
  const isAuthenticated = useIsAuthenticated();
  const auth = isAuthenticated();
  return auth ? <Component /> : <Navigate to="login" />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootLayout />}>
      <Route path="login" element={<Login />} />
      <Route path=":redirect" element={<Redirect />} />
      <Route path="/" element={<PrivateRoute Component={MainLayout} />}>
        <Route index element={<Dashboard />} />
        <Route path="createworkflow" element={<CreateWorkflow />} />
        <Route path="assignworkflow" element={<AssignWorkflow />} />
        <Route
          path="assignworkflow/:workflowId"
          element={<AssignWorkflowForm />}
        />
        <Route path="assignedWorkflow/:id" element={<AssignedWorkflow />} />
        <Route path="/viewreporttemplate" element={<ViewTemplateReport />} />
        <Route path="/generatereport" element={<GenerateReport />} />
        <Route path="/assignreport" element={<AssignReport />} />
        <Route path="/creatorshub" element={<CreatorsHub />} />
        <Route path="/generateticket" element={<CommingSoon />} />
        <Route path="/submitticket" element={<CommingSoon />} />
        <Route path="/request" element={<CommingSoon />} />
        <Route path="/submit" element={<CommingSoon />} />
        <Route path="/files" element={<Files />} />
        <Route path="/comments-and-notes" element={<CommingSoon />} />
        <Route path="/marketplace" element={<CommingSoon />} />
        <Route path="/contacts" element={<CommingSoon />} />
        <Route path="/importdata" element={<ImportData />} />
        <Route path="/profile" element={<Profile />} />
      </Route>
      <Route path="register" element={<Register />} />
    </Route>
  )
);

function App() {
  return (
    <AuthProvider
      authType={"cookie"}
      authName={"_auth"}
      cookieDomain={window.location.hostname}
      cookieSecure={window.location.protocol === "https:"}
    >
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
