import { FC } from "react";
import CSS from "csstype";

const modalStyle: CSS.Properties = {
  scrollbarWidth: "thin",
  background: "#f5f7ff",
};

interface SuccessModalProps {
  message: string;
}

export const SuccessModal: FC<SuccessModalProps> = (
  props: SuccessModalProps
) => {
  const { message } = props;
  return (
    <div
      className="modal fade"
      id="success-modal"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header p-3 pr-4">
            <h5 className="modal-title">
              <i className="fa-solid fa-check"></i> Success
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body p-4 overflow-auto" style={modalStyle}>
            <div className="row">
              <div className="col-12">
                <h2 className="text-center">Success!</h2>
                <p className="text-center">{message}</p>
              </div>
            </div>
          </div>
          <div className="modal-footer py-2">
            <button
              type="button"
              className="btn btn-success btn-sm btn-block"
              data-dismiss="modal"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
