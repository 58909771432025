import { map } from "lodash";
import { useEffect } from "react";
import { SectionWorkflowDto } from "../../lib/data-transfer-object/report.template.dto";

interface SectionWorkflowsTreeProps {
  data?: SectionWorkflowDto;
  isEditable: boolean;
}

// TODO: isEditable will use when we edit the Workflow or Action Item

export const SectionWorkflowsTree = (props: SectionWorkflowsTreeProps) => {
  const { data, isEditable } = props;

  console.log(data);

  return (
    <div className="text-muted col-12 my-4">
      <div className="col-12 border-bottom py-2 px-0">
        <h5 className="font-weight-bold m-0 creators-hub-items-level1">
          {data?.categoryName}
        </h5>
      </div>
      {map(data?.subCategories, (subCategory, index) => {
        return (
          <>
            <div key={index} className="col-12 border-bottom py-2 px-0">
              <p className="font-weight-bold m-0 creators-hub-items-level1">
                {subCategory.subCategoryName}
              </p>
            </div>
            {map(subCategory.workflows, (workflow, index) => {
              return (
                <>
                  <div key={index} className="col-12 border-bottom py-2 px-0">
                    <p className="m-0 creators-hub-items-level2">
                      {workflow.workflowName}
                    </p>
                  </div>
                  {map(workflow.actionItems, (actionItem, index) => {
                    return (
                      <div
                        key={index}
                        className="col-12 border-bottom py-2 px-0"
                      >
                        <p className="m-0 creators-hub-items-level3">
                          {actionItem.actionItemName}
                        </p>
                      </div>
                    );
                  })}
                </>
              );
            })}
          </>
        );
      })}
    </div>
  );
};
