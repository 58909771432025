import React, { useEffect } from "react";
import CSS from "csstype";
import { useFetch } from "../../lib/hooks/useFetch";
import { GETALL_WORKFLOW_API } from "../../constants/tms";
import {
  GetAllWorkflowResponseDto,
  WorkflowDto,
} from "../../lib/data-transfer-object/workflow.dto";
import { get, map } from "lodash";
interface WokflowListProps {
  workflows: WorkflowDto[];
}

export const WorkflowList = (props: WokflowListProps) => {
  const { workflows } = props;

  return (
    <div className="col-md-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body pb-5">
          <p className="card-title">
            <i className="fa-solid fa-arrows-turn-to-dots mr-2"></i>
            Workflow List
          </p>
          <div className="my-3">
            <hr />
          </div>
          <table
            className="table table-striped table-bordered"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th className="col-2">Category</th>
                <th className="col-2">Sub Category</th>
                <th className="col-2">Name</th>
                <th className="col-1">Approver Required</th>
                <th className="col-1">File Upload Required</th>
                <th className="col-1">Comment Required</th>
                <th className="col-3">Options</th>
              </tr>
            </thead>
            <tbody>
              {map(workflows, (workflow, index) => {
                const { subCategoryName, workflowName, workflowConfig } =
                  workflow;
                const {
                  isApproverRequired,
                  isFileUploadRequired,
                  isCommentRequired,
                } = workflowConfig;
                return (
                  <tr key={index}>
                    <td>{"Senior Care"}</td>
                    <td>{subCategoryName}</td>
                    <td>{workflowName}</td>
                    <td>{isApproverRequired ? "Yes" : "No"}</td>
                    <td>{isFileUploadRequired ? "Yes" : "No"}</td>
                    <td>{isCommentRequired ? "Yes" : "No"}</td>
                    <td className="py-0 px-5">
                      <button
                        type="button"
                        className="btn btn-success btn-sm text-left"
                        data-toggle="modal"
                        data-target="#modal-edit-workflow-items"
                      >
                        <i className="fa-solid fa-pen-to-square mr-2"></i> Edit
                        Workflow
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm text-left"
                        data-toggle="modal"
                        data-target="#modal-view-action-items"
                      >
                        <i className="fa-solid fa-arrow-up-right-from-square mr-2"></i>{" "}
                        View Action Items
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
